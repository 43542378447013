import { formatInTimeZone } from "date-fns-tz";
import Attendee from "../dto/Attendee";
import { Currency } from "./Currency";
import { currentTimeZone }  from "./DateUtils";

export interface ExportField 
{
    name: string;
    customField?: boolean;
    displayName?: string;
    valToString?: (value: any, attendee: Attendee) => string;
}

export const CourtsExportToCSV = (attendees: Attendee[]) =>
{
    const fields: ExportField[] = [
        { name: 'attendeeNumber', displayName: 'Attendee Number' },
        { name: 'firstName', displayName: 'First Name' },
        { name: 'lastName', displayName: 'Last Name' },
        { name: 'name', displayName: 'Preferred Name' },
        { name: 'email', displayName: 'Email' },
        { name: 'birthdate', displayName: 'Birthdate' },
        { name: 'createdAt', displayName: 'Registered On', valToString: (value, a) => {
            return formatInTimeZone(value, currentTimeZone(), 'MMM d, yyyy, h:mm:ss A (z)');
        } },
        { name: 'Emergency Contact Name', customField: true },
        { name: 'Emergency Contact Phone', customField: true },
        { name: 'Grade', customField: true },
        { name: 'Highschool', customField: true },
        { name: 'Gender', customField: true },
        { name: 'Student or Parent', customField: true },
        { name: 'Ethnicity', customField: true },
        { name: 'Permission Form', customField: true },
        { name: 'Confidentiality Contract', customField: true },
    ];

    return ExportToCSV(attendees, fields);
};

export const AOExportToCSV = (attendees: Attendee[]) => 
{
    const fields: ExportField[] = [
        { name: 'attendeeNumber' },
        { name: 'firstName' },
        { name: 'lastName' },
        { name: 'name', displayName: 'preferredName' },
        { name: 'Badge Name', customField: true, displayName: 'badgeName' },
        { name: 'email' },
        { name: 'birthdate' },
        { name: 'registrationItems', displayName: 'regItems', valToString: (value, a) => {
            return a.registrationItems.map(regItem => (
                `${regItem.title} (${Currency.format(regItem.price)})`
            )).join('\n')
        } },
        { name: 'Shirt Size', customField: true, displayName: 'shirtSize' },
        { name: 'createdAt', displayName: 'registeredOn', valToString: (value, a) => {
            return formatInTimeZone(value, currentTimeZone(), 'MMM d, yyyy, HH:mm:ss (zzz)');
        } }
    ];

    return ExportToCSV(attendees, fields);
}

export const ExportToCSV = (attendees: Attendee[], fields: ExportField[]) =>
{
    const headers = fields.map(f => f.displayName || f.name).join(',');

    return headers + '\n' + attendees.map(attendee => 
    {
        return '"' + fields.map(field => 
        {
            if(!field.customField)
            {
                const val = attendee[field.name] || '';
                return (field.valToString ? field.valToString(val, attendee) : val.toString()).replace(/\"/gi, '""');
            }
            else
            {
                const val = attendee.fields[field.name] || '';
                return (field.valToString ? field.valToString(val, attendee) : val.toString()).replace(/\"/gi, '""');
            }
        }).join('","') + '"';
    }).join('\n');
};