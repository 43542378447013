import { useState, useCallback, useContext } from "react";
import {Organization} from "../../dto/Organization";
import User from "../../dto/User";
import { Event } from "../../dto/Event";
import Attendee from "../../dto/Attendee";

import { Utils } from "../../utils/Utils";
import Refresh from "@mui/icons-material/Refresh";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { GridConfig } from "../../components/Grid/Grid";
import { ArchivedAttendeeGrid } from "../../grids/ArchivedAttendeeGrid";
import { PageToolbar, ToolbarIconButton, ToolbarSearchBox } from "../../components/PageToolbar";
import { useQuery } from "react-query";
import { Table, TableConfig } from "../../components/Table/Table";
import { toDateElement } from "../../utils/DateUtils";
import { DialogContext } from "../../context/DialogContext";

interface AttendeesPageConfigiration 
{
    search: string,
    page: number,
    pageSize: number
};

export interface AttendeesProps
{
    account: User;
    events: Event[];
    organization: Organization;
    eventId: string;
    event: Event;
}

export interface AttendeesState
{
    isModalOpen: boolean;
    attendeeToEdit: Attendee;
    loading: boolean;
    forceUpdate: boolean;
}

export const ArchivedAttendeesPage = (props) => 
{
    const { event, isLoaded } = useCurrentContext();
    const api = useAPI();
    const dialog = useContext(DialogContext);

    const config = Utils.getPageConfig<AttendeesPageConfigiration>(`${event.id}-attendees-config`);

    const [ loading, setLoading ] = useState(false);
    
    const [ searchText, setSearchText ] = useState<string>(config?.search || '');
    const [gridConfig, setGridConfig] = useState<TableConfig<Attendee>>({
        columns: [
            { key: 'attendeeNumber', name: 'Attendee #', width: 1, pinned: true, readOnly: true },
            { key: 'firstName', name: 'First Name',  width: 2, readOnly: true },
            { key: 'lastName', name: 'Last Name',  width: 2, readOnly: true },
            { key: 'email', name: 'Email', width: 2, readOnly: true },
            { key: 'createdAt', name: 'Date Added', width: 2, render: (value) => toDateElement(value) },
            { key: 'actions', name: 'Actions', width: 2, readOnly: true, render: (value, item) => {
                return (
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <button onClick={() => restoreAttendee(item)}>Restore</button>
                        <button onClick={() => deleteAttendee(item)} className="danger">Delete</button>
                    </div>
                );
            }}
        ],
        pageIndex: 0,
        pageSize: 50
    });

    const attendeeQuery = useQuery(QueryKeys.Attendees.Archived(event.id, `${searchText}|${JSON.stringify(gridConfig)}`), () => 
    {
        const q = api.events.getById(event.id).attendees
            .param('archived', 'true')
            .select('attendeeNumber', 'firstName', 'lastName', 'email', 'birthdate', 'registration', 'customFields', 'createdAt')
            .expand('registrationItems')
            .skip(gridConfig.pageIndex * gridConfig.pageSize)
            .top(gridConfig.pageSize)
            .includeCount();

        //if(sortModel && sortModel.length > 0)
        //    q.orderBy(sortModel[0].field, sortModel[0].sort);
        // if(filterModel && filterModel.items > 0)
        //     q.filter(filterModel)

        //if(searchText)
        //    q.search(searchText);

        Utils.setPageConfig(`${event.id}-archived-attendees-config`, {
            //sort: sortModel,
            //filter: filterModel,
            search: searchText,
            page: gridConfig.pageIndex,
            pageSize: gridConfig.pageSize
        });

        return q.get();
    }, { enabled: isLoaded, initialData: { items: [] } });

    const restoreAttendee = useCallback(async (attendee: Attendee) =>
    {
        dialog.show({
            title: `Restore ${attendee.firstName} ${attendee.lastName}`,
            content: <div>Are you sure you want to restore the attendee <strong>{attendee.firstName} {attendee.lastName}</strong>?</div>,
            options: [
                { text: 'Restore' },
                { text: 'Cancel' }
            ],
            onSelect: async (option) => {
                if(option.text === 'Restore')
                {
                    setLoading(true);
                    await api.events.getById(event.id).attendees.getById(attendee.id).restore();
                    attendeeQuery.refetch();
                    setLoading(false);
                }
            }
        });
    }, [api, attendeeQuery]);

    const deleteAttendee = useCallback(async (attendee: Attendee) => 
    {
        dialog.show({
            title: `Delete ${attendee.firstName} ${attendee.lastName}`,
            content: <div>Are you sure you want to delete the attendee <strong>{attendee.firstName} {attendee.lastName}</strong>?</div>,
            options: [
                { text: 'Delete', type: 'danger' },
                { text: 'Cancel' }
            ],
            onSelect: async (option) => {
                if(option.text === 'Delete')
                {
                    setLoading(true);
                    await api.events.getById(event.id).attendees.getById(attendee.id).delete();
                    attendeeQuery.refetch();
                    setLoading(false);
                }
            }
        });
    }, [api, attendeeQuery]);

    return (
        <>
            <PageToolbar
                startItems={
                    <ToolbarSearchBox defaultValue={searchText} onChange={value => setSearchText(value)} />
                }
                endItems={
                    <ToolbarIconButton tooltip="Refresh" onClick={() => attendeeQuery.refetch()}><Refresh /></ToolbarIconButton>
                }
            />

            {/* <ArchivedAttendeeGrid 
                items={attendeeQuery.data.items}
                totalItems={attendeeQuery.data.count}
                config={gridConfig}
                loading={(attendeeQuery.isLoading) || loading}
                onConfigChanged={async config => setGridConfig(config)}
                onRestoreItem={unarchiveItem}
                onDeleteItem={deleteItem}
            /> */}

            <div style={{ padding: '20px' }}>
                <Table
                    items={attendeeQuery.data.items.filter(a => {
                        return (
                            `${a.firstName} ${a.lastName}`.toLowerCase().includes(searchText.toLowerCase()) ||
                            a.email.toLowerCase().includes(searchText.toLowerCase())
                        );
                    })}
                    loading={attendeeQuery.isLoading || loading}
                    config={gridConfig}
                    //onConfigChanged={config => setGridConfig(config)}
                    onItemContextMenu={(e, item) => [
                        { label: 'Restore', onClick: () => restoreAttendee(item) },
                        { label: 'Delete', onClick: () => deleteAttendee(item) }
                    ]}
                />
            </div>

        </>
    );
}
