import RegItem from "../../dto/RegItem";
import AddIcon from "@mui/icons-material/Add";
import { PageToolbar, ToolbarButton, ToolbarSearchBox } from "../../components/PageToolbar";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useToggle } from "../../hooks/useToggle";
import { useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Currency } from "../../utils/Currency";
import { useCallback, useContext, useState } from "react";
import { Table, TableConfig } from "../../components/Table/Table";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ClearIcon from "@mui/icons-material/Clear";

import "./AddOnPage.scss";
import { Chip } from "../../components/Chip";
import { ViewRegTierDrawer } from "./ViewRegTierDrawer";
import RegToken from "../../dto/RegToken";
import { toDateElement, toDateString } from "../../utils/DateUtils";
import { EditTokenDrawer } from "./EditTokenDrawer";
import { ViewTokenDrawer } from "./ViewTokenDrawer";
import { copyToClipboard } from "../../utils/Utils";
import { DialogContext } from "../../context/DialogContext";

const BASE_FORM_URL = process.env.REACT_APP_TOKEN_LINK_URL;

export interface TokensPageProps
{

}

export const TokensPage = (props: TokensPageProps) =>
{
    const { event, isLoaded } = useCurrentContext();
    const dialog = useContext(DialogContext);
    const queryClient = useQueryClient();
    const api = useAPI();
    const editDrawer = useToggle(false);
    const viewDrawer = useToggle(false);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedItem, setSelectedItem] = useState<RegToken>(null);
    const [tableConfig, setTableConfig] = useState<TableConfig<RegToken>>({
        sorts: [{ key: 'createdAt', dir: 'desc' }],
        columns: [
            { key: 'name', name: 'Name', sortable: true, render: (value, item) => {
                return <a href="#" className="text" onClick={() => viewItem(item)}>{value}</a>
            } },
            { key: 'email', name: 'Email', sortable: true },
            { key: 'createdAt', name: 'Created', sortable: true, render: (value) => {
                return toDateElement(new Date(value), true);
            }},
            { key: 'status', name: 'Status', sortable: true, render: (value) => {
                switch(value) {
                    case 'active': return <Chip color="success">Active</Chip>;
                    case 'redeemed': return <Chip color="warning">Redeemed</Chip>;
                    case 'revoked': return <Chip color="danger">Revoked</Chip>;
                    default: return <Chip>{value}</Chip>;
                }
            }},
            { key: 'regItems', name: 'Items', render: (value) => {
                return `${value.length || 0} items`;
            }},
            { key: 'actions', name: 'Actions', render: (value, item) => {
                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        { item.status === 'active' &&
                            <button onClick={() => editItem(item)}>Edit</button>
                        }
                        {/* <button onClick={() => viewItem(item)}>View</button> */}
                    </div>
                )
            }}
        ]
    });

    const tokensQuery = useQuery(
        QueryKeys.RegTokens.All(event.id, JSON.stringify(tableConfig.sorts)),
        () => {
            if(tableConfig.sorts.length === 0)
                return api.events.getById(event.id).regTokens.get();
            else
            {
                const sort = tableConfig.sorts[0];
                return api.events.getById(event.id).regTokens.get({ orderBy: { field: sort.key, direction: sort.dir } });
            }
        }, 
        { enabled: isLoaded, initialData: { items: [] } }
    );

    const viewItem = useCallback((item: RegToken) => {
        editDrawer.close();
        setSelectedItem(item);
        viewDrawer.open();
    }, [editDrawer, viewDrawer]);

    const editItem = useCallback((item: RegToken) => {
        viewDrawer.close();
        setSelectedItem(item);
        editDrawer.open();
    }, [editDrawer, viewDrawer]);

    const copyLink = useCallback((item: RegToken) => {
        copyToClipboard(`${BASE_FORM_URL}?t=${item.token}`);
    }, []);

    const revoke = useCallback((item: RegToken) => {
        dialog.show({
            title: 'Revoke token',
            content: 'Are you sure you want to revoke this token?',
            options: [
                { text: 'Revoke', type: 'danger' },
                { text: 'Cancel' }
            ],
            onSelect: async (option) => {
                if(option.text === 'Revoke')
                {
                    await api.events.getById(event.id).regTokens.getById(item.id).update({ status: 'revoked' });
                    queryClient.invalidateQueries(QueryKeys.RegTokens.All(event.id));
                    tokensQuery.refetch();
                }
            }
        });
    }, []);

    const deleteItem = useCallback((item: RegToken) => {
        dialog.show({
            title: 'Delete token',
            content: 'Are you sure you want to delete this token?',
            options: [
                { text: 'Delete', type: 'danger' },
                { text: 'Cancel' }
            ],
            onSelect: async (option) => {
                if(option.text === 'Delete')
                {
                    await api.events.getById(event.id).regTokens.getById(item.id).delete()
                    queryClient.invalidateQueries(QueryKeys.RegTokens.All(event.id));
                    tokensQuery.refetch();
                }
            }
        });
    }, []);

    const items = tokensQuery.data?.items
    .filter(i => {
        if(searchText.length > 0)
            return i.email?.toLowerCase().includes(searchText.toLowerCase())
                || i.name?.toLowerCase().includes(searchText.toLowerCase());
        else 
            return true;
    }) || [];

    const handleItemContextMenu = useCallback((e, item: RegToken) => {
        return [
            { label: 'View', onClick: viewItem, icon: <VisibilityIcon />},
            ...(item.status === 'active' ? [{ label: 'Edit', onClick: editItem, icon: <EditIcon />}] : []),
            ...(item.status === 'active' ? [{ type: 'divider'}] : []),
            ...(item.status === 'active' ? [{ label: 'Copy link to form', onClick: copyLink, icon: <ContentCopyIcon /> }] : []),
            ...(item.status === 'active' ? [{ label: 'Revoke', onClick: revoke, icon: <ClearIcon /> }] : []),
            { type: 'divider' },
            { label: 'Delete', onClick: deleteItem, icon: <DeleteIcon /> },
        ]
    }, []);

    return (
        <>
            <PageToolbar
                startItems={
                    <>
                        <ToolbarButton icon={<AddIcon />} onClick={() => editItem(null)}>New token</ToolbarButton>
                        <ToolbarSearchBox defaultValue={searchText} onChange={value => { setSearchText(value) }} placeholder="Search..." />
                    </>
                }
                endItems={
                    <>
                        {/* <ToolbarIconButton tooltip="Refresh" onClick={() => { }}><RefreshIcon /></ToolbarIconButton> */}
                    </>
                }
            />

            <div className="reg-items-table" style={{ padding: '20px' }}>

                <Table
                    items={items}
                    loading={tokensQuery.isFetched === false}
                    config={tableConfig}
                    onConfigChange={setTableConfig}
                    onItemContextMenu={handleItemContextMenu}
                />

            </div>

            <ViewTokenDrawer
                open={viewDrawer.isOpen}
                onDismiss={viewDrawer.close}
                tokenId={selectedItem?.id}
            />

            <EditTokenDrawer
                open={editDrawer.isOpen}
                onDismiss={editDrawer.close}
                onSave={item => { 
                    editDrawer.close(); 
                    tokensQuery.refetch()
                }}
                tokenId={selectedItem?.id}
            />
        </>
    );
};
