import React from "react";
import { Address } from "../dto/Attendee";

export const setValue = (obj: any, path: string, value: any) =>
{
    const pList = path.split('.');

    pList.reduce((accumulator, currentValue, currentIndex) => {
        if(currentIndex === pList.length - 1)
            accumulator[currentValue] = value;
        else if(!accumulator[currentValue])
            accumulator[currentValue] = {};

        return accumulator[currentValue];
    }, obj);

    return obj;
}

export const isTextCharacter = (str: string) => 
{
    return str.length === 1 && /[0-9a-zA-Z!@#$%^&*()-=_+[\]{};':",./<>?\\|]/gi.test(str);
}

export const copyInto = (to: any, from: any) => 
{
    if(typeof to === 'object' && typeof from === 'object')
    {
        for(const key in to)
            to[key] = from[key] ?? to[key];
    }

    return to;
}

export const hrefToRelativeUrl = (href: string) =>
{
    return href.replace(window.location.origin, '');
}

export const groupBy = <T, >(items: T[], fieldName: string) => {
    return items.reduce((result, item) => {
        (result[item[fieldName]] = result[item[fieldName]] || []).push(item);
        return result;
    }, {});
}

export const sortByProperty = (propertyName: string, ascending: boolean = true) =>
{
    return (a, b) => {
        if(a[propertyName] < b[propertyName]) { return ascending ? -1: 1; }
        else if(a[propertyName] > b[propertyName]) { return ascending ? 1 : -1; }
        else { return 0; }
    };
}

export const copyToClipboard = (str: string) =>
{
    const el = document.createElement('textarea');
    //el.value = `${this.baseTokenUrl}?t=` + params.row.token;
    //el.value = `asdfasdf`;
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export class Utils
{
    public static GenerateGuid(): string
    {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            // eslint-disable-next-line no-mixed-operators
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public static avatarFromString(name: string, sizeInPx: number = 32, styles: React.CSSProperties = {})
    {
        const components = typeof name === 'string' ? name.split(' ') : [];
        let children = 'BM';
        try 
        {
            if(components.length >= 2)
                children = `${components[0][0]}${components[1][0]}`;
            else if(components.length === 1)
                children = `${components[0].length >= 2 ? components[0][0] + components[0][1] : components[0][0]}`;
        }
        catch(ex) { }

        return {
            sx: Object.assign({
                bgcolor: this.stringToColour(name),
                width: `${sizeInPx}px`,
                height: `${sizeInPx}px`,
                fontSize: '1.5rem',
            }, styles),
            children,
        };
    }

    public static downloadFile(filename, content)
    {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    public static copyToClipboard(str: string)
    {
        return copyToClipboard(str);
    }

    public static sortByProperty(propertyName: string, ascending: boolean = true)
    {
        return (a, b) => {
            if(a[propertyName] < b[propertyName]) { return ascending ? -1: 1; }
            else if(a[propertyName] > b[propertyName]) { return ascending ? 1 : -1; }
            else { return 0; }
        };
    }

    public static isNullOrWhitespace(s: string)
    {
        return !(typeof s === 'string' && s.trim().length > 0);
    }

    public static sort<T>(property: string, ascending: boolean = true)
    {
        return (a: T, b: T) => {
            if(a[property] < b[property]) { return ascending ? -1 : 1; }
            else if(a[property] > b[property]) { return ascending ? 1 : -1; }
            else { return 0; }
        };
    }

    public static joinPaths(...paths: string[])
    {
        let retval = '';

        if(paths.length >= 1)
        {
            retval = paths[0];
        }

        if(paths.length >= 2)
        {
            for(let i = 1; i < paths.length; i++)
                retval = this.join(retval, paths[i]);
        }

        return retval;
    }

    private static join(path1: string, path2: string)
    {
        let endsWith = /\/$/;
        let startsWith = /^\//;

        if(endsWith.test(path1))
            path1 = path1.substring(0, path1.length - 1);
        if(startsWith.test(path2))
            path2 = path2.substring(1);

        return `${path1}/${path2}`;
    }

    public static formatAddress(address: Address)
    {
        let components: string[] = [];
        components.push(address.city);

        if(address.region)
            components.push(address.region);
        
        components.push(address.country);

        let str = components.join(', ');

        return (
            <div>
                <div>{address.address1}</div>
                {
                    address.address2 &&
                    <div>{address.address2}</div>
                }
                <div>{str}</div>
                <div>{address.postalCode}</div>
            </div>
        );
    }

    public static escapeRegExp(value: string): string 
    {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    public static dayList(month: number, year: number)
    {
        let days = 31;
        let retval = [{ name: '-', value: -1 }];

        switch(month)
        {
            case -1: days = 0; break;
            case 1: days = 29; break;
            case 3: days = 30; break;
            case 5: days = 30; break;
            case 8: days = 30; break;
            case 10: days = 30; break;
        }

        for(let i = 1; i < (days + 1); i++)
            retval.push({ name: i.toString(), value: i });

        return retval;
    }

    public static monthList(): { name: string, value: number }[]
    {
        return [
            { name: '-', value: -1 },
            { name: 'January', value: 0 },
            { name: 'February', value: 1 },
            { name: 'March', value: 2 },
            { name: 'April', value: 3 },
            { name: 'May', value: 4 },
            { name: 'June', value: 5 },
            { name: 'July', value: 6 },
            { name: 'August', value: 7 },
            { name: 'September', value: 8 },
            { name: 'October', value: 9 },
            { name: 'November', value: 10 },
            { name: 'December', value: 11 }
        ];
    }

    public static yearList(): { name: string, value: number }[]
    {
        let retval = [{ name: '-', value: -1 }];
        let currentYear = new Date().getFullYear();

        for(let i = currentYear; i >= 1900; i--)
        {
            retval.push({ name: i.toString(), value: i });
        }

        return retval;
    }

    // private static stringToColor(string: string)
    // {
    //     let hash = 0;
    //     let i;
      
    //     /* eslint-disable no-bitwise */
    //     for (i = 0; i < string.length; i += 1) {
    //       hash = string.charCodeAt(i) + ((hash << 5) - hash);
    //     }
      
    //     let color = '#';
      
    //     for (i = 0; i < 3; i += 1) {
    //       const value = (hash >> (i * 8)) & 0xff;
    //       color += `00${value.toString(16)}`.substr(-2);
    //     }
    //     /* eslint-enable no-bitwise */
      
    //     return color;
    // }

    private static stringToColour = (str: string) => {
        let hash = 0;
        str.split('').forEach(char => {
          hash = char.charCodeAt(0) + ((hash << 5) - hash)
        })
        let colour = '#'
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 0xff
          colour += value.toString(16).padStart(2, '0')
        }
        return colour
      }

    public static setPageConfig<T>(key: string, value: T)
    {
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    public static getPageConfig<T>(key: string)
    {
        let strValue = window.localStorage.getItem(key);
        let retval: T | null = null;

        if(typeof strValue === 'string')
            retval = JSON.parse(strValue);

        return retval; 
    }

    public static countryList(): string[]
    {
        return [
            "",
            "United States",
            "Canada",
            "-",
            "Afghanistan",
            "Akrotiri",
            "Albania",
            "Algeria",
            "American Samoa",
            "Andorra",
            "Angola",
            "Anguilla",
            "Antarctica",
            "Antigua and Barbuda",
            "Argentina",
            "Armenia",
            "Aruba",
            "Ashmore and Cartier Islands",
            "Australia",
            "Austria",
            "Azerbaijan",
            "Bahamas, The",
            "Bahrain",
            "Bangladesh",
            "Barbados",
            "Bassas da India",
            "Belarus",
            "Belgium",
            "Belize",
            "Benin",
            "Bermuda",
            "Bhutan",
            "Bolivia",
            "Bosnia and Herzegovina",
            "Botswana",
            "Bouvet Island",
            "Brazil",
            "British Indian Ocean Territory",
            "British Virgin Islands",
            "Brunei",
            "Bulgaria",
            "Burkina Faso",
            "Burma",
            "Burundi",
            "Cambodia",
            "Cameroon",
            "Cape Verde",
            "Cayman Islands",
            "Central African Republic",
            "Chad",
            "Chile",
            "China",
            "Christmas Island",
            "Clipperton Island",
            "Cocos (Keeling) Islands",
            "Colombia",
            "Comoros",
            "Congo, Democratic Republic of the",
            "Congo, Republic of the",
            "Cook Islands",
            "Coral Sea Islands",
            "Costa Rica",
            "Cote d'Ivoire",
            "Croatia",
            "Cuba",
            "Cyprus",
            "Czech Republic",
            "Denmark",
            "Dhekelia",
            "Djibouti",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "Egypt",
            "El Salvador",
            "Equatorial Guinea",
            "Eritrea",
            "Estonia",
            "Ethiopia",
            "Europa Island",
            "Falkland Islands (Islas Malvinas)",
            "Faroe Islands",
            "Fiji",
            "Finland",
            "France",
            "French Guiana",
            "French Polynesia",
            "French Southern and Antarctic Lands",
            "Gabon",
            "Gambia, The",
            "Gaza Strip",
            "Georgia",
            "Germany",
            "Ghana",
            "Gibraltar",
            "Glorioso Islands",
            "Greece",
            "Greenland",
            "Grenada",
            "Guadeloupe",
            "Guam",
            "Guatemala",
            "Guernsey",
            "Guinea",
            "Guinea-Bissau",
            "Guyana",
            "Haiti",
            "Heard Island and McDonald Islands",
            "Holy See (Vatican City)",
            "Honduras",
            "Hong Kong",
            "Hungary",
            "Iceland",
            "India",
            "Indonesia",
            "Iran",
            "Iraq",
            "Ireland",
            "Isle of Man",
            "Israel",
            "Italy",
            "Jamaica",
            "Jan Mayen",
            "Japan",
            "Jersey",
            "Jordan",
            "Juan de Nova Island",
            "Kazakhstan",
            "Kenya",
            "Kiribati",
            "Korea, North",
            "Korea, South",
            "Kuwait",
            "Kyrgyzstan",
            "Laos",
            "Latvia",
            "Lebanon",
            "Lesotho",
            "Liberia",
            "Libya",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Macau",
            "Macedonia",
            "Madagascar",
            "Malawi",
            "Malaysia",
            "Maldives",
            "Mali",
            "Malta",
            "Marshall Islands",
            "Martinique",
            "Mauritania",
            "Mauritius",
            "Mayotte",
            "Mexico",
            "Micronesia, Federated States of",
            "Moldova",
            "Monaco",
            "Mongolia",
            "Montenegro",
            "Montserrat",
            "Morocco",
            "Mozambique",
            "Namibia",
            "Nauru",
            "Navassa Island",
            "Nepal",
            "Netherlands",
            "Netherlands Antilles",
            "New Caledonia",
            "New Zealand",
            "Nicaragua",
            "Niger",
            "Nigeria",
            "Niue",
            "Norfolk Island",
            "Northern Mariana Islands",
            "Norway",
            "Oman",
            "Pakistan",
            "Palau",
            "Panama",
            "Papua New Guinea",
            "Paracel Islands",
            "Paraguay",
            "Peru",
            "Philippines",
            "Pitcairn Islands",
            "Poland",
            "Portugal",
            "Puerto Rico",
            "Qatar",
            "Reunion",
            "Romania",
            "Russia",
            "Rwanda",
            "Saint Helena",
            "Saint Kitts and Nevis",
            "Saint Lucia",
            "Saint Pierre and Miquelon",
            "Saint Vincent and the Grenadines",
            "Samoa",
            "San Marino",
            "Sao Tome and Principe",
            "Saudi Arabia",
            "Senegal",
            "Serbia", 
            "Seychelles",
            "Sierra Leone",
            "Singapore",
            "Slovakia",
            "Slovenia",
            "Solomon Islands",
            "Somalia",
            "South Africa",
            "South Georgia and the South Sandwich Islands",
            "Spain",
            "Spratly Islands",
            "Sri Lanka",
            "Sudan",
            "Suriname",
            "Svalbard",
            "Swaziland",
            "Sweden",
            "Switzerland",
            "Syria",
            "Taiwan",
            "Tajikistan",
            "Tanzania",
            "Thailand",
            "Timor-Leste",
            "Togo",
            "Tokelau",
            "Tonga",
            "Trinidad and Tobago",
            "Tromelin Island",
            "Tunisia",
            "Turkey",
            "Turkmenistan",
            "Turks and Caicos Islands",
            "Tuvalu",
            "Uganda",
            "Ukraine",
            "United Arab Emirates",
            "United Kingdom",
            "Uruguay",
            "Uzbekistan",
            "Vanuatu",
            "Venezuela",
            "Vietnam",
            "Virgin Islands",
            "Wake Island",
            "Wallis and Futuna",
            "West Bank",
            "Western Sahara",
            "Yemen",
            "Zambia",
            "Zimbabwe",
        ];
    }
}