import { Component, MouseEvent, ReactNode } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

export interface MaterialTableProps<T>
{
    items: T[];
    columns: TableColumn<T>[];
    loading?: boolean;
    allowSelection?: boolean;
    toolbar?: ReactNode;
    showMenuButton?: boolean;
    onItemClick?: (e: MouseEvent<HTMLTableRowElement>, item: T) => void;
    menuButtonsForRow?: (item: T, requestClose: Function) => ReactNode;

    page?: number;
    rowsPerPage?: number;
    totalItems?: number;
    rowsPerPageOptions?: number[];
    onChangePage?: (e: MouseEvent<HTMLElement>, page: number) => void;
    onChangeRowsPerPage?: (e: MouseEvent<HTMLElement>, rows: number) => void;
    onSortChange?: (column: TableColumn<T>, sortDirection: 'asc' | 'desc') => void;
}

export interface MaterialTableState<T>
{
    rowsPerPage: number;
    page: number;

    menuOpen: boolean;
    menuAnchor: any;
    menuContent: ReactNode;

    sortColumnKey: string;
    sortDirection: 'asc' | 'desc';
}

export interface TableColumn<T>
{
    key: string;
    label: string;
    numeric?: boolean;
    sortable?: boolean;
    render?: (data: any, item: T) => ReactNode;
}

export class MaterialTable<T> extends Component<MaterialTableProps<T>, MaterialTableState<T>>
{
    public static defaultProps: Partial<any> = {
        loading: false,
        allowSelection: false,
        showMenuButton: false
    };

    constructor(props: MaterialTableProps<T>)
    {
        super(props);

        this.state = {
            rowsPerPage: 25,
            page: 0,
            menuOpen: false,
            menuAnchor: null,
            menuContent: null,
            sortColumnKey: null,
            sortDirection: 'asc'
        };

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    public componentDidMount() 
    {
        
    }

    public render() 
    {
        let headerColumns = this.props.columns.map((c, index) => 
        {
            if(c.sortable)
            {
                return (
                    <TableCell key={index} sortDirection={this.state.sortColumnKey === c.key ? this.state.sortDirection : false}>
                        <Tooltip title={`Sort by ${c.label}`} placement={c.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                            <TableSortLabel 
                                onClick={(e) => { this.handleSortChange(e, c); }} 
                                active={this.state.sortColumnKey === c.key} 
                                direction={this.state.sortColumnKey === c.key ? this.state.sortDirection : 'asc'}>
                                {c.label}
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                );
            }
            else
            {
                return (
                    <TableCell key={index}>
                        {c.label}
                    </TableCell>
                );
            }
        });

        let items = null;
        let spinner = null;

        items = this.props.items.map((i, index) => 
        {
            let cells = this.props.columns.map((c, index) => 
            {
                let data = i[c.key] ? i[c.key] : null;
                let style: React.CSSProperties = null;

                return (
                    <TableCell key={index} style={style}>
                        {c.render ? c.render(data, i) : (data ? data.toString() : '')}
                    </TableCell>
                );
            });

            return (
                <TableRow key={index} hover onClick={e => this.handleClick(e, i)}>
                    { this.props.allowSelection && <TableCell padding="checkbox"><Checkbox /></TableCell> }
                    {cells}
                    { this.props.showMenuButton && 
                        <TableCell padding="checkbox">
                            <IconButton className="menu-button" onClick={e => this.handleMenuButtonClick(e, i)}>
                                <i className="fa fa-ellipsis-v" />
                            </IconButton>
                        </TableCell> }
                </TableRow>
            );
        });

        if(this.props.loading)
        {
            let spinnerStyle: React.CSSProperties = {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255,255,255,0.75)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            };

            spinner = (
                <div style={spinnerStyle}>
                    <CircularProgress size={50} />
                </div>
            )
        }

        return (
            <Paper className="component-material-table">
                {this.props.toolbar}
                <div style={{ position: 'relative' }}>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                { this.props.allowSelection && <TableCell padding="checkbox"><Checkbox /></TableCell>}
                                {headerColumns}
                                { this.props.showMenuButton && <TableCell padding="checkbox"></TableCell>}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {items}
                        </TableBody>

                    </Table>
                    {spinner}
                </div>

                <Menu
                    open={this.state.menuOpen}
                    anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    onClick={(e) => { this.setState({ menuOpen: false }); e.stopPropagation(); e.preventDefault(); }}
                    onClose={() => { this.setState({ menuOpen: false }); }}
                    anchorEl={this.state.menuAnchor}>
                    {this.state.menuContent}
                </Menu>

                {
                    this.props.page >= 0 &&
                    <TablePagination
                        component="div"
                        count={this.props.totalItems}
                        rowsPerPage={this.props.rowsPerPage}
                        rowsPerPageOptions={this.props.rowsPerPageOptions}
                        page={this.props.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}/>
                }

            </Paper>
        )
    }

    private handleSortChange(e: React.MouseEvent<HTMLElement>, column: TableColumn<T>)
    {
        if(this.props.onSortChange)
        {
            let sortDirection: 'asc' | 'desc' = 'asc';

            if(this.state.sortColumnKey === column.key)
                sortDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc';

            this.setState({ sortColumnKey: column.key, sortDirection: sortDirection });

            this.props.onSortChange(column, sortDirection);
        }
    }

    private handleChangePage(e: React.MouseEvent<HTMLElement>, page: number)
    {
        this.props.onChangePage(e, page);
    }

    private handleChangeRowsPerPage(e)
    {
        this.props.onChangeRowsPerPage(e, parseInt(e.target.value));
    }

    private handleMenuButtonClick(e: React.MouseEvent<HTMLElement>, item: T)
    {
        this.setState({
            menuOpen: true,
            menuAnchor: e.target,
            menuContent: this.props.menuButtonsForRow(item, () => { this.setState({ menuOpen: false }); })
        });
    }

    private handleClick(e: React.MouseEvent<HTMLTableRowElement>, item: T)
    {
        if(this.props.onItemClick)
        {
            this.props.onItemClick(e, item);
        }
    }
}
