
export class Currency
{
    public static format(value: number)
    {
        value /= 100;

        //const formatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });
        const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        return formatter.format(value);
    }
}
