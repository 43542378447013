
import { useDrag } from "@use-gesture/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Vector2 } from "@use-gesture/react/dist/declarations/src";
import { GridMenu } from "../Grid/Menus/GridMenu";
import { useToggle } from "../../hooks/useToggle";
import { GridMenuItem } from "../Grid/Menus/GridMenuItem";
import { GridMenuDivider } from "../Grid/Menus/GridMenuDivider";

import "./Table.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export type GridColumnType = 'text' | 'number' | 'date' | 'datetime' | 'select';
export interface GridSort { key: string, dir: 'asc' | 'desc' }
export interface GridFilter { key: string, op: 'eq', value: any }

export interface TableColumn<T>
{
    key: string;
    name: string;
    render?: (value: any, row: T) => React.ReactNode;
    width?: number;
    sortable?: boolean;
    pinned?: boolean;
    readOnly?: boolean;
    showMenu?: boolean;
}

export interface GridOption { key: string, text?: string }
export interface GridSelectColumn<T> extends TableColumn<T>
{
    options?: GridOption[];
}

export interface GridColumnInternal<T> extends TableColumn<T>, GridSelectColumn<T>
{
    width: number;
}

export interface TableConfig<T>
{
    columns: Array<TableColumn<T> | GridSelectColumn<T>>;
    sorts?: GridSort[];
    filters?: GridFilter[];

    pageSize?: number;
    pageIndex?: number;
}

export interface TableContextMenuItem<T>
{
    label?: string,
    onClick?: (item: T) => void;
    icon?: JSX.Element;
    type?: 'button' | 'divider' | string;
}

export interface GridProps<T>
{
    items?: T[];
    totalItems?: number;

    config: TableConfig<T>;
    
    loading?: boolean;
    
    onConfigChange?: (config: TableConfig<T>) => void | Promise<void>;
    onSelectItem?: (e: React.MouseEvent<HTMLDivElement>, item: T) => void;
    onColumnContextMenu?: (e: React.MouseEvent<HTMLDivElement>, column: TableColumn<T>) => TableContextMenuItem<T>[];
    onItemContextMenu?: (e: React.MouseEvent<HTMLDivElement>, item: T) => TableContextMenuItem<T>[];
    validate?: <T>(column: TableColumn<T>, value: T) => string;
}

const defaultConfig: TableConfig<any> = {
    columns: [],
    sorts: [],
    filters: [],
    pageSize: 10,
    pageIndex: 0
};

export const Table = function<T>(props: GridProps<T>)
{
    const { items = [], totalItems, config: propsConfig, loading = false, onSelectItem, onColumnContextMenu, onItemContextMenu, onConfigChange } = props;

    const config = Object.assign(defaultConfig, propsConfig);

    const [columns, setColumns] = useState<GridColumnInternal<T>[]>([]);
    const [isScrolled, setIsScrolled] = useState(false);
    
    const itemMenu = useToggle(false);
    const [itemMenuPos, setItemMenuPos] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const [itemMenuItems, setItemMenuItems] = useState<TableContextMenuItem<T>[]>([]);
    const [selectedItem, setSelectedItem] = useState<T>(null);
    
    useEffect(() => {
        const convertToInternalCol = (col: TableColumn<T>) => { return Object.assign({}, col, {}) as GridColumnInternal<T>; }
        setColumns(config.columns.map(convertToInternalCol));
    }, [JSON.stringify(config)]);

    const notifyConfigChange = useCallback(async (changes: Partial<TableConfig<T>> = {}) => {        
        await onConfigChange?.(Object.assign({}, config, changes));
    }, [columns, config]);

    const handleColumnClick = useCallback((e: React.MouseEvent<HTMLDivElement>, col: TableColumn<T>) => {
        if(col.sortable)
        {
            // TODO: this only supports single column sorting for now, can choose to update this in the future
            const sort = config.sorts.find(s => s.key === col.key);
            let newSort = null;
            if(sort === undefined)
                newSort = { key: col.key, dir: 'asc' };
            else if(sort.dir === 'asc')
                newSort = { key: col.key, dir: 'desc' };
            else if(sort.dir === 'desc')
                newSort = { key: col.key, dir: 'asc' };

            notifyConfigChange({ sorts: newSort ? [newSort] : [] });
        }
    }, [config, notifyConfigChange]);

    const handleColumnContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>, col: TableColumn<T>) => {
        if(onColumnContextMenu)
        {
            onColumnContextMenu(e, col);
            e.preventDefault();
            return false;
        }
    }, [onColumnContextMenu]);

    const handleItemContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>, item: T) => {
        if(onItemContextMenu)
        {
            itemMenu.open();
            setItemMenuPos({ x: e.clientX, y: e.clientY });
            setItemMenuItems(onItemContextMenu(e, item));
            setSelectedItem(item);
            
            e.preventDefault();
            return false;
        }
    }, [onItemContextMenu]);

    // const handlePageChange = useCallback((pageIndex: number) => {
    //     notifyConfigChange({ pageIndex });
    // }, [notifyConfigChange]);

    return (
        <div className="table-root"> 
            <div className={['table scrollable', (isScrolled ? 'scrolled' : '')].join(' ')} onScroll={e => { if(e.currentTarget.scrollLeft > 0 && !isScrolled) { setIsScrolled(true); } else if(e.currentTarget.scrollLeft === 0 && isScrolled) { setIsScrolled(false); } }}>

                <div className="grid-header" style={{ gridTemplateColumns: [...columns.map(col => `${col.width ?? 1}fr`)].join(' ') }}>
                    { columns.map((col, colIndex, columns) => {
                        const left = col.pinned ? columns.slice(0, colIndex).reduce((acc, curr) => acc + curr.width, 0) : 0;
                        return <GridHeaderItem 
                            key={col.key}
                            column={col}
                            left={left}
                            sort={config.sorts.find(s => s.key === col.key)?.dir}
                            onClick={handleColumnClick}
                            onContextMenu={handleColumnContextMenu} />
                    })}
                </div>

                <div className={'grid-spinner' + (loading ? ' visible' : '')}>
                    <div className="loader"></div>
                </div>
                
                <div className="grid-rows">
                { loading === false && items.map((item, rowIndex) => {
                    return (
                        <div key={item['id'] ?? rowIndex} className="grid-row" style={{ gridTemplateColumns: [...columns.map(col => `${col.width ?? 1}fr`)].join(' ') }}>
                            { columns.map((col, colIndex) => {
                                const value = col.key.split('.').reduce((acc, curr) => acc ? acc[curr] : undefined, item);

                                return <GridRowItem 
                                    key={col.key + item[col.key]}
                                    col={col}
                                    value={value} 
                                    item={item}
                                    width={col.width}
                                    pinned={col.pinned}
                                    onDoubleClick={onSelectItem}
                                    onContextMenu={handleItemContextMenu}
                                />
                            })}
                            <div className="grid-row-item-end"></div>
                        </div>
                    );
                })}
                </div>
            </div>
            {/* <div className="grid-footer">
                <div className="grid-footer-left">
                    {`Showing ${config.pageIndex * config.pageSize + 1} - ${Math.min(config.pageIndex * config.pageSize + config.pageSize, totalItems)} of ${totalItems} records`}
                    <button disabled={config.pageIndex === 0} onClick={e => handlePageChange(config.pageIndex - 1)}>Previous Page</button>
                    <button disabled={(config.pageIndex * config.pageSize) + config.pageSize >= totalItems} onClick={e => handlePageChange(config.pageIndex + 1)}>Next Page</button>
                </div>
                <div className="grid-footer-right"></div>
            </div> */}

            <GridMenu open={itemMenu.isOpen} onRequestClose={itemMenu.close} anchorPosition={itemMenuPos}>
                {
                    itemMenuItems.map((item, index) => {
                        switch(item.type) {
                            case 'divider': return <GridMenuDivider key={index} />;
                            case 'button':
                            default: 
                                return <GridMenuItem icon={item.icon} key={index} onClick={() => {
                                    item.onClick(selectedItem);
                                    itemMenu.close();
                                }}>{item.label}</GridMenuItem>
                        }
                    })
                }
            </GridMenu>
        </div>
    );
}

export interface GridHeaderItemProps<T>
{
    // name: string;
    // width: number;
    left?: number;
    minWidth?: number;
    // readOnly: boolean;
    // // pinned?: boolean;
    // sortable?: boolean;

    column: TableColumn<T>;
    sort? : 'asc' | 'desc';

    onReorderDrag?: (down: boolean, movement: Vector2) => void;
    onResize?: (down: boolean, width: number) => void;
    onContextMenu?: (e: React.MouseEvent<HTMLDivElement>, col: TableColumn<T>) => void;
    onClick?: (e: React.MouseEvent<HTMLDivElement>, col: TableColumn<T>) => void;
}

const GridHeaderItem = <T,>(props: GridHeaderItemProps<T>) =>
{
    const { column,  minWidth = 60, left = 0, onReorderDrag: onDrag, onResize, onContextMenu, onClick, sort } = props;
    const headerItemRef = useRef(null);
    const resizeControlRef = useRef(null);
    const menuControlRef = useRef(null);
    const [tempWidth, setTempWidth] = useState(0);
    const [isReordering, setIsReordering] = useState(false);

    const bind: any = useDrag((event) => {
        if(event.target === resizeControlRef.current)
        {
            if(event.first)
                setTempWidth(column.width);
            else
                onResize?.(event.last, Math.max(tempWidth + event.movement[0], minWidth));
        }
        else
        {
            if(event.intentional)
            {
                onDrag?.(event.last, event.movement);
                setIsReordering(!event.last);
            }
        }
    }, { threshold: 5 });

    const handleMenuClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const target: HTMLElement = e.target as HTMLElement;
        if(e.button === 0 && (target === menuControlRef.current || target.parentElement === menuControlRef.current))
        {
            onContextMenu?.(e, column);
            // e.preventDefault();
            // return false;
        }
        else
        {
            onClick?.(e, column);
        }
    }, [onContextMenu, onClick, column]);

    // const handleContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    //     onContextMenu?.(e);
    //     // e.preventDefault();
    //     // return false;
    // }, [onContextMenu]);

    const classes = ['grid-header-item'];
    if(column.pinned) classes.push('pinned');
    if(column.sortable) classes.push('sortable');

    return (
        <div className={classes.join(' ')} style={{ cursor: isReordering ? 'grabbing' : undefined, left }} {...bind()} onClick={handleMenuClick} onContextMenu={onContextMenu} ref={headerItemRef}>
            {/* {iconForType(type, readOnly)} */}
            <span className="header-label">{column.name}</span>

            {
                column.sortable && !!sort &&
                <span className="header-menu-button">{props.sort === 'asc' ? <ArrowDropDownIcon /> : props.sort === 'desc' ? <ArrowDropUpIcon /> : ''}</span>
            }

            {/* <span className="header-menu-button" ref={menuControlRef}><ExpandMoreIcon fontSize="inherit" /></span> */}
            {/* <div className="resize-control" ref={resizeControlRef}></div> */}
        </div>
    );
}

export interface GridRowItemProps<T>
{
    col: GridColumnInternal<T>;
    value: any;
    item: T;
    width: number;
    left?: number;
    onClick?: () => void;
    onDoubleClick?: (e: React.MouseEvent<HTMLDivElement>, item: T) => void;
    onContextMenu?: (e: React.MouseEvent<HTMLDivElement>, item: T) => void;
    onChange?: (value: any) => string | void | Promise<string | void>;
    pinned?: boolean;
    selected?: boolean;
    readOnly?: boolean;
}

const GridRowItem = <T,>(props: GridRowItemProps<T>) =>
{
    const { col, value, item, onClick, onDoubleClick, onChange, onContextMenu, selected, pinned, readOnly = false, left = 0 } = props;
 
    const itemRef = useRef<HTMLDivElement>(null);

    let cell: React.ReactNode;
    if(col.render)
        cell = col.render(value, item);
    else
        cell = value?.toString();

    const classes = ['grid-row-item'];
    if(pinned) classes.push('pinned');
    if(col.readOnly) classes.push('readonly');

    const handleDoubleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        onDoubleClick?.(e, item);
    }, [onDoubleClick, item]);

    const handleContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        onContextMenu?.(e, item);
    }, [onContextMenu, item]);

    return (
        <div 
            className={classes.join(' ')} 
            ref={itemRef}
            //style={{ width: `${col.width}px`, left: `${left}px` }} 
            style={{ left: `${left}px` }}
            tabIndex={-1}
            onMouseDown={onClick}
            onDoubleClick={handleDoubleClick}
            onContextMenu={handleContextMenu}>
            {cell}
            { col.showMenu &&
                <span className="grid-row-item-menu"><MoreVertIcon fontSize="inherit" /></span>
            }
        </div>
    );
}
