import TextFormatIcon from "@mui/icons-material/TextFormat";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TagIcon from "@mui/icons-material/Tag";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { Vector2, useDrag } from "@use-gesture/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextCell } from "./Cells/TextCell";
import { DateCell } from "./Cells/DateCell";
import { SelectCell } from "./Cells/SelectCell";
import { NumberCell } from "./Cells/NumberCell";
import { DateTimeCell } from "./Cells/DateTimeCell";

import "./Grid.scss";
import { GridMenu } from "./Menus/GridMenu";
import { useToggle } from "../../hooks/useToggle";
import { GridMenuDivider } from "./Menus/GridMenuDivider";
import { GridMenuItem } from "./Menus/GridMenuItem";

export type GridColumnType = 'text' | 'number' | 'date' | 'datetime' | 'select';
export interface GridSort { key: string, dir: 'asc' | 'desc' }
export interface GridFilter { key: string, op: 'eq', value: any }

export interface GridColumn
{
    key: string;
    name: string;
    type: GridColumnType;
    width: number;
    pinned?: boolean;
    readOnly?: boolean;
    showMenu?: boolean;
}

export interface GridOption { key: string, text?: string }
export interface GridSelectColumn extends GridColumn
{
    options?: GridOption[];
}

export interface GridColumnInternal extends GridColumn, GridSelectColumn
{
    width: number;
}

export interface GridContextMenuItem<T>
{
    label?: string,
    onClick?: (item: T) => void;
    icon?: JSX.Element;
    type?: 'button' | 'divider' | string;
}

export interface GridConfig
{
    columns: Array<GridColumn | GridSelectColumn>;
    sorts?: GridSort[];
    filters?: GridFilter[];

    pageSize?: number;
    pageIndex?: number;
}

export interface GridProps<T>
{
    items?: T[];
    totalItems?: number;

    config: GridConfig;
    
    loading?: boolean;
    canAddRow?: boolean;
    editable?: boolean;
    
    onConfigChanged?: (config: GridConfig) => Promise<void>;
    onSaveItem?: (row: T, property: string, value: any) => void | Promise<void>;
    //onCreateItem?: (row: T) => void | Promise<void>;
    onSelectItem?: (item: T) => void;
    //onColumnContextMenu?: (e: React.MouseEvent<HTMLDivElement>, column: GridColumn) => void;
    //onItemContextMenu?: (e: React.MouseEvent<HTMLDivElement>, item: T) => void;
    
    onColumnContextMenu?: (e: React.MouseEvent<HTMLDivElement>, column: GridColumn) => GridContextMenuItem<GridColumn>[];
    onItemContextMenu?: (e: React.MouseEvent<HTMLDivElement>, item: T) => GridContextMenuItem<T>[];
    
    //onAddColumnContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
    validate?: <T>(column: GridColumn, value: T) => string;
}

interface GridV2 { row: number, col: number };
const eqv2 = (p1: GridV2, p2: GridV2) => p1.row === p2.row && p1.col === p2.col;
const GRIDV2_EMPTY = { row: -1, col: -1 };
const defaultConfig: GridConfig = {
    columns: [],
    sorts: [],
    filters: [],
    pageSize: 10,
    pageIndex: 0
};

export const Grid = function<T>(props: GridProps<T>)
{
    const { items = [], totalItems, config: propsConfig, loading = false, canAddRow = false, editable: canEdit = false, onConfigChanged, onSaveItem, onSelectItem, onColumnContextMenu, onItemContextMenu } = props;

    const config = Object.assign(defaultConfig, propsConfig);

    const [pinnedColumns, setPinnedColumns] = useState<GridColumnInternal[]>([]);
    const [columns, setColumns] = useState<GridColumnInternal[]>([]);
    const [cursorV2, setCursorV2] = useState<GridV2>({ row: -1, col: -1 });
    const [isScrolled, setIsScrolled] = useState(false);
    const [isReordering, setIsReordering] = useState(false);
    const [reorderWidth, setReorderWidth] = useState(0);
    const [reorderX, setReorderX] = useState(0);

    const headerMenu = useToggle(false);
    const [headerMenuItems, setHeaderMenuItems] = useState<GridContextMenuItem<GridColumn>[]>([]);
    const [headerAnchorEl, setHeaderAnchorEl] = useState<HTMLElement>(null);
    const [selectedColumn, setSelectedColumn] = useState<GridColumn | null>(null);
    
    const itemMenu = useToggle(false);
    const [itemMenuItems, setItemMenuItems] = useState<GridContextMenuItem<T>[]>([]);
    const [itemMenuPos, setItemMenuPos] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const [selectedItem, setSelectedItem] = useState<T | null>(null);
    
    useEffect(() => {
        const convertToInternalCol = (col: GridColumn) => { return Object.assign({}, col, {}) as GridColumnInternal; }
        setColumns(config.columns.map(convertToInternalCol));
    }, [JSON.stringify(config)]);

    useEffect(() => {
        if(canEdit === false)
            setCursorV2(GRIDV2_EMPTY);
    }, [canEdit]);

    const notifyConfigChanged = useCallback(async (changes: Partial<GridConfig> = {}) => {        
        await onConfigChanged?.(Object.assign(config, changes));
    }, [pinnedColumns, columns, config]);

    const handleResize = useCallback((col: GridColumnInternal, last: boolean, newWidth: number) => {    
        col.width = newWidth;
        if(pinnedColumns.indexOf(col) >= 0)
            setPinnedColumns([...pinnedColumns]);
        else
            setColumns([...columns]);

        if(last)
            notifyConfigChanged();
    }, [pinnedColumns, columns, notifyConfigChanged]);

    const handleReorderDrag = useCallback((col: GridColumnInternal, last: boolean, x: number) => {
        if(pinnedColumns.indexOf(col) >= 0)
        {
            const colX = pinnedColumns.slice(0, pinnedColumns.indexOf(col)).reduce((acc, curr) => acc + curr.width, 0);
            setReorderX(colX + x);
        }
        else
        {
            let colX = columns.slice(0, columns.indexOf(col)).reduce((acc, curr) => acc + curr.width, 0);
            colX += pinnedColumns.reduce((acc, curr) => acc + curr.width, 0);
            setReorderX(colX + x);
        }

        setReorderWidth(col.width);
        setIsReordering(!last);
    }, [pinnedColumns, columns]);

    const setCursorPos = useCallback((pos: GridV2) => {
        if(canEdit)
            setCursorV2(pos);
    }, [canEdit]);

    const onChange = useCallback(async (col: GridColumnInternal, row: T, value: any) => {
        //await onSaveRow(row, { [col.key]: value } as Partial<T>);
        await onSaveItem(row, col.key, value);
    }, []);

    const handleColumnContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>, col: GridColumn) => {
        if(onColumnContextMenu)
        {
            headerMenu.open();
            setHeaderAnchorEl(e.currentTarget);
            setHeaderMenuItems(onColumnContextMenu(e, col));
            e.preventDefault();
            return false;
        }
    }, [onColumnContextMenu, headerMenu]);

    const handleItemContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>, item: T) => {
        if(onItemContextMenu)
        {
            itemMenu.open();
            setItemMenuPos({ x: e.clientX, y: e.clientY });
            setItemMenuItems(onItemContextMenu(e, item));
            setSelectedItem(item);
            
            e.preventDefault();
            return false;
        }
    }, [onItemContextMenu, itemMenu]);

    const handlePageChange = useCallback((pageIndex: number) => {
        notifyConfigChanged({ pageIndex });
    }, [notifyConfigChanged]);

    // const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLElement>) => {
    //     if(!e.shiftKey && !e.ctrlKey)
    //     {
    //         console.log(e);

    //         if(e.key === 'Tab')
    //         {
    //             e.preventDefault();
    //             setCursorV2({
    //                 row: (cursorV2.col + 1) % columns.length === 0 ? (cursorV2.row + 1) % items.length : cursorV2.row,
    //                 col: (cursorV2.col + 1) % columns.length
    //             });
    //         }
    //         else if(e.key === 'ArrowUp')
    //         {
    //             e.preventDefault();
    //             setCursorV2({ row: Math.max(cursorV2.row - 1, 0), col: cursorV2.col });
    //         }
    //         else if(e.key === 'ArrowDown')
    //         {
    //             e.preventDefault();
    //             setCursorV2({ row: Math.min(cursorV2.row + 1, items.length - 1), col: cursorV2.col });
    //         }
    //         else if(e.key === 'ArrowLeft')
    //         {
    //             e.preventDefault();
    //             setCursorV2({ row: cursorV2.row, col: Math.max(cursorV2.col - 1, 0) });
    //         }
    //         else if(e.key === 'ArrowRight')
    //         {
    //             e.preventDefault();
    //             setCursorV2({ row: cursorV2.row, col: Math.min(cursorV2.col + 1, columns.length - 1) });
    //         }
    //         else if(e.key === 'Enter')
    //         {
    //             e.preventDefault();
    //             setCursorV2({ row: Math.min(cursorV2.row + 1, items.length - 1), col: cursorV2.col });
    //         }
    //     }

    // }, [cursorV2]);

    const gridClasses = ['grid','scrollable'];
    if(isScrolled)
        gridClasses.push('scrolled');
    if(canEdit)
        gridClasses.push('editable');

    return (
        <div className="grid-root"> 
            <div className={'grid-spinner' + (loading ? ' visible' : '')}></div>

            <div className={gridClasses.join(' ')} onScroll={e => { if(e.currentTarget.scrollLeft > 0 && !isScrolled) { setIsScrolled(true); } else if(e.currentTarget.scrollLeft === 0 && isScrolled) { setIsScrolled(false); } }}>

                <div className="grid-header" style={{ gridTemplateColumns: [...columns.map(col => `${col.width}px`), '1px'].join(' ') }}>
                    { columns.map((col, colIndex, columns) => {
                        const left = col.pinned ? columns.slice(0, colIndex).reduce((acc, curr) => acc + curr.width, 0) : 0;
                        return <GridHeaderItem 
                            key={col.key}
                            pinned={col.pinned} 
                            name={col.name} 
                            width={col.width}
                            left={left}
                            type={col.type}
                            readOnly={col.readOnly}
                            sortDir={config.sorts?.find(s => s.key === col.key)?.dir}
                            onResize={(last, newWidth) => handleResize(col, last, newWidth)}
                            onReorderDrag={(last, movement) => handleReorderDrag(col, last, movement[0])} 
                            onContextMenu={(e) => handleColumnContextMenu(e, col)} />
                    })}
                    {/* { onAddColumnContextMenu && <GridHeaderAdd onClick={onAddColumnContextMenu} /> } */}
                    {/* <div className="grid-header-space"></div> */}
                </div>
                
                <div className="grid-rows">
                { items.map((item, rowIndex) => {

                    return (
                        <div 
                            key={item['id'] ?? rowIndex} 
                            className="grid-row" 
                            style={{ gridTemplateColumns: [...columns.map(col => `${col.width}px`), '1px'].join(' ') }} 
                            onContextMenu={e => handleItemContextMenu(e, item)}
                            onDoubleClick={e => canEdit === false && onSelectItem?.(item)}
                            >
                            { columns.map((col, colIndex) => {
                                const gridPos = { row: rowIndex, col: colIndex + pinnedColumns.length };
                                //const left = col.pinned ? columns.slice(0, colIndex).reduce((acc, curr) => acc + curr.width, 0) : 0;
                                const value = col.key.split('.').reduce((acc, curr) => acc ? acc[curr] : undefined, item);

                                return <GridRowItem 
                                    key={col.key + item[col.key]}
                                    col={col}
                                    value={value} 
                                    onClick={() => setCursorPos(gridPos)}
                                    onChange={value => onChange(col, item, value)}
                                    isReadOnly={col.readOnly || canEdit === false}
                                    isSelected={eqv2(cursorV2, gridPos)}
                                    // onDoubleClick={e => onSelectItem?.(e, item)}
                                    //onContextMenu={(e) => handleItemContextMenu(e, item)}
                                />
                            })}
                            <div className="grid-row-item-end"></div>
                        </div>
                    );
                })}
                </div>
                <div className="grid-column-ghost" style={{ display: isReordering ? 'block' : 'none', left: reorderX, width: reorderWidth }}></div>

            </div>
            <div className="grid-footer">
                <div className="grid-footer-left">
                    {
                        loading && `Loading page...`
                    }
                    {
                        !loading && `Showing ${config.pageIndex * config.pageSize + 1} - ${Math.min(config.pageIndex * config.pageSize + config.pageSize, totalItems)} of ${totalItems} records`
                    }
                    <button disabled={config.pageIndex === 0} onClick={e => handlePageChange(config.pageIndex - 1)}>Previous Page</button>
                    <button disabled={(config.pageIndex * config.pageSize) + config.pageSize >= totalItems} onClick={e => handlePageChange(config.pageIndex + 1)}>Next Page</button>
                </div>
                <div className="grid-footer-right"></div>
            </div>

            <GridMenu
                open={headerMenu.isOpen}
                onRequestClose={headerMenu.close}
                anchorEl={headerAnchorEl}>
                { headerMenuItems.map((menuItem, index) => {
                    switch(menuItem.type) {
                        case 'divider': return <GridMenuDivider key={index} />;
                        case 'button':
                        default:
                            return <GridMenuItem 
                                key={index} 
                                icon={menuItem.icon} 
                                onClick={() => { menuItem.onClick?.(selectedColumn); headerMenu.close(); }}>
                                {menuItem.label}
                            </GridMenuItem>;
                    }
                })}
            </GridMenu>

            <GridMenu
                open={itemMenu.isOpen}
                onRequestClose={itemMenu.close}
                anchorPosition={itemMenuPos}>
                { itemMenuItems.map((item, index) => {
                    switch(item.type) {
                        case 'divider': return <GridMenuDivider key={index} />;
                        case 'button':
                        default:
                            return <GridMenuItem 
                                key={index} 
                                icon={item.icon} 
                                onClick={() => { item.onClick?.(selectedItem); itemMenu.close(); }}>
                                {item.label}
                            </GridMenuItem>;
                    }
                })}
            </GridMenu>
        </div>
    );
}

export interface GridHeaderItemProps
{
    name: string;
    width: number;
    left?: number;
    minWidth?: number;
    type: GridColumnType;
    readOnly: boolean;
    pinned?: boolean;
    sortDir?: 'asc' | 'desc';

    onReorderDrag?: (down: boolean, movement: Vector2) => void;
    onResize?: (down: boolean, width: number) => void;
    onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const iconForType = (type: GridColumnType, readOnly: boolean) =>
{
    if(readOnly)
        return <LockIcon fontSize="inherit" />;

    switch(type)
    {
        case 'text': return <TextFormatIcon fontSize="inherit" />;
        case 'datetime': return <ScheduleIcon fontSize="inherit" />;
        case 'date': return <CalendarTodayIcon fontSize="inherit" />;
        case 'number': return <TagIcon fontSize="inherit" />;
        case 'select': return <ExpandCircleDownIcon fontSize="inherit" />
    }
}

const GridHeaderItem = (props: GridHeaderItemProps) =>
{
    const { name, width, type, minWidth = 90, left = 0, onReorderDrag: onDrag, onResize, onContextMenu, pinned, readOnly, sortDir } = props;
    const headerItemRef = useRef(null);
    const resizeControlRef = useRef(null);
    const menuControlRef = useRef(null);
    const [tempWidth, setTempWidth] = useState(0);
    const [isReordering, setIsReordering] = useState(false);

    const bind: any = useDrag((event) => {

        if(event.target === resizeControlRef.current)
        {
            if(event.first)
                setTempWidth(width);
            else
                onResize?.(event.last, Math.max(tempWidth + event.movement[0], minWidth));
        }
        else
        {
            if(event.intentional)
            {
                onDrag?.(event.last, event.movement);
                setIsReordering(!event.last);
            }
        }
    }, { threshold: 5 });

    const handleMenuClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const target: HTMLElement = e.target as HTMLElement;
        if(e.button === 0 && (target === menuControlRef.current || target.parentElement === menuControlRef.current))
        {
            onContextMenu?.(e);
            // e.preventDefault();
            // return false;
        }
    }, [onContextMenu]);

    // const handleContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    //     onContextMenu?.(e);
    //     // e.preventDefault();
    //     // return false;
    // }, [onContextMenu]);

    let sortIcon = null;
    if(sortDir === 'asc')
        sortIcon = <ArrowDownwardIcon fontSize="inherit" />;
    else if(sortDir === 'desc')
        sortIcon = <ArrowUpwardIcon fontSize="inherit" />;

    return (
        <div className={['grid-header-item', ...(pinned ? ['pinned'] : [])].join(' ')} style={{ cursor: isReordering ? 'grabbing' : undefined, left }} {...bind()} onClick={handleMenuClick} onContextMenu={onContextMenu} ref={headerItemRef}>
            {iconForType(type, readOnly)}
            <span className="header-label">{name} {sortIcon}</span>
            <span className="header-menu-button" ref={menuControlRef}><ExpandMoreIcon fontSize="inherit" /></span>
            <div className="resize-control" ref={resizeControlRef}></div>
        </div>
    );
}

const GridHeaderAdd = (props: { onClick?: (e: React.MouseEvent<HTMLDivElement>) => void }) =>
{
    return (
        <div className="grid-header-add grid-header-item" onClick={props.onClick}>
            <AddIcon fontSize="inherit" />
        </div>
    );
}

export interface GridRowItemProps
{
    col: GridColumnInternal;
    value: any;
    left?: number;
    onClick?: () => void;
    onDoubleClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
    onChange?: (value: any) => string | void | Promise<string | void>;
    isSelected?: boolean;
    isReadOnly?: boolean;
}

const GridRowItem = (props: GridRowItemProps) =>
{
    const { col, value, onClick, onDoubleClick, onChange, onContextMenu, isSelected: selected, isReadOnly = false, left = 0 } = props;
 
    const itemRef = useRef<HTMLDivElement>(null);
    const [isSaving, setIsSaving] = useState(false);

    const cellProps = {
        selected,
        value,
        onSave: onChange,
        options: col.options,
        readOnly: col.readOnly || isReadOnly,
    };

    let cell: React.ReactNode;
    switch(col.type)
    {
        case 'text': cell = <TextCell {...cellProps} />; break;
        case 'number': cell = <NumberCell {...cellProps} />; break;
        case 'select': cell = <SelectCell {...cellProps} />; break;
        case 'date': cell = <DateCell {...cellProps} />; break;
        case 'datetime': cell = <DateTimeCell {...cellProps} />; break;
        default: cell = <div>Invalid cell type</div>;
    }

    const classes = ['grid-row-item'];
    if(selected) classes.push('cursor');
    if(isSaving) classes.push('saving');
    if(col.pinned) classes.push('pinned');
    if(col.readOnly) classes.push('readonly');

    return (
        <div 
            className={classes.join(' ')} 
            ref={itemRef}
            style={{ left: `${left}px` }}
            tabIndex={-1}
            onMouseDown={onClick}
            onDoubleClick={onDoubleClick}
            onContextMenu={onContextMenu}>
            {cell}
            { col.showMenu &&
                <span className="grid-row-item-menu"><MoreVertIcon fontSize="inherit" /></span>
            }
        </div>
    );
}
