import { useCallback, useContext, useEffect, useState } from "react";
import { Drawer, DrawerProps } from "../../components/Drawer";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { BMTextField } from "../../components/Fields/BMTextField";
import { BMDateField } from "../../components/Fields/BMDateField";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMButton } from "../../components/Fields/BMButton";
import { Utils } from "../../utils/Utils";
import * as Yup from "yup";

//import "./EditAttendeeDrawer.scss";
import Attendee from "../../dto/Attendee";
import RegItem from "../../dto/RegItem";
import { BMDropdownField } from "../../components/Fields/BMDropdownField";
import { DialogContext, DialogOption } from "../../context/DialogContext";
import { Field } from "../../dto/Field";

export interface EditCustomFieldDrawerProps extends DrawerProps
{
    customFieldId: string;
    onSave?: () => void;
}

export const EditCustomFieldDrawer = (props: EditCustomFieldDrawerProps) => 
{
    const { open, onSave, onDismiss, customFieldId } = props;
    const { event, isLoaded } = useCurrentContext();
    const dialog = useContext(DialogContext);
    const queryClient = useQueryClient();
    const api = useAPI();
    const [error, setError] = useState<string>('');

    const fieldsQuery = useQuery(QueryKeys.Events.Fields.All(event.id), () => api.events.getById(event.id).fields.get(), { enabled: isLoaded, initialData: null });
    const updateField = useMutation(async (fieldData: Partial<Field>) => {
        const retval = await api.events.getById(event.id).fields.getById(customFieldId).update(fieldData);
        queryClient.invalidateQueries(QueryKeys.CustomFields.GetById(event.id, customFieldId));
        return retval;
    });
    const createField = useMutation(async (fieldData: Partial<Field>) => {
        const retval = await api.events.getById(event.id).fields.add(fieldData);
        queryClient.invalidateQueries(QueryKeys.CustomFields.GetById(event.id, retval.id));
        return retval;
    });

    // const attendeeQuery = useQuery(QueryKeys.Attendees.GetById(event.id, attendeeId), () => api.events.getById(event.id).attendees.getById(attendeeId).get(), { enabled: isLoaded && !!attendeeId, initialData: null });
    // const updateAttendee = useMutation(async (attendeeData: Partial<Attendee>) => {
    //     const retval = await api.events.getById(event.id).attendees.getById(attendeeId).update(attendeeData);
    //     queryClient.invalidateQueries(QueryKeys.Attendees.GetById(event.id, attendeeId));
    //     return retval;
    // });
    // const createAttendee = useMutation(async (attendeeData: Partial<Attendee>) => {
    //     const retval = await api.events.getById(event.id).attendees.add(attendeeData);
    //     queryClient.invalidateQueries(QueryKeys.Attendees.GetById(event.id, retval.id));
    //     return retval;
    // });

    // useEffect(() => {
    //     if(open)
    //     {
    //         if(attendeeId)
    //             attendeeQuery.refetch();
    //         setError('');
    //     }
    // }, [open]);

    // const onSubmit = useCallback(async (values: FormikValues, formikHelpers: FormikHelpers<any>) =>
    // {
    //     try
    //     {
    //         let retval: Attendee;
    //         if(attendeeId)
    //             retval = await updateAttendee.mutateAsync(values);
    //         else
    //             retval = await createAttendee.mutateAsync(values);

    //         onDismiss();
    //         //onSave(retval);
    //     }
    //     catch(e: any)
    //     {
    //         setError(e.message);
    //     }
    // }, [attendeeQuery.data, updateAttendee.mutateAsync, onDismiss, onSave]);

    const onSubmit = useCallback(async (values: FormikValues, formikHelpers: FormikHelpers<any>) => {
        try 
        {
            let retval: Field;
            if(customFieldId)
                retval = await updateField.mutateAsync(values);
            else
                retval = await createField.mutateAsync(values);

            onSave();
        }
        catch(ex: any)
        {
            setError(ex.message);
        }
    }, [customFieldId, updateField, createField, onSave]);

    const onDelete = useCallback(async (option: DialogOption) => {
        if(option.text === 'Delete')
        {
            //await api.events.getById(event.id).regItems.getById(regItemId).delete();
            await api.events.getById(event.id).fields.getById(customFieldId).delete();
            queryClient.invalidateQueries(QueryKeys.RegItems.All(event.id));
            onDismiss();
        }
    }, [api, event, customFieldId, onDismiss, dialog, queryClient]);

    const field = fieldsQuery.data?.items.find(f => f.id === customFieldId);

    const schema = Yup.object({
        name: Yup.string().required('Name is required').trim(),
        type: Yup.string().required('Type is required').trim(),
    });

    let initialData = {
        name: '',
        type: 'text',
    }

    let title = ''
    if(customFieldId && field)
    {
        initialData = {
            name: field.name,
            type: field.type,
        };
    }

    return (
        <Drawer title={customFieldId ? 'Edit Custom Field' : 'New Custom Field'} loading={fieldsQuery.isFetching || (fieldsQuery.isFetching && !!customFieldId)} {...props}>
            <Formik 
                initialValues={initialData} 
                onSubmit={onSubmit}
                validationSchema={schema}
                enableReinitialize>
                { formProps => (
                    <Form className="attendee-drawer">
                        <BMTextField name="name" label="Name" autoComplete="off" required autoFocus={!customFieldId} />
                        <BMDropdownField name="type" label="Type" options={[
                            { value: 'text', label: 'Text' },
                            // { value: 'number', label: 'Number' },
                            // { value: 'date', label: 'Date' },
                            // { value: 'dropdown', label: 'Dropdown' }
                        ]} />

                        <hr />
                        {
                            error && <div className="error">{error}</div>
                        }
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <BMSubmitButton>{customFieldId ? 'Save' : 'Create'}</BMSubmitButton>
                            <BMButton onClick={onDismiss}>Cancel</BMButton>

                            {
                                customFieldId && (
                                    <BMButton className="danger" style={{ marginLeft: 'auto' }}
                                    onClick={() => {
                                        dialog.show({
                                            title: `Delete ${field?.name}`,
                                            content: 'Are you sure you want to delete this custom field? All data stored in this field will be lost.',
                                            options: [
                                                { text: 'Delete', type: 'danger' },
                                                { text: 'Cancel' }
                                            ],
                                            onSelect: onDelete
                                        });
                                    }}>
                                        Delete
                                    </BMButton>
                                )
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
}
