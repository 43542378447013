
export const BadgePage = () => {
    return (
        <div>
            <h1>Badge Page</h1>
            <iframe 
                width={'100%'}
                height={'1000px'}
                src="https://omwaff.com" />
        </div>
    );
}