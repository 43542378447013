import { useEffect } from "react";
import { useRef } from "react";

export const usePrevious = (value: any) => 
{
    const prevValue = useRef(value);

    useEffect(() => {
        prevValue.current = value;
    }, [value]);

    return prevValue.current;
};