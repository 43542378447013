import { useState, useRef, useCallback, useContext } from "react";
import { useCurrentContext } from "../hooks/useCurrentContext";
import { Dialog } from "../components/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { BMTextField } from "../components/Fields/BMTextField";
import { BMDropdownField } from "../components/Fields/BMDropdownField";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useAPI } from "../hooks/useAPI";
import { DialogContext } from "../context/DialogContext";
import { ToastContext } from "../context/ToastContext";

export interface ShareDialogProps
{
    isOpen: boolean;
    onDismiss?: any;
}

export const ShareDialog = (props: ShareDialogProps) =>
{
    const { isOpen, onDismiss } = props;
    const { organization, isLoaded } = useCurrentContext();
    const toast = useContext(ToastContext);
    const api = useAPI();

    const shareOrg = useMutation(async (values: { email: string, role: string }) => {
        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
        
        await sleep(1000);
        //await api.organizations.getById(organization.id).share(values.email, values.role);

        toast.showToast({
            //message: `An invitation has been sent to ${values.email}`,
            message: `${organization?.title} has been shared with ${values.email}`,
            type: 'success'
        });

        handleDismiss();
        return true;
    });

    const handleDismiss = useCallback(() => {
        if(shareOrg.isLoading === false)
            onDismiss();
    }, [onDismiss, shareOrg]);

    const schema = Yup.object({
        email: Yup.string().email('Email address is not valid').required('Email is required'),
        role: Yup.string().required('Role is required')
    });

    const initialData = {
        email: '',
        role: 'edit'
    };

    return (
        <Dialog
            open={isOpen}
            title={`Share ${organization?.title}`}
            onDismiss={handleDismiss}
            style={{ width: '420px' }}>
            <p>Invite someone to collaborate with you on your events.</p>
            <Formik 
                initialValues={initialData} 
                validationSchema={schema}
                onSubmit={(values, formikHelpers: FormikHelpers<any>) => shareOrg.mutateAsync(values)}>
                { formProps => (
                    <Form style={{ margin: '20px 0 0 0' }}>
                        <BMTextField name="email" type="email" placeholder="someone@example.com" layout="vertical" />
                        <BMDropdownField
                            name="role"
                            layout="vertical"
                            options={[
                                { value: 'edit', label: 'Editor' },
                                { value: 'admin', label: 'Administrator' }
                            ]}
                            placeholder="Select a role"
                            description={(() => {
                                switch(formProps.values.role) {
                                    case 'edit': return 'Editors can view and edit all events in your organization, but cannot manage billing or user management.';
                                    case 'admin': return 'Administrators can manage all aspects of your organization, including billing and user management.';
                                    default: return '';
                                }
                            })()}
                            />

                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }} >
                            <button type="submit">Share</button>
                            <button onClick={onDismiss}>Cancel</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}
