
import { ComponentProps } from "react";
import { Portal } from "./Portal";

import "./Dialog.scss";

export interface DialogProps extends ComponentProps<any>
{
    title?: string;
    subtitle?: string;
    footer?: JSX.Element;

    open: boolean;
    modal?: boolean;
    loading?: boolean;
    onDismiss?: () => void;
    size?: 'small' | 'medium' | 'large';
}

export const Dialog = (props: DialogProps) =>
{
    const { open, onDismiss, modal = false, title, subtitle, children, footer, size = 'medium', loading = false } = props;

    const handleDismiss = (e: React.MouseEvent<HTMLDivElement>) =>
    {
        e.target === e.currentTarget && !modal && onDismiss?.();
    }

    if(open)
    {
        return (
            <Portal>
                <div className='dialog-container' onClick={handleDismiss}>
                    <div className={`dialog ${size}`}>
                        {
                            loading &&
                            <div className="dialog-loader">
                                <div className="loader"></div>
                            </div>
                        }
                        <div className="dialog-title">
                            <h3>{title}</h3>
                            {
                                props.subtitle && 
                                <span className="subtle">{subtitle}</span>
                            }
                        </div>
                        <div className="dialog-content">
                            {children}
                        </div>
                        {
                            footer &&
                            <div className="dialog-footer">
                                {footer}
                            </div>
                        }
                    </div>
                </div>
            </Portal>
        );
    }
    else
    {
        return null;
    }
}
