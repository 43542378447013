import { Component, useEffect, useState } from "react";
import { Route, Routes } from 'react-router-dom';

import Store from "../store/Store";
import Zone from "../dto/Zone";
import ZoneContext from "../context/ZoneContext";
import { ZoneDetails } from "../pages/Volunteers/ZoneDetails";
import { StoreActions } from "../store/StoreActions";

export interface ZoneRouteProps
{
}

export interface ZoneRouteState
{
    zone: Zone;
}

export const ZoneRoute = (props) => 
{
    const [zone, setZone] = useState<Zone>(null);

    // useEffect(() => 
    // {
    //     const store = Store.GetCurrent();
    //     store.AddChangeListener(onStoreUpdated, this);

    //     return () => {
    //         store.RemoveChangeListener(onStoreUpdated);
    //     }
    // });

    return null;
    
};

// export class ZoneRoute extends Component<ZoneRouteProps, ZoneRouteState>
// {
//     constructor(props: ZoneRouteProps)
//     {
//         super(props);

//         this.state = {
//             zone: null
//         };
//     }

//     public componentDidMount()
//     {
//         let store = Store.GetCurrent();
//         store.AddChangeListener(this.onStoreUpdated, this);
//     }

//     public componentWillUnmount()
//     {
//         let store = Store.GetCurrent();
//         store.RemoveChangeListener(this.onStoreUpdated);
//     }

//     private onStoreUpdated()
//     {
//         this.updateState(this.props);
//     }

//     public componentDidUpdate(prevProps, prevState)
//     {
//         if(this.props.match.params['zoneId'] !== (prevProps.match.params['zoneId']))
//         {
//             this.updateState(this.props);
//         }
//     }

//     private updateState(props: ZoneRouteProps)
//     {
//         let store = Store.GetCurrent();
//         let eventId = this.props.match.params['eventSlug'];
//         let zoneId = this.props.match.params['zoneId'];

//         //if(eventId && zoneId  && zoneId !== (this.state.zone ? this.state.zone.getSlug() : null))
//         if(eventId && zoneId)
//         {
//             let zone = store.getZone(eventId, zoneId);

//             if(zone)
//             {
//                 this.setState({ zone: zone });
//             }
//             else
//             {
//                 // TODO: fetch zone
//                 StoreActions.GetZones(eventId);
//             }
//         }
//     }

//     public render()
//     {
//         let path = this.props.match.path;
//         let context = {
//             zone: this.state.zone
//         };

//         // HACK: Not good practice but router param change doesn't trigger componentDidUpdate??
//         // if(this.props.match.params['zoneId'] !== (this.state.zone ? this.state.zone.getSlug() : null))
//         // {
//         //     setTimeout(() => { this.updateState(this.props); }, 0);
//         // }

//         return (
//             <ZoneContext.Provider value={context}>
//                 <Routes>
//                     <Route path={`${path}`} element={ZoneDetailsWithContext} />
//                 </Routes>
//             </ZoneContext.Provider>
//         );
//     }
// }