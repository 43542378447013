import { Component, FunctionComponent, useEffect, useState } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, TextField, Button } from "@mui/material";
import { BM } from "../../api/BM";
import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "../../constants/AppRoutes";

export const Callback = (props: any) =>
{
    const [errorMessage, setErrorMessage] = useState('');
    const nav = useNavigate();
    const { isLoading, isAuthenticated, handleRedirectCallback } = useAuth0();

    useEffect(() => 
    {
        handleRedirectCallback().then(result => {            
            const state = JSON.parse(window.sessionStorage.getItem(result.appState) || '{}');
            nav(state.returnTo || AppRoutes.Root());
        }).catch(error => {
            console.error(error);
            setErrorMessage(error.message);
        });
    }, [handleRedirectCallback]);

    return (
        <div className="auth-page">
            <div className="auth-card">
                <div className="auth-hole"></div>
                <div className="card-title">
                    <img className="logo" src="/images/logo.svg" alt="Badgemeister Logo" />
                </div>
                <div className="card-content">
                    {
                        isLoading &&
                        <>
                            <CircularProgress />
                            <div>Signing in...</div>
                        </>
                    }
                    {
                        !isLoading && errorMessage &&
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ fontSize: '16px' }}>Something went wrong...</div>
                            <div style={{ fontSize: '13px', fontWeight: 'normal' }}>{errorMessage}</div>
                        </div>
                    }
                    {/* <div>IsLoading: {isLoading ? 'true' : 'false'}</div>
                    <div>isAuthenticated: {isAuthenticated ? 'true' : 'false'}</div> */}
                </div>
            </div>
        </div>
    );
}
