export default class Checkpoint
{
    public id: string;
    public sid: string;
    public event: string;
    public title: string;

    public static FromJson(json: any): Checkpoint
    {
        const obj = new Checkpoint();
        obj.id = json.id || json._id;
        obj.sid = json.sid;
        obj.title = json.title;
        obj.event = json.event;

        return obj;
    }

    // public getSlug()
    // {
    //     return this.sid;
    // }
}
