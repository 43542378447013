import { ComponentProps, FunctionComponent, ReactNode } from "react";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";

export interface PageHeaderProps extends ComponentProps<any>
{
    navigationItems?: ReactNode;
    actionItems?: ReactNode;
}

export const Header: FunctionComponent<PageHeaderProps> = (props) =>
{
    return (
        <Toolbar disableGutters>
            {props.navigationItems}
            <Typography variant="h2" sx={{ flex: 1 }}>{props.children}</Typography>
            {props.actionItems}
        </Toolbar>
    )
};
