import { DatePicker } from "@mui/lab";
import { TextField, TextFieldProps } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { ReactElement, JSXElementConstructor, useEffect, useState, useCallback, CSSProperties } from "react";
import { parse, format } from "date-fns";
import { BMFieldProps } from "./BMFieldProps";

export interface BMDateFieldProps extends BMFieldProps
{
    includeTime?: boolean;
    openTo: 'day' | 'month' | 'year';
    disableFuture?: boolean;
}

export const BMDateField = (props) => 
{
    const { name, label, includeTime, disabled = false, layout = 'horizontal', placeholder, required = false, autoFocus = false } = props;
    const [field, meta, helpers] = useField<string>(name);
    const context = useFormikContext();
    const s: React.CSSProperties = { gridArea: name };

    // const [internalValue, setInternalValue] = useState<Date>(null);

    // useEffect(() => {
    //     if(includeTime)
    //         setInternalValue(new Date(field.value));
    //     else
    //         setInternalValue(parse(field.value, 'yyyy-MM-dd', new Date()));
    // }, [field.value]);

    // const onChange = useCallback((newValue: Date) => {
    //     if(includeTime)
    //     {
    //         helpers.setValue(newValue.toISOString());
    //     }
    //     else
    //     {
    //         helpers.setValue(format(newValue, 'yyyy-MM-dd'));
    //     }
    //     setInternalValue(newValue);
    // }, [helpers]);

    const style: CSSProperties = layout === 'horizontal' ? {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        flexDirection: 'row'
    } : {
        display: 'flex',
        flexDirection: 'column'
    }

    return (
        // <DatePicker 
        //     disableFuture={disableFuture}
        //     label={label}
        //     openTo={openTo}
        //     inputFormat="MM/dd/yyyy"
        //     value={internalValue}
        //     onChange={onChange}
        //     disabled={disabled || context.isSubmitting}
        //     renderInput={params => {
        //         const { error, helperText, ...otherProps } = params;
        //         return <TextField 
        //             name={name}
        //             error={meta.touched && !!meta.error} 
        //             helperText={meta.touched ? meta.error : ''} 
        //             style={s}
        //             onBlur={field.onBlur}
        //             {...otherProps} 
        //         />
        //     }}
        // />
        <div style={Object.assign({ gridArea: name }, style)}>
            { label && <label style={layout === 'horizontal' ? { flex: '0 0 140px', marginBottom: 0 } : {}}>{label} {required ? <span className="required">*</span> : ''}</label> }
            <input 
                type="date" 
                name={name}
                placeholder={placeholder}
                disabled={disabled || context.isSubmitting}
                required={required}
                autoFocus={autoFocus}
                style={{ gridArea: name, flex: 1 }}
                {...field}
            />
        </div>
    );
}
