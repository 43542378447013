import { useCallback, useContext, useState } from "react";
import { Drawer, DrawerProps } from "../../components/Drawer";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { BMTextField } from "../../components/Fields/BMTextField";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMButton } from "../../components/Fields/BMButton";
import * as Yup from "yup";

import "./EditTokenDrawer.scss";
import { DialogContext, DialogOption } from "../../context/DialogContext";
import RegToken from "../../dto/RegToken";
import { BMMultiSelectField } from "../../components/Fields/BMSelectField";

export interface EditTokenDrawerProps extends DrawerProps
{
    tokenId: string;
    onSave?: (token: RegToken) => void;
}

export const EditTokenDrawer = (props: EditTokenDrawerProps) => 
{
    const { onSave, onDismiss, tokenId } = props;
    const { event, isLoaded } = useCurrentContext();
    const dialog = useContext(DialogContext);
    const queryClient = useQueryClient();
    const api = useAPI();
    const [error, setError] = useState<string>('');

    const tokenQuery = useQuery(QueryKeys.RegTokens.GetById(event.id, tokenId), () => api.events.getById(event.id).regTokens.getById(tokenId).get(), { enabled: isLoaded && !!tokenId, initialData: null });
    const updateRegToken = useMutation(async (regTokenData: Partial<RegToken>) => {
        const retval = await api.events.getById(event.id).regTokens.getById(tokenId).update(regTokenData);
        queryClient.invalidateQueries(QueryKeys.RegTokens.GetById(event.id, tokenId));
        return retval;
    });
    const createRegToken = useMutation(async (regTokenData: Partial<RegToken>) => {
        const retval = await api.events.getById(event.id).regTokens.add(regTokenData);
        queryClient.invalidateQueries(QueryKeys.RegTokens.GetById(event.id, retval.id));
        return retval;
    });

    const onSubmit = useCallback(async (values: FormikValues, formikHelpers: FormikHelpers<any>) =>
    {
        try
        {
            const updateData: Partial<RegToken> = {
                name: values.name,
                email: values.email,
                regItems: values.regItems
            };

            let retval: RegToken;
            if(tokenId)
                retval = await updateRegToken.mutateAsync(updateData);
            else
                retval = await createRegToken.mutateAsync(updateData);

            onSave(retval);
        }
        catch(e: any)
        {
            setError(e.message);
        }
    }, [tokenId, updateRegToken, createRegToken, onSave]);

    const onDelete = useCallback(async (option: DialogOption) => {
        if(option.text === 'Delete')
        {
            await api.events.getById(event.id).regTokens.getById(tokenId).delete();
            queryClient.invalidateQueries(QueryKeys.RegTokens.All(event.id));
            onDismiss();
        }
    }, [api, event, tokenId, onDismiss, dialog, queryClient]);

    const getRegItems = useCallback(async (values: string[]) => {
        const result = await api.events.getById(event.id).regItems.get();
        return result.items.map(x => ({ label: x.title, value: x.id }));
    }, [api, event]);

    const schema = Yup.object({
        name: Yup.string().required('Name is required').trim(),
        email: Yup.string().required('Email is required').trim().email('Invalid email address'),
        regItems: Yup.array().required('Registration items are required').min(1, 'At least one registration item is required')
    });

    let initialData: Partial<RegToken> = {
        name: '',
        email: '',
        regItems: []
    };

    if(tokenId && tokenQuery.data)
    {
        initialData = {
            name: tokenQuery.data.name,
            email: tokenQuery.data.email,
            regItems: tokenQuery.data.regItems
        };
    }

    return (
        <Drawer title={tokenId ? 'Edit Token' : 'New Token'} loading={(tokenQuery.isFetching && !!tokenId)} {...props}>
            <Formik 
                initialValues={initialData} 
                onSubmit={onSubmit}
                validationSchema={schema}
                enableReinitialize>
                { formProps => (
                    <Form className="reg-token-drawer">
                        <BMTextField name="name" label="Name" required autoFocus={!tokenId} autoComplete="off" />
                        <BMTextField name="email" label="Email" type="email" required autoComplete="off" />

                        <BMMultiSelectField 
                            name="regItems" 
                            label="Registration Items" 
                            required 
                            // options={[
                            //     { label: 'Basic', value: '5e1fe767b9a7090017356e34' },
                            //     { label: 'Sponsor', value: '2' },
                            //     { label: 'Patron', value: '3' },
                            //     { label: 'PhilAnthropist', value: '4' },
                            // ]} 
                            options={getRegItems}
                        />

                        <hr />
                        { error && <div className="error">{error}</div> }
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <BMSubmitButton>{tokenId ? 'Save' : 'Create'}</BMSubmitButton>
                            <BMButton onClick={onDismiss}>Cancel</BMButton>

                            {
                                tokenId && 
                                <BMButton className="danger" style={{ marginLeft: 'auto' }}
                                onClick={() => {
                                    dialog.show({
                                        title: 'Delete Registration Token',
                                        content: 'Are you sure you want to delete this token? This action cannot be undone.',
                                        options: [
                                            { text: 'Delete', type: 'danger' },
                                            { text: 'Cancel' }
                                        ],
                                        onSelect: onDelete
                                    });
                                }}>
                                    Delete
                                </BMButton>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
}
