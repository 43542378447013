import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";
import DateAdapter from "@mui/lab/AdapterDateFns";

//import type {} from '@mui/x-data-grid/themeAugmentation';
import { LocalizationProvider } from "@mui/x-date-pickers";

import "./styles/index.scss";
import "./styles/typography.scss";
import "./styles/pages/signup.scss";
import { App } from "./App";
import { ClerkProvider } from "@clerk/clerk-react";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const theme = createTheme({
    typography: {
        fontFamily: 'var(--font-body)',
        htmlFontSize: 10,
        fontSize: 14,
        h1: { fontSize: '3rem' },
        h2: { fontSize: '2.5rem', fontWeight: 500 },
        h3: { fontSize: '2.2rem', fontWeight: 500 },
        h4: { fontSize: '2rem', fontWeight: 500 },
        h5: { fontSize: '1.8rem', color: '#333', fontWeight: 500 },
        h6: { fontSize: '1.5rem' },
        body1: { fontSize: '1.4rem' },
        body2: { fontSize: '1.2rem' },
        subtitle1: { textTransform: 'none' },
        subtitle2: { textTransform: 'none' },
    },
    palette: {
        mode: 'dark',
        primary: { 
            main: '#e84930'
        },
        secondary: {
            //main: '#1c2650'
            main: '#f5f4f3'
        },
        // text: {
        //     primary: '#fff',
        //     secondary: '#fff'
        // },
    },
    // palette: {
    //     primary: {
    //         main: '#E84930'
    //         //main: '#18499B'
    //     },
    //     secondary: {
    //         main: '#1c2650'
    //         //main: '#0fa0ce'
    //     }
    // },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'var(--color-body)'
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: { fontSize: '1.4rem', margin: '0 12px', borderRadius: '5px' }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: { fontSize: '1.4rem' }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
            }
        },
        MuiButton: {
            styleOverrides: {
                root: { 
                    textTransform: 'none', 
                    letterSpacing: 'normal', 
                    boxShadow: 'none !important',
                    //':hover': { boxShadow: 'none' } 
                }
            },
        },
        MuiListItemButton: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true
            }
        },
        // MuiDataGrid: {
        //     styleOverrides: {
        //         root: {
        //             '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
        //                 outline: "none !important"
        //             }
        //         }
        //     }
        // },
        MuiTab: {
            styleOverrides: {
                root: { textTransform: 'none', letterSpacing: 'normal' }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: { padding: '20px' }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: { padding: '20px 20px' }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: { 
                    borderRadius: '4px',
                    ':before': { border: 'none !important' },
                    ':after': { border: 'none !important' }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                container: {
                    alignItems: 'start'
                }
            }
        }
        // MuiButton: {
        //     styleOverrides: { root: { textTransform: 'none' } }
        // },
        // MuiPaper: {
        //     styleOverrides: { 
        //         root: {
        //         marginBottom: '20px'
        //         }
        //     }
        // },
        // MuiDialog: {
        //     styleOverrides: {
        //         paper: {
        //             minWidth: '480px',
        //             padding: '0'
        //         },
        //         scrollPaper: {
        //             alignItems: 'flex-start'
        //         }
        //     }
        // },
        // MuiTableCell: {
        //     styleOverrides: {
        //         root: {
        //             borderBottom: '1px solid rgba(224,224,224,0.5)',
        //             padding: '12px 24px'
        //         },
        //         // body: {
        //         //     fontSize: '1.5rem'
        //         // }
        //     }
        // },
        // MuiDialogContent: {
        //     styleOverrides: {
        //         root: {
        //             //padding: '24px 24px 0 24px'
        //         }
        //     }
        // },
        // MuiDialogTitle: {
        //     styleOverrides: {
        //         root: {
        //             //backgroundColor: '#1c2650',
        //             //backgroundImage: 'linear-gradient(45deg, rgba(88, 0, 0, .6), hsla(0, 0%, 100%, 0))',
                    
        //             //color: 'white'
        //         }
        //     }
        // },
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         input: {
        //             padding: '14px 12px',
        //             // backgroundColor: '#FAFBFC'
        //         }
        //     }
        // }
    }
});

const clerkPublishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const root = createRoot(document.getElementById('app-root')!);
root.render(
    <ThemeProvider theme={theme}>
        <LocalizationProvider>
            <ClerkProvider publishableKey={clerkPublishableKey}>
            {/* <Auth0Provider
                domain={process.env.REACT_APP_AUTH_DOMAIN}
                clientId={process.env.REACT_APP_AUTH_CLIENTID}
                redirectUri={`${window.location.origin}/auth/callback`}
                audience={process.env.REACT_APP_AUTH_AUDIENCE}
                //onRedirectCallback={onRedirectCallback}
                skipRedirectCallback={true}> */}
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </QueryClientProvider>
            {/* </Auth0Provider> */}
            </ClerkProvider>
        </LocalizationProvider>
    </ThemeProvider>
);
