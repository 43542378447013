
import "./Chip.scss";

export interface ChipProps extends React.HTMLAttributes<HTMLDivElement>
{
    color?: 'success' | 'warning' | 'danger' | string;
}

export const Chip = (props: ChipProps) => {
    const { children, className, color = '', ...rest } = props;

    const classes = `chip chip-${color} ${className || ''}`;

    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
}