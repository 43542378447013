
export const AppRoutes = 
{
    Root: () => '/',
    OrgDashboard: (orgId: string) => `/${orgId}`,
    EventDashboard: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}`,
    
    Login: (relativeUrl: string = '') => `/auth/login${relativeUrl ? `?returnTo=${relativeUrl}` : ''}`,
    Logout: () => `${window.location.origin}/auth/logout`,

    Event: {
        Root: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}`,
        Overview: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/overview`,
        Settings: {
            Root: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/settings`,
            General: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/settings/general`,
            CustomFields: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/settings/fields`,
            Emails: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/settings/emails`,
            Notifications: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/settings/notifications`,
            Permissions: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/settings/permissions`,
            Webhooks: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/settings/webhooks`,
            Advanced: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/settings/advanced`,
        },
        Activities: {
            Root: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/activities`,
            List: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/activities/list`,
            Calendar: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/activities/calendar`,
            Automations: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/activities/automations`,
            Item:(orgId: string, eventId: string, activityId: string) => `/${orgId}/events/${eventId}/activities/${activityId}`,
        },
        Attendees: {
            Root: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/attendees`,
            List: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/attendees/list`,
            Archived: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/attendees/archived`,
            View: (orgId: string, eventId: string, attendeeId: string) => `/${orgId}/events/${eventId}/attendees/${attendeeId}`,
            Edit: (orgId: string, eventId: string, attendeeId: string) => `/${orgId}/events/${eventId}/attendees/${attendeeId}/edit`,
        },
        Registration: {
            Root: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/registration`,
            Tiers: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/registration/tiers`,
            Addons: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/registration/addons`,
            Tokens: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/registration/tokens`,
        },
        Badges: {
            Root: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/badges`,
        },
        Engagement: {
            Root: (orgId: string, eventId: string) => `/${orgId}/events/${eventId}/engagement`,
        }
    }
}