import { useState, useCallback, useContext } from "react";
import { useToggle } from "../hooks/useToggle";
import { Grid, GridColumn, GridProps } from "../components/Grid/Grid"
import { GridMenu } from "../components/Grid/Menus/GridMenu";
import { GridMenuItem } from "../components/Grid/Menus/GridMenuItem";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PinIcon from "@mui/icons-material/PushPin";
import PrintIcon from "@mui/icons-material/Print";
import { GridMenuDivider } from "../components/Grid/Menus/GridMenuDivider";
import { Form, Formik } from "formik";
import { BMTextField } from "../components/Fields/BMTextField";
import { BMDropdownField } from "../components/Fields/BMDropdownField";
import { BMSubmitButton } from "../components/Fields/BMSubmitButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { DialogContext } from "../context/DialogContext";

export interface BMGridProps<T> extends GridProps<T>
{
    // onViewItem?: (item: T) => void;
    // onEditItem?: (item: T) => void;
    // onDeleteItem?: (item: T) => void;
    // onPrintBadge?: (item: T) => void;
    onNewField?: (name: string, type: string) => void;
    onDeleteField?: (field: GridColumn) => void;

    headerMenuItems?: [
        {type: 'button' | 'divider', icon: React.ReactNode, label: string, onClick: () => void},
    ]
}

export const AttendeeGrid = function<T>(props: BMGridProps<T>) 
{
    const { onNewField, onDeleteField, ...gridProps } = props;
    const headerMenu = useToggle(false);
    const itemMenu = useToggle(false);
    const addFieldMenu = useToggle(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>(null);
    const [itemMenuPos, setItemMenuPos] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const [addFieldMenuAnchorEl, setAddFieldMenuAnchorEl] = useState<HTMLElement>(null);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const dialog = useContext(DialogContext);

    const [newFieldName, setNewFieldName] = useState('');
    const [newFieldType, setNewFieldType] = useState('text');

    const handleHeaderContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>, col: GridColumn) => {
        setMenuAnchorEl(e.currentTarget);
        setSelectedColumn(col);
        headerMenu.open();
    }, [menuAnchorEl, headerMenu]);

    const handleItemMenu = useCallback((e: React.MouseEvent<HTMLDivElement>, item: T) => {
        setItemMenuPos({ x: e.clientX, y: e.clientY });
        itemMenu.open();
        setSelectedItem(item);
    }, [itemMenu]);

    const handleAddFieldMenu = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        setAddFieldMenuAnchorEl(e.currentTarget);
        addFieldMenu.open();
    }, [menuAnchorEl, headerMenu]);

    // const handleViewItem = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    //     onViewItem?.(selectedItem);
    //     itemMenu.close();
    // }, [onViewItem, selectedItem]);

    // const handleEditItem = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    //     onEditItem?.(selectedItem);
    //     itemMenu.close();
    // }, [onEditItem, selectedItem]);

    // const handleArchiveItem = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    //     onDeleteItem?.(selectedItem);
    //     itemMenu.close();
    // }, [onDeleteItem, selectedItem]);

    // const handlePrintItem = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    //     onPrintBadge?.(selectedItem);
    //     itemMenu.close();
    // }, [onPrintBadge, selectedItem]);

    const handleNewField = useCallback(async values => {
        await onNewField?.(values.fieldName, values.fieldType);
        addFieldMenu.close();
    }, [addFieldMenu]);

    const handleDeleteField = useCallback(async () => {
        await onDeleteField?.(selectedColumn);

        dialog.show({
            title: 'Delete Field',
            content: <div>Are you sure you want to delete the field <strong>{selectedColumn.label}</strong>?</div>,
            options: [
                { text: 'Delete', type: 'danger' },
                { text: 'Cancel' }
            ],
            onSelect: async (option) => {
                if(option.text === 'Delete')
                    onDeleteField?.(selectedColumn);
            }
        });

        headerMenu.close();
    }, [headerMenu]);

    return (
        <>
            <Grid
                {...gridProps}
                //onItemContextMenu={handleItemMenu}
                //onColumnContextMenu={handleHeaderContextMenu}
                //onAddColumnContextMenu={handleAddFieldMenu}
            />
        
            <GridMenu open={headerMenu.isOpen} onRequestClose={headerMenu.close} anchorEl={menuAnchorEl}>
                {/* <GridMenuItem icon={EditIcon}>Edit Field</GridMenuItem> */}
                <GridMenuItem icon={<ArrowDownwardIcon />}>Sort ascending</GridMenuItem>
                <GridMenuItem icon={<ArrowUpwardIcon />}>Sort descending</GridMenuItem>
                {/* <GridMenuItem icon={PinIcon} onClick={() => {}}>Pin Column</GridMenuItem> */}
                <GridMenuDivider />
                <GridMenuItem icon={<DeleteIcon />} onClick={handleDeleteField}>Delete Field</GridMenuItem>
            </GridMenu>

            {/* <GridMenu open={itemMenu.isOpen} onRequestClose={itemMenu.close} anchorPosition={itemMenuPos}>
                <GridMenuItem icon={<VisibilityIcon />} onClick={handleViewItem}>View attendee</GridMenuItem>
                <GridMenuItem icon={<EditIcon />} onClick={handleEditItem}>Edit attendee</GridMenuItem>
                <GridMenuDivider />
                <GridMenuItem icon={<PrintIcon />} onClick={handlePrintItem}>Print badge</GridMenuItem>
                <GridMenuDivider />
                <GridMenuItem icon={<DeleteIcon />} onClick={handleArchiveItem}>Archive</GridMenuItem>
            </GridMenu> */}

            <GridMenu open={addFieldMenu.isOpen} onRequestClose={addFieldMenu.close} anchorEl={addFieldMenuAnchorEl}>
                <div style={{ padding: '5px 10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Formik initialValues={{
                            fieldName: '',
                            fieldType: 'text'
                        }}
                        onSubmit={handleNewField}>
                        { formProps => {
                                return (
                                    <Form>
                                        <BMTextField name="fieldName" placeholder="Field Name" autoFocus />
                                        <BMDropdownField name="fieldType" options={[
                                            { label: 'Single line of text', value: 'text' },
                                            { label: 'Number', value: 'number' },
                                            { label: 'Checkbox', value: 'checkbox' },
                                        ]} />
                                        <GridMenuDivider />
                                        <BMSubmitButton disabled={!(formProps.values.fieldName && formProps.values.fieldType)}>Create Field</BMSubmitButton>
                                    </Form>
                                );
                            }
                        }
                    </Formik>
                </div>
            </GridMenu>
        </>
    );
}
