import { useCallback, useEffect, useState } from "react";
import { Drawer, DrawerProps } from "../components/Drawer";
import { useCurrentContext } from "../hooks/useCurrentContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../data/QueryKeys";
import { useAPI } from "../hooks/useAPI";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { BMTextField } from "../components/Fields/BMTextField";
import { BMDateField } from "../components/Fields/BMDateField";
import { BMSubmitButton } from "../components/Fields/BMSubmitButton";
import { BMButton } from "../components/Fields/BMButton";
import { Utils, copyInto } from "../utils/Utils";
import * as Yup from "yup";

//import "./EditEventDrawer.scss";
import { Event } from "../dto/Event";
import { BMTextArea } from "../components/Fields/BMTextArea";
import { BMDateTimeField } from "../components/Fields/BMDateTimeField";

export interface EventDrawerProps extends DrawerProps
{
    eventId: string;
    onSave?: (activity: Event) => void;
}

export const EditEventDrawer = (props: EventDrawerProps) => 
{
    const { open, onSave, onDismiss, eventId } = props;
    const { organization, event, isLoaded } = useCurrentContext();
    const queryClient = useQueryClient();
    const api = useAPI();
    const [error, setError] = useState<string>('');

    const eventQuery = useQuery(QueryKeys.Events.Id(eventId), () => api.events.getById(event.id).get(), { enabled: isLoaded && !!eventId, initialData: null });
    const updateEvent = useMutation(async (eventData: Partial<Event>) => {
        const retval = await api.events.getById(event.id).update(eventData);
        queryClient.invalidateQueries(QueryKeys.Events.Id(eventId));
        return retval;
    });
    const createEvent = useMutation(async (eventData: Partial<Event>) => {
        const retval = await api.events.add(eventData);
        queryClient.invalidateQueries(QueryKeys.Events.Id(retval.id));
        return retval;
    });

    useEffect(() => {
        if(open)
        {
            if(eventId)
                eventQuery.refetch();
            setError('');
        }
    }, [open]);

    const onSubmit = useCallback(async (values: FormikValues, formikHelpers: FormikHelpers<any>) =>
    {
        values.organization = organization.id;
        console.log(values);
        
        try
        {
            let retval: Event;
            if(eventId)
                retval = await updateEvent.mutateAsync(values);
            else
                retval = await createEvent.mutateAsync(values);

            onDismiss();
            onSave(retval);
        }
        catch(e: any)
        {
            setError(e.message);
        }
    }, [eventQuery.data, updateEvent.mutateAsync, onDismiss, onSave]);

    const schema = Yup.object({
        title: Yup.string().required('Name is required').trim(),
        description: Yup.string().trim()
    });

    let initialData = {
        title: '',
        description: '',
        startTime: new Date(),
        endTime: new Date()
    };

    if(eventId && eventQuery.data)
        initialData = copyInto(initialData, eventQuery.data);

    return (
        <Drawer title={eventId ? 'Edit Event' : 'New Event'} loading={eventQuery.isFetching} {...props}>
            <Formik 
                initialValues={initialData} 
                onSubmit={onSubmit}
                validationSchema={schema}
                enableReinitialize>
                { formProps => (
                    <Form className="activity-drawer">
                        <h4>Session Information</h4>
                        <BMTextField name="title" label="Name" autoComplete="off" required />
                        <BMTextArea name="description" label="Description" />
                        <BMDateTimeField name="startTime" label="Start Time" />
                        <BMDateTimeField name="endTime" label="End Time" />
                        <hr />
                        {
                            error && <div className="error">{error}</div>
                        }
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <BMSubmitButton>{eventId ? 'Save' : 'Create'}</BMSubmitButton>
                            <BMButton onClick={onDismiss}>Cancel</BMButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
}
