import { AppDispatcher } from "./AppDispatcher";
import * as AppConstants from "./AppConstants";
import Session from "../dto/Session";
import { Activity } from "../dto/Activity";
import { Event } from "../dto/Event";
import Attendee from "../dto/Attendee";
import Zone from "../dto/Zone";
import Shift from "../dto/Shift";

export class AppActions
{
    public static EventCreated(event: Event)
    {
        AppDispatcher.emit({
            eventName: AppConstants.EVENT_CREATED,
            payload: {
                eventId: event.id,
                event: event
            }
        });
    }

    public static EventCreatedError(error: string)
    {
        AppDispatcher.emit({
            eventName: AppConstants.EVENT_CREATED_ERROR,
            payload: {
                error: error
            }
        });
    }

    public static EventUpdated(event: Event)
    {
        AppDispatcher.emit({
            eventName: AppConstants.EVENT_UPDATED,
            payload: {
                eventId: event.id,
                event: event
            }
        });
    }

    public static EventUpdatedError(error: string)
    {
        AppDispatcher.emit({
            eventName: AppConstants.EVENT_UPDATED_ERROR,
            payload: {
                error: error
            }
        });
    }

    public static EventDeleted(event: Event)
    {
        AppDispatcher.emit({
            eventName: AppConstants.EVENT_DELETED,
            payload: {
                eventId: event.id,
                event: event
            }
        });
    }

    public static EventDeletedError(error: string)
    {
        AppDispatcher.emit({
            eventName: AppConstants.EVENT_DELETED_ERROR,
            payload: {
                error: error
            }
        });
    }

    public static SessionCreated(session: Session)
    {
        AppDispatcher.emit({
            eventName: AppConstants.SESSION_CREATED,
            payload: {
                sessionId: session.id,
                session: session
            }
        });
    }

    public static SessionCreatedError(error: string)
    {
        AppDispatcher.emit({
            eventName: AppConstants.SESSION_CREATED_ERROR,
            payload: {
                error: error
            }
        });
    }

    public static SessionUpdated(session: Session)
    {
        AppDispatcher.emit({
            eventName: AppConstants.SESSION_UPDATED,
            payload: {
                sessionId: session.id,
                session: session
            }
        });
    }

    public static SessionUpdatedError(error: string)
    {
        AppDispatcher.emit({
            eventName: AppConstants.SESSION_UPDATED_ERROR,
            payload: {
                error: error
            }
        });
    }

    public static SessionDeleted(session: Session)
    {
        AppDispatcher.emit({
            eventName: AppConstants.SESSION_DELETED,
            payload: {
                sessionId: session.id,
                session: session
            }
        });
    }

    public static SessionDeletedError(error: string)
    {
        AppDispatcher.emit({
            eventName: AppConstants.SESSION_DELETED_ERROR,
            payload: {
                error: error
            }
        });
    }

    public static ActivityCreated(activity: Activity) { this.emitEvent(AppConstants.ACTIVITY_CREATED, { value: activity }); }
    public static ActivityCreatedError(error: string) { this.emitEvent(AppConstants.ACTIVITY_CREATED_ERROR, { value: error }); }
    public static ActivityUpdated(activity: Activity) { this.emitEvent(AppConstants.ACTIVITY_UPDATED, { value: activity }); }
    public static ActivityUpdatedError(error: string) { this.emitEvent(AppConstants.ACTIVITY_UPDATED_ERROR, { value: error }); }
    public static ActivityArchived(activity: Activity) { this.emitEvent(AppConstants.ACTIVITY_ARCHIVED, { value: activity }); }
    public static ActivityArchivedError(error: string) { this.emitEvent(AppConstants.ACTIVITY_ARCHIVED_ERROR, { value: error }); }
    public static ActivityDeleted(activity: Activity) { this.emitEvent(AppConstants.ACTIVITY_DELETED, { value: activity }); }
    public static ActivityDeletedError(error: string) { this.emitEvent(AppConstants.ACTIVITY_DELETED_ERROR, { value: error }); }

    public static AttendeeCreated(attendee: Attendee) { this.emitEvent(AppConstants.ATTENDEE_CREATED, { attendee: attendee }); }
    public static AttendeeCreatedError(error: string) { this.emitEvent(AppConstants.ATTENDEE_CREATED_ERROR, { error: error }); }
    public static AttendeeUpdated(attendee: Attendee) { this.emitEvent(AppConstants.ATTENDEE_UPDATED, { attendee: attendee }); }
    public static AttendeeUpdatedError(error: string) { this.emitEvent(AppConstants.ATTENDEE_UPDATED_ERROR, { error: error }); }
    public static AttendeeArchived(attendee: Attendee) { this.emitEvent(AppConstants.ATTENDEE_ARCHIVED, { attendee: attendee }); }
    public static AttendeeArchivedError(error: string) { this.emitEvent(AppConstants.ATTENDEE_ARCHIVED_ERROR, { error: error }); }
    public static AttendeeDeleted(attendee: Attendee) { this.emitEvent(AppConstants.ATTENDEE_DELETED, { attendee: attendee }); }
    public static AttendeeDeletedError(error: string) { this.emitEvent(AppConstants.ATTENDEE_DELETED_ERROR, { error: error }); }

    public static ZoneCreated(zone: Zone) { this.emitEvent(AppConstants.ZONE_CREATED, { value: zone }); }
    public static ZoneCreatedError(error: string) { this.emitEvent(AppConstants.ZONE_CREATED_ERROR, { value: error }); }
    public static ZoneUpdated(zone: Zone) { this.emitEvent(AppConstants.ZONE_UPDATED, { value: zone }); }
    public static ZoneUpdatedError(error: string) { this.emitEvent(AppConstants.ZONE_UPDATED_ERROR, { value: error }); }
    public static ZoneArchived(zone: Zone) { this.emitEvent(AppConstants.ZONE_ARCHIVED, { value: zone }); }
    public static ZoneArchivedError(error: string) { this.emitEvent(AppConstants.ZONE_ARCHIVED_ERROR, { value: error }); }
    public static ZoneDeleted(zone: Zone) { this.emitEvent(AppConstants.ZONE_DELETED, { value: zone }); }
    public static ZoneDeletedError(error: string) { this.emitEvent(AppConstants.ZONE_DELETED_ERROR, { value: error }); }

    public static ShiftCreated(shift: Shift) { this.emitEvent(AppConstants.SHIFT_CREATED, { value: shift }); }
    public static ShiftCreatedError(error: string) { this.emitEvent(AppConstants.SHIFT_CREATED_ERROR, { value: error }); }
    public static ShiftUpdated(shift: Shift) { this.emitEvent(AppConstants.SHIFT_UPDATED, { value: shift }); }
    public static ShiftUpdatedError(error: string) { this.emitEvent(AppConstants.SHIFT_UPDATED_ERROR, { value: error }); }
    public static ShiftArchived(shift: Shift) { this.emitEvent(AppConstants.SHIFT_ARCHIVED, { value: shift }); }
    public static ShiftArchivedError(error: string) { this.emitEvent(AppConstants.SHIFT_ARCHIVED_ERROR, { value: error }); }
    public static ShiftDeleted(shift: Shift) { this.emitEvent(AppConstants.SHIFT_DELETED, { value: shift }); }
    public static ShiftDeletedError(error: string) { this.emitEvent(AppConstants.SHIFT_DELETED_ERROR, { value: error }); }

    private static emitEvent(eventName: string, payload)
    {
        AppDispatcher.emit({
            eventName: eventName,
            payload: payload
        });
    }
}

export default AppActions;