export const EVENT_CREATED = "eventcreated";
export const EVENT_CREATED_ERROR = "eventcreatederror";
export const EVENT_DELETED = "eventdeleted";
export const EVENT_DELETED_ERROR = "eventdeletederror";
export const EVENT_ARCHIVED = "eventarchived";
export const EVENT_ARCHIVED_ERROR = "eventarchivederror";
export const EVENT_UPDATED = "eventupdated";
export const EVENT_UPDATED_ERROR = "eventupdatederror";

export const SESSION_CREATED = "sessioncreated";
export const SESSION_CREATED_ERROR = "sessioncreatederror";
export const SESSION_DELETED = "sessiondeleted";
export const SESSION_DELETED_ERROR = "sessiondeletederror";
export const SESSION_ARCHIVED = "sessionarchived";
export const SESSION_ARCHIVED_ERROR = "sessionarchivederror";
export const SESSION_UPDATED = "sessionupdated";
export const SESSION_UPDATED_ERROR = "sessionupdatederror";

export const ACTIVITY_CREATED = "activity.created";
export const ACTIVITY_CREATED_ERROR = "activity.createderror";
export const ACTIVITY_DELETED = "activity.deleted";
export const ACTIVITY_DELETED_ERROR = "activity.deletederror";
export const ACTIVITY_ARCHIVED = "activity.archived";
export const ACTIVITY_ARCHIVED_ERROR = "activity.archivederror";
export const ACTIVITY_UPDATED = "activity.updated";
export const ACTIVITY_UPDATED_ERROR = "activity.updatederror";

export const PERSON_CREATED = "person.created";
export const PERSON_CREATED_ERROR = "person.created.error";
export const PERSON_DELETED = "person.deleted";
export const PERSON_DELETED_ERROR = "person.deleted.error";
export const PERSON_ARCHIVED = "person.archived";
export const PERSON_ARCHIVED_ERROR = "person.archived.error";
export const PERSON_UPDATED = "person.updated";
export const PERSON_UPDATED_ERROR = "person.updated.error";

export const ATTENDEE_CREATED = "attendee.created";
export const ATTENDEE_CREATED_ERROR = "attendee.created.error";
export const ATTENDEE_DELETED = "attendee.deleted";
export const ATTENDEE_DELETED_ERROR = "attendee.deleted.error";
export const ATTENDEE_ARCHIVED = "attendee.archived";
export const ATTENDEE_ARCHIVED_ERROR = "attendee.archived.error";
export const ATTENDEE_UPDATED = "attendee.updated";
export const ATTENDEE_UPDATED_ERROR = "attendee.updated.error";

export const ZONE_CREATED = "zone.created";
export const ZONE_CREATED_ERROR = "zone.created.error";
export const ZONE_DELETED = "zone.deleted";
export const ZONE_DELETED_ERROR = "zone.deleted.error";
export const ZONE_ARCHIVED = "zone.archived";
export const ZONE_ARCHIVED_ERROR = "zone.archived.error";
export const ZONE_UPDATED = "zone.updated";
export const ZONE_UPDATED_ERROR = "zone.updated.error";

export const SHIFT_CREATED = "shift.created";
export const SHIFT_CREATED_ERROR = "shift.created.error";
export const SHIFT_DELETED = "shift.deleted";
export const SHIFT_DELETED_ERROR = "shift.deleted.error";
export const SHIFT_ARCHIVED = "shift.archived";
export const SHIFT_ARCHIVED_ERROR = "shift.archived.error";
export const SHIFT_UPDATED = "shift.updated";
export const SHIFT_UPDATED_ERROR = "shift.updated.error";
