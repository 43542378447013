import { useNavigate, useParams } from "react-router-dom";
import { Page } from "../components/Page";
import { Box, Card, CardContent, Grid, Typography, CardActionArea, Skeleton } from "@mui/material";
import Add from "@mui/icons-material/Add";
import { QueryKeys } from "../data/QueryKeys";
import { useAPI } from "../hooks/useAPI";
import { useQuery } from "react-query";
import { useCurrentContext } from "../hooks/useCurrentContext";
import { BM } from "../api/BM";
import { EventsShimmer } from "../utils/Shimmer";
import { EventDialog } from "../dialogs/EventDialog";
import { useToggle } from "../hooks/useToggle";
import { AppRoutes } from "../constants/AppRoutes";
import { toHumanizedDateSpan } from "../utils/DateUtils";
import { useContext } from "react";
import { EditEventDrawer } from "./EditEventDrawer";

export const Events = (props: any) => 
{
    const { user, organization, isLoaded } = useCurrentContext();
    const { orgId } = useParams();
    const eventDialog = useToggle(false);
    const nav = useNavigate();
    const api = useAPI();
    const { data, isLoading } = useQuery(QueryKeys.Orgs.Events(organization?.id), () => BM.client.organizations.getById(organization?.id).events.get(), { enabled: isLoaded });

    return (
        <Page pageTitle={organization?.title || 'Loading...'} framed>

            <Box sx={{ margin: '40px 0 30px 0' }}>
                { isLoading && <Skeleton variant="text" /> }
                { !isLoading && <Typography variant="h4">{organization?.title}</Typography> }
            </Box>

            <Grid container spacing={2}>
                { 
                    !isLoading && 
                    <>
                        <Grid item xs={4} key={'new'}>
                            <Card>
                                <CardActionArea onClick={e => eventDialog.open()}>
                                    <CardContent sx={{ minHeight: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'  }}>
                                        <Add fontSize="large" />
                                        <div>New Event</div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        {
                            data?.items.map(event => 
                            {
                                return (
                                    <Grid item xs={4} key={event.id}>
                                        <Card>
                                            <CardActionArea onClick={e => { nav(`/${orgId}/events/${event.id}`) }}>
                                                <CardContent sx={{ minHeight: '220px' }}>
                                                    <Typography variant="h5" component="div">{event.title}</Typography>
                                                    <Typography sx={{ fontSize: '1.4rem' }}>{toHumanizedDateSpan(event.startTime, event.endTime)}</Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            {/* <CardActions>
                                                <Button size="small">View</Button>
                                            </CardActions> */}
                                        </Card>
                                    </Grid>
                                );
                            }) 
                        }
                    </>
                }
                { isLoading && <EventsShimmer /> }
            </Grid>

            {/* <EventDialog 
                isOpen={eventDialog.isOpen}
                onRequestClose={eventDialog.close}
                onSave={event => nav(AppRoutes.Event.Root(orgId, event.id))}
            /> */}

            <EditEventDrawer
                open={eventDialog.isOpen}
                onDismiss={eventDialog.close}
                onSave={event => nav(AppRoutes.Event.Root(orgId, event.id))}
                eventId={null}
            />

        </Page>
    );
}
