import { useContext, useState, useEffect } from "react";
import { Event } from "../../dto/Event";
import { Form, Formik } from "formik";
import { BMTextField } from "../../components/Fields/BMTextField";
import { BMDateTimeField } from "../../components/Fields/BMDateTimeField";
import { BMTextArea } from "../../components/Fields/BMTextArea";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMDropdownField } from "../../components/Fields/BMDropdownField";
import { getTimeZones } from "../../utils/DateUtils";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import * as Yup from "yup";

export interface GeneralSettingsPageProps
{

}

export const GeneralSettingsPage = (props: GeneralSettingsPageProps) =>
{
    const { event, isLoaded } = useCurrentContext();
    const api = useAPI();

    const updateEvent = useMutation(async (eventData: Partial<Event>) => {
        return await api.events.getById(event.id).update(eventData);
    });

    const schema = Yup.object({
        title: Yup.string().required('Please enter an event name'),
        description: Yup.string(),
        startTime: Yup.date().required('Please enter a start time'),
        endTime: Yup.date().required('Please enter an end time'),
        location: Yup.string(),
        timeZone: Yup.string().required('Please select a time zone'),
        displayTimeZone: Yup.string().required('Please select an option'),
        currency: Yup.string().required('Please select a currency')
    });

    const initialValues = {
        title: event.title,
        description: event.description,
        startTime: event.startTime || new Date().toUTCString(),
        endTime: event.endTime || new Date().toUTCString(),
        location: event.location,
        timeZone: event.timeZone,
        displayTimeZone: event.displayTimeZone,
        currency: event.currency
    };

    return (
        <div style={{ maxWidth: '640px', padding: '0 20px', margin: '40px auto' }}>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (values, formikHelpers) => {
                    console.log(values);
                    await updateEvent.mutateAsync(values);
                }}
            >
            { formProps => (
                <Form>
                    <h3>General</h3>
                    <hr />

                    <BMTextField name="title" label="Name" layout="vertical" required />
                    <BMTextArea name="description" label="Description" layout="vertical" />
                    <BMDateTimeField name="startTime" label="Start Time" layout="vertical" />
                    <BMDateTimeField name="endTime" label="End Time" layout="vertical" />

                    <h3>Region</h3>
                    <hr />
                    <BMTextField name="location" label="Location" layout="vertical" />
                    <BMDropdownField name="timeZone" label="Event Time Zone" layout="vertical" options={
                        getTimeZones().map(tz => ({ value: tz, label: tz }))
                    } />
                    <BMDropdownField name="displayTimeZone" label="Dashboard Time Zone" layout="vertical" options={[
                        { label: 'Show date and time in the event\'s time zone', value: 'local' },
                        { label: 'Show date and time in my computer\'s time zone', value: 'user' },
                    ]} 
                        description={
                            formProps.values.displayTimeZone === 'local' ? 'Dates and times will be displayed in the event\'s time zone while managing the event in the web dashboard. Attendees will always see dates and times in the event\'s time zone.' :
                            formProps.values.displayTimeZone === 'user' ? 'Dates and times will be displayed in your computer\'s time zone while managing the event in the web dashboard. Attendees will always see dates and times in the event\'s time zone.' :
                            ''
                        }
                    />

                    <h3>Currency</h3>
                    <hr />
                    <BMDropdownField name="currency" label="Currency" layout="vertical" options={[
                        { label: 'Canadian Dollar (CAD)', value: 'CAD' },
                        { label: 'US Dollar (USD)', value: 'USD' },
                        // { label: 'Euro (EUR)', value: 'EUR' },
                        // { label: 'British Pound (GBP)', value: 'GBP' }
                    ]} />

                    <hr />

                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                        <BMSubmitButton>Save</BMSubmitButton>
                        <button>Cancel</button>
                    </div>
                </Form>
            )}
            </Formik>
        </div>
    );
}