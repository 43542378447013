import { Query, QueryParams } from "./Query";

export default class BaseQuery<T>
{
    protected query: Query<T>;
    protected rootUrl: string;

    constructor(rootUrl?: string, deserializer?: (json: any) => T)
    {
        this.rootUrl = rootUrl ?? '';
        this.query = new Query<T>(this.rootUrl, deserializer);
    }

    public add(data: Partial<T>)
    {
        return this.query.add(data);
    }

    public get(params?: QueryParams)
    {
        return this.query.get(params);
    }

    public getById(id: string)
    {
        const retval = {
            update: (data: Partial<T>) => { return this.update(id, data) },
            get: () => { return this.getOne(id); },
            delete: () => { return this.delete(id); },
            select: (...fields: string[]) => {
                this.select(...fields);
                return retval;
            },
            expand: (...fields: string[]) => {
                this.expand(...fields);
                return retval;
            }
        };
        return retval;
    }

    public search(searchString: string)
    {
        return this.query.search(searchString);
    }

    public orderBy(field: string, direction: 'asc' | 'desc')
    {
        return this.query.orderBy(field, direction);
    }
    
    public select(...fields: string[])
    {
        return this.query.select(...fields);
    }

    public expand(...fields: string[])
    {
        return this.query.expand(...fields);
    }

    public skip(count: number)
    {
        return this.query.skip(count);
    }

    public top(maxItems: number)
    {
        return this.query.top(maxItems);
    }

    public param(name: string, value: string)
    {
        return this.query.param(name, value);
    }

    public includeCount(includeCount: boolean = true)
    {
        return this.query.includeCount(includeCount);
    }

    protected update(id: string, data: Partial<T>)
    {
        return this.query.update(id, data);
    }

    protected getOne(id: string)
    {
        return this.query.getById(id);
    }

    protected delete(id: string)
    {
        return this.query.delete(id);
    }
}