import { Component } from "react";
import { Event } from "../../dto/Event";
import Checkpoint from "../../dto/Checkpoint";
import { Card } from "../../components/Card";
import { SearchBox } from "../../components/SearchBox";
import { FormType } from "../../dialogs/ZoneModal";
import { TrackerAPI } from "../../api/TrackerAPI";
import OrganizationContext from "../../context/OrganizationContext";
import EventContext from "../../context/EventContext";
import { CheckpointDialog } from "../../dialogs/CheckpointDialog";

import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Header } from "../../components/Header";
import { Page } from "../../components/Page";
import {Organization} from "../../dto/Organization";

export interface CheckpointListProps
{
    organization: Organization;
    event: Event;
}

export interface CheckpointListState
{
    searchText: string;
    isOpen: boolean;
    isLoading: boolean;
    checkpoints: Checkpoint[];
}

export class CheckpointListInternal extends Component<CheckpointListProps, CheckpointListState>
{
    constructor(props: CheckpointListProps)
    {
        super(props);

        this.state = {
            searchText: '',
            isOpen: false,
            isLoading: false,
            checkpoints: []
        };
    }

    public componentDidMount()
    {
        if(this.props.event)
        {
            this.refreshList(this.props.event.id);
        }
    }

    public componentWillUnmount()
    {
    }

    public componentDidUpdate(prevProps: CheckpointListProps, prevState: CheckpointListState)
    {
        if(this.props.event && !prevProps.event)
        {
            this.refreshList(this.props.event.id);
        }
    }

    private refreshList(eventId: string)
    {
        this.setState({ isLoading: true });

        TrackerAPI.GetCurrent().GetCheckpoints(eventId).then((checkpoints) => 
        {
            this.setState({ 
                checkpoints: checkpoints,
                isLoading: false
            });
        });
    }

    public render()
    {
        let zoneList: React.ReactNode[] = [];
        // let clearSearchClasses = "clear-button";
        let countString = 'No checkpoints found';

        // let pageTitle = "Checkpoints | Badgemeister";
        // if(this.props.event)
        //     pageTitle = `Checkpoints - ${this.props.event.title} | Badgemeister`;

        let checkpoints = this.state.checkpoints.slice(0);
        checkpoints.sort((a, b) => {
            if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
            else if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
            else { return 0; } 
        });

        checkpoints.forEach((c) => 
        {
            if(this.containsString(c, this.state.searchText))
            {
                let subtitle = null;
                let title = (<div><span>{c.title}</span>{subtitle}</div>);

                zoneList.push(
                    // <ListItem
                    //     key={c.id} 
                    //     title={title}
                    //     //path={`${window.location.pathname}/${c.getSlug()}`}
                    //     path={'#'}
                    // />
                    <ListItem divider key={c.id}>
                        <ListItemText primary={title} />
                        <ListItemSecondaryAction>
                            {/* <Button variant="outlined">Edit</Button>
                            <Button variant="outlined">Delete</Button> */}
                            <IconButton><EditIcon /></IconButton>
                            <IconButton><DeleteIcon /></IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>

                );
            }
        });

        if(zoneList.length > 1)
            countString = `${zoneList.length} checkpoints`;
        else if(zoneList.length === 1)
            countString = `1 checkpoint`;

        return (
            <Page pageTitle={'Checkpoint List | Badgemeister'} className="page-checkpoint-list">
                <Header>
                    <h1>Checkpoints</h1>
                </Header>

                <Card padded={false} className="page-zones">
                    <Toolbar>

                            <SearchBox
                                onChange={this.onSearch.bind(this)}
                                placeholder="Search by name..." 
                                style={{ marginRight: '20px', flex: 1 }} />

                            {/* <jButton buttonStyle={ButtonStyle.Primary} onClick={() => this.setState({ isOpen: true })}>
                                <i className="fa fa-plus fa-left" />New Checkpoint
                            </jButton> */}
                            <Button variant="contained" color="primary" onClick={() => this.setState({ isOpen: true })}>
                                New Checkpoint
                            </Button>

                    </Toolbar>

                    {/* <List loading={this.state.isLoading}>
                        {zoneList}
                    </List> */}

                    <List>
                        {zoneList}
                    </List>

                    <div className="row count">
                        <p className="subtle">{countString}</p>
                    </div>
                    
                    <CheckpointDialog 
                        type={FormType.New}
                        isOpen={this.state.isOpen}
                        onRequestClose={() => { this.setState({ isOpen: false }); }}
                        event={this.props.event}
                        onItemCreated={this.onCheckpointCreated.bind(this)}
                    />

                </Card>

            </Page>
        );
    }

    private onCheckpointCreated(c: Checkpoint)
    {
        this.refreshList(this.props.event.id);
    }

    private onSearch(e: React.ChangeEvent<HTMLInputElement>)
    {
        this.setState({ searchText: e.target.value });
        if (sessionStorage)
            sessionStorage.setItem('checkpoints-searchtext', e.target.value);
    }

    private containsString(c: Checkpoint, value: string)
    {
        let string: string = c.title;
        return string.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    }
}

export function CheckpointList(props)
{
    return (
        <OrganizationContext.Consumer>
            { orgCtx => 
                <EventContext.Consumer>
                    { ctx => <CheckpointListInternal organization={orgCtx.organization} event={ctx.event} {...props} /> }
                </EventContext.Consumer>
            }
        </OrganizationContext.Consumer>
    );
}
