import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "../../constants/AppRoutes";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useAuth, useClerk } from "@clerk/clerk-react";

export const Logout = () => 
{
    //const { logout, isAuthenticated, isLoading } = useAuth0();
    const { isLoaded, isSignedIn } = useAuth();
    const clerk = useClerk();
    const nav = useNavigate();

    useEffect(() => 
    {
        if(isLoaded)
        {
            if(isSignedIn)
                clerk.signOut({ redirectUrl: 'https://badgemeister.com' });
                //logout({ returnTo: 'https://badgemeister.com' });
            else
                nav(AppRoutes.Root());
        }
    }, [isLoaded, isSignedIn, clerk, nav]);

    return (
        <div className="auth-page">
            <div className="auth-card">
                <div className="auth-hole"></div>
                <div className="card-title">
                    <img className="logo" src="/images/logo.svg" alt="Badgemeister Logo" />
                </div>
                <div className="card-content">
                        <CircularProgress />
                        <div>Signing out...</div>
                </div>        
            </div>
        </div>
    );
};

