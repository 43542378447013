
import { useNavigate } from "react-router-dom";
import "./PageTabs.scss";

export interface PageTabsProps
{
    tabs: Array<{ text: string, href?: string, selected?: boolean, onClick?: () => void }>;
}

export const PageTabs = (props: PageTabsProps) =>
{
    const { tabs } = props;
    const nav = useNavigate();

    const isSelected = (href: string) => {
        return window.location.pathname.startsWith(href);
    }

    return (
        <nav className="page-tabs">
            {
                tabs?.map(t => (
                    <a 
                        key={t.text} 
                        className={['tab', (t.selected ?? isSelected(t.href) ? 'selected' : undefined)].join(' ')} 
                        href={t.href || '#'} 
                        onClick={(e) => {
                            e.preventDefault();
                            t.onClick ? t.onClick() : nav(t.href);
                        }}>
                        {t.text}
                    </a>
                )) 
            }
        </nav>
    );
};