import { useCallback, useContext, useState } from "react";
import { Drawer, DrawerProps } from "../../components/Drawer";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { BMTextField } from "../../components/Fields/BMTextField";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMButton } from "../../components/Fields/BMButton";
import * as Yup from "yup";

import RegItem from "../../dto/RegItem";
import { BMTextArea } from "../../components/Fields/BMTextArea";
import { BMCurrencyField } from "../../components/Fields/BMCurrencyField";

import "./EditAddOnDrawer.scss";
import { BMDropdownField } from "../../components/Fields/BMDropdownField";
import { DialogContext, DialogOption } from "../../context/DialogContext";

export interface EditAddOnDrawerProps extends DrawerProps
{
    regItemId: string;
    onSave?: (regItem: RegItem) => void;
}

export const EditAddOnDrawer = (props: EditAddOnDrawerProps) => 
{
    const { onSave, onDismiss, regItemId } = props;
    const { event, isLoaded } = useCurrentContext();
    const dialog = useContext(DialogContext);
    const queryClient = useQueryClient();
    const api = useAPI();
    const [error, setError] = useState<string>('');

    const regItemQuery = useQuery(QueryKeys.RegItems.GetById(event.id, regItemId), () => api.events.getById(event.id).regItems.getById(regItemId).get(), { enabled: isLoaded && !!regItemId, initialData: null });
    const updateRegTier = useMutation(async (regTierData: Partial<RegItem>) => {
        const retval = await api.events.getById(event.id).regItems.getById(regItemId).update(regTierData);
        queryClient.invalidateQueries(QueryKeys.RegItems.GetById(event.id, regItemId));
        return retval;
    });
    const createRegTier = useMutation(async (regTierData: Partial<RegItem>) => {
        const retval = await api.events.getById(event.id).regItems.add(regTierData);
        queryClient.invalidateQueries(QueryKeys.RegItems.GetById(event.id, retval.id));
        return retval;
    });

    const onSubmit = useCallback(async (values: FormikValues, formikHelpers: FormikHelpers<any>) =>
    {
        try
        {
            setError('');

            const updateData = {
                title: values.title,
                type: 'addon' as 'addon',
                description: values.description,
                group: values.group,
                price: values.price,
                active: values.status === 'active',
                public: values.visibility === 'public'
            };

            let retval: RegItem;
            if(regItemId)
                retval = await updateRegTier.mutateAsync(updateData);
            else
                retval = await createRegTier.mutateAsync(updateData);

            onSave(retval);
        }
        catch(e: any)
        {
            setError(e.message);
        }
    }, [regItemId, updateRegTier, createRegTier, onSave]);

    const onDelete = useCallback(async (option: DialogOption) => {
        if(option.text === 'Delete')
        {
            await api.events.getById(event.id).regItems.getById(regItemId).delete();
            queryClient.invalidateQueries(QueryKeys.RegItems.All(event.id));
            onDismiss();
        }
    }, [api, event, regItemId, onDismiss, dialog, queryClient]);

    const schema = Yup.object({
        title: Yup.string().required('Name is required').trim(),
        type: Yup.string().required('Type is required').oneOf(['addon']),
        description: Yup.string().trim(),
        group: Yup.string().trim(),
        price: Yup.number().required('Price is required').min(0),
        status: Yup.string().required('Status is required').oneOf(['active', 'inactive']),
        visibility: Yup.string().required('Visibility is required').oneOf(['public', 'private'])
    });

    let initialData: Partial<RegItem> | { status: string, visibility: string } = {
        title: '',
        type: 'addon',
        description: '',
        group: '',
        price: 0,
        status: 'inactive',
        visibility: 'public'
    };

    if(regItemId && regItemQuery.data)
    {
        initialData = {
            title: regItemQuery.data.title,
            type: regItemQuery.data.type,
            description: regItemQuery.data.description,
            group: regItemQuery.data.group,
            price: regItemQuery.data.price,
            status: regItemQuery.data.active ? 'active' : 'inactive',
            visibility: regItemQuery.data.public ? 'public' : 'private'
        };
    }

    return (
        <Drawer title={regItemId ? 'Edit Add-on' : 'New Add-on'} loading={(regItemQuery.isFetching && !!regItemId)} {...props}>
            <Formik 
                initialValues={initialData} 
                onSubmit={onSubmit}
                validationSchema={schema}
                enableReinitialize>
                { formProps => (
                    <Form className="reg-addon-drawer">
                        <BMTextField name="title" label="Name" required autoFocus autoComplete="off" />
                        <BMTextArea name="description" label="Description" />
                        <BMTextField name="group" label="Group" />
                        <BMCurrencyField name="price" label="Price" required />

                        <BMDropdownField name="status" label="Status" options={[
                            { value: 'active', label: 'Available' },
                            { value: 'inactive', label: 'Disabled' },
                        ]} 
                            required 
                        />
                        <BMDropdownField name="visibility" label="Visibility" options={[
                            { value: 'public', label: 'Public' },
                            { value: 'private', label: 'Private' },
                        ]} 
                            required
                            description="Public add-ons are visible to the public. Private add-ons are only available via registration token."
                        />

                        <hr />
                        { error && <div className="error">{error}</div> }
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <BMSubmitButton>{regItemId ? 'Save' : 'Create'}</BMSubmitButton>
                            <BMButton onClick={onDismiss}>Cancel</BMButton>

                            {
                                regItemId && 
                                <BMButton className="danger" style={{ marginLeft: 'auto' }}
                                onClick={() => {
                                    dialog.show({
                                        title: 'Delete Registration Tier',
                                        content: 'Are you sure you want to delete this add-on? This action cannot be undone.',
                                        options: [
                                            { text: 'Delete', type: 'danger' },
                                            { text: 'Cancel' }
                                        ],
                                        onSelect: onDelete
                                    });
                                }}>
                                    Delete
                                </BMButton>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
}
