import { Navigate, Route, Routes } from 'react-router-dom';
import { Page, PageHeader } from "../../components/Page";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { ActivityList } from "./ActivityList";
import { PageTabs } from "../../components/PageTabs";
import { AppRoutes } from "../../constants/AppRoutes";

export const ActivitiesRoot = () => 
{
    const { organization, event } = useCurrentContext();

    const tabs = [
        { text: 'Session List', href: AppRoutes.Event.Activities.List(organization.id, event.id) },
        // { text: 'Calendar', href: AppRoutes.Event.Activities.Calendar(organization.slug, event.id) },
        // { text: 'Automation', href: AppRoutes.Event.Activities.Calendar(organization.slug, event.id) }
    ]

    return (
        <Page>
            <PageHeader>
                <h2>Sessions</h2>
                <PageTabs tabs={tabs} />
            </PageHeader>
            <Routes>
                <Route path="list" element={<ActivityList />} />
                {/* <Route path="calendar" element={<div></div>} /> */}
                {/* <Route path=":activityId" element={<ActivityDetails />} /> */}
                <Route path="" element={<Navigate to="list" />} />
            </Routes>
        </Page>
    );
}
