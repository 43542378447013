import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

export interface BMTextFieldProps extends React.InputHTMLAttributes<HTMLButtonElement>
{
    
}

export const BMSubmitButton = (props: BMTextFieldProps) => 
{
    const { children = 'Submit', disabled } = props;
    const context = useFormikContext();

    return (
        <button type="submit" disabled={disabled || context.isSubmitting}>{children}</button>
    );
};
