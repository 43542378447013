import { useState, useEffect, useContext, useCallback } from "react";
import { formatInTimeZone } from "date-fns-tz";
import { currentTimeZone } from "../../utils/DateUtils";
import { format } from "date-fns";
import { useNavigate } from 'react-router-dom';
import Attendee from "../../dto/Attendee";

import { Utils, setValue } from "../../utils/Utils";
import { useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useToggle } from "../../hooks/useToggle";
import { useAPI } from "../../hooks/useAPI";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { GridColumn, GridConfig } from "../../components/Grid/Grid";
import { PageToolbar, ToolbarButton, ToolbarIconButton, ToolbarSearchBox } from "../../components/PageToolbar";

import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { AttendeeGrid } from "../../grids/AttendeeGrid";
import { EditAttendeeDrawer } from "./EditAttendeeDrawer";
import { ViewAttendeeDrawer } from "./ViewAttendeeDrawer";
import { ToastContext } from "../../context/ToastContext";
import { DialogContext } from "../../context/DialogContext";
import { AOExportToCSV } from "../../utils/Export";

export const AttendeesList = (props) => 
{
    const nav = useNavigate();
    const { event, isLoaded } = useCurrentContext();
    const toast = useContext(ToastContext);
    const dialog = useContext(DialogContext);
    const queryClient = useQueryClient();
    const editDrawer = useToggle(false);
    const viewDrawer = useToggle(false);

    const [editMode, setEditMode] = useState(false);
    const [selectedAttendeeId, setSelectedAttendeeId] = useState<string>(null);
    const [selectedView, setSelectedView] = useState(0);
    const [searchText, setSearchText] = useState<string>('');
    const api = useAPI();

    const viewQuery = useQuery(QueryKeys.Events.Views(event.id), () => api.events.getById(event.id).views.get(), { enabled: isLoaded, initialData: { items: [] } });

    useEffect(() => {
        setSelectedView(0);
    }, [viewQuery.data]);

    useEffect(() => {
        setGridConfig({ ...gridConfig, columns: viewQuery.data.items[selectedView]?.columns || [] });
    }, [selectedView, viewQuery.data]);

    // const [gridConfig, setGridConfig] = useState<GridConfig>({
    //     columns: [
    //         { key: 'attendeeNumber', name: 'Attendee #', type: 'text', width: 160, pinned: true, readOnly: true, showMenu: true },
    //         { key: 'firstName', name: 'First Name', type: 'text', width: 200 },
    //         { key: 'lastName', name: 'Last Name', type: 'text', width: 200 },
    //         { key: 'email', name: 'Email', type: 'text', width: 400 },
    //         { key: 'createdAt', name: 'Date Added', type: 'datetime', width: 200, readOnly: true },
    //         { key: 'fields.Test Number', name: 'Test Number', type: 'text', width: 200 },
    //         { key: 'fields.New Field', name: 'New Field', type: 'text', width: 200 },
    //     ],
    //     pageIndex: 0,
    //     pageSize: 10
    // });

    const [gridConfig, setGridConfig] = useState<GridConfig>({
        columns: [],
        pageIndex: 0,
        pageSize: 50,
    });

    const attendeeQuery = useQuery(QueryKeys.Attendees.All(event.id, `${searchText}|${JSON.stringify(gridConfig)}`), () => 
    {
        const q = api.events.getById(event.id).attendees
            //.select('attendeeNumber', 'firstName', 'lastName', 'email', 'birthdate', 'registration', 'customFields', 'createdAt')
            //.select()
            .expand('registrationItems')
            .skip(gridConfig.pageIndex * gridConfig.pageSize)
            .top(gridConfig.pageSize)
            .includeCount();

        if(gridConfig.sorts && gridConfig.sorts.length > 0)
        {
            const sort = gridConfig.sorts[0];
            q.orderBy(sort.key, sort.dir);
        }

        //if(sortModel && sortModel.length > 0)
        //    q.orderBy(sortModel[0].field, sortModel[0].sort);
        // if(filterModel && filterModel.items > 0)
        //     q.filter(filterModel)

        if(searchText)
            q.search(searchText);

        Utils.setPageConfig(`${event.id}-attendees-config`, {
            //sort: sortModel,
            //filter: filterModel,
            search: searchText,
            //page: page,
            //pageSize: pageSize
        });

        return q.get();
    }, { enabled: isLoaded, initialData: { items: [] } });

    const viewItem = useCallback((attendee: Attendee) => {
        editDrawer.close();
        setSelectedAttendeeId(attendee.id);
        viewDrawer.open();
    }, [editDrawer, viewDrawer]);

    const editItem = useCallback(async (attendee: Attendee) => {
        viewDrawer.close();
        setSelectedAttendeeId(attendee?.id);
        editDrawer.open();
    }, [editDrawer, viewDrawer]);

    const archiveItem = async (attendee: Attendee) =>
    {
        dialog.show({
            title: 'Archive attendee',
            content: 'Are you sure you want to archive this attendee?',
            options: [
                { text: 'Archive', type: 'danger' },
                { text: 'Cancel' }
            ],
            onSelect: async (option) => {
                if(option.text === 'Archive')
                {
                    await api.events.getById(event.id).attendees.getById(attendee.id).archive();
                    queryClient.invalidateQueries(QueryKeys.RegItems.All(event.id));
                    attendeeQuery.refetch();
                }
            }
        });
    };

    const printItem = async (attendee: Attendee) => 
    {
        await api.events.getById(event.id).attendees.getById(attendee.id).print();

        toast.showToast({
            message: `Printing new badge for ${attendee?.getName()}`,
            durationInMs: 6000,
            type: 'success'
        });
    }

    const exportAttendees = async () =>
    {
        try
        {
            const response = await api.events.getById(event.id).attendees
                .select('attendeeNumber', 'firstName', 'lastName', 'name', 'customFields', 'email', 'birthdate', 'registration_items', 'createdAt', 'fields')
                .orderBy('createdAt', 'asc')
                .expand('registrationItems')
                .get();

            let content: string;
            content = AOExportToCSV(response.items);
            // if(eventContext.event?.title.indexOf('AnthrOhio') > -1)
            // {
            //     content = AOExportToCSV(response.value);
            // }
            // else
            // {
            //     content = CourtsExportToCSV(response.value);
            // }

            const today = format(new Date(), 'M-d-y');
            Utils.downloadFile(`${event.title}-Attendees-${today}.csv`, content);
        }
        catch(ex)
        {
            console.error(ex);
        }
    };

    const sortByField = useCallback(async (field: string, dir: 'asc' | 'desc' = 'asc') => {
        // const sortModel = [{ field, sort: 'asc' }];
        // setSortModel(sortModel);
        // await attendeeQuery.refetch();
        setGridConfig({ ...gridConfig, sorts: [{ key: field, dir }] });
    }, [attendeeQuery]);


    return (
        <>
            <PageToolbar 
                startItems={
                    <>
                        <ToolbarButton icon={<AddIcon />} onClick={() => editItem(null)}>New attendee</ToolbarButton>
                        <ToolbarButton icon={<EditIcon />} onClick={() => setEditMode(!editMode)}>{editMode ? 'Exit grid view' : 'Edit in grid view'}</ToolbarButton>
                        <ToolbarSearchBox defaultValue={searchText} onChange={value => { setSearchText(value) }} />
                    </>
                }
                endItems={
                    <>
                        {/* <ToolbarButton variant="text" endIcon={<ExpandMoreIcon />}>All attendees</ToolbarButton> */}
                        <ToolbarIconButton tooltip="Refresh" onClick={() => { attendeeQuery.refetch(); }}><RefreshIcon /></ToolbarIconButton>
                        <ToolbarIconButton tooltip="Export" onClick={() => { exportAttendees(); }}><FileDownloadIcon /></ToolbarIconButton>
                        {/* <ToolbarIconButton><MoreVertIcon /></ToolbarIconButton> */}
                    </>
            } />

            <AttendeeGrid
                editable={editMode}
                items={attendeeQuery.data.items}
                totalItems={attendeeQuery.data.count}
                config={gridConfig}
                loading={(attendeeQuery.isFetching)}
                onSelectItem={viewItem}
                onConfigChanged={async config => { 
                    setGridConfig(config);
                    attendeeQuery.refetch();
                }}
                onSaveItem={async (item: Attendee, property: string, value: any) => {
                    console.log(item.id, property, value);
                    const updateObj = setValue({}, property, value);
                    await api.events.getById(event.id).attendees.getById(item.id).update(updateObj);
                }}
                onColumnContextMenu={(e, column) => {
                    return [
                        // { label: 'Edit Field', onClick: () => console.log('edit field'), icon: <EditIcon />},
                        { label: 'Sort ascending', onClick: () => sortByField(column.key, 'asc'), icon: <ArrowDownwardIcon /> },
                        { label: 'Sort descending', onClick: () => sortByField(column.key, 'desc'), icon: <ArrowUpwardIcon />},
                        // { label: 'Pin Column', onClick: () => console.log('pin column'), icon: <MoreVertIcon />},
                        // { type: 'divider' },
                        // { label: 'Delete', onClick: () => console.log('delete'), icon: <DeleteIcon />}
                        //{ label: 'Delete', onClick: () => setGridConfig({ ...gridConfig, columns: gridConfig.columns.filter(c => c.key !== column.key) }) }
                    ];
                }}
                onItemContextMenu={(e, item) => {
                    return [
                        { label: 'View', onClick: viewItem, icon: <VisibilityIcon />},
                        { label: 'Edit Fields', onClick: editItem, icon: <EditIcon />},
                        { type: 'divider'},
                        { label: 'Print Badge', onClick: printItem, icon: <PrintIcon />},
                        { type: 'divider' },
                        { label: 'Archive', onClick: archiveItem, icon: <DeleteIcon />},
                    ];
                }}
                onNewField={async (name, type) => {
                    await api.events.getById(event.id).fields.add({ name, type: type as any });
                    setGridConfig({ 
                        ...gridConfig, 
                        columns: [...gridConfig.columns as any[], { key: `fields.${name}`, name, type: type as any, width: 160 }] 
                    });
                }}
                onDeleteField={async (column) => {
                    // await api.events.getById(event.id).fields.getById(column.key).delete();
                    // setGridConfig({ 
                    //     ...gridConfig, 
                    //     columns: gridConfig.columns.filter(c => c.key !== id) 
                    // });
                }}
            />

            <EditAttendeeDrawer
                open={editDrawer.isOpen}
                onDismiss={editDrawer.close} 
                attendeeId={selectedAttendeeId}
                onSave={() => attendeeQuery.refetch()}
            />

            <ViewAttendeeDrawer
                open={viewDrawer.isOpen}
                onDismiss={viewDrawer.close} 
                attendeeId={selectedAttendeeId}
            />
        </>
    );
}
