import "whatwg-fetch";
import { Event, fromJson } from "../dto/Event";
import Session from "../dto/Session";
import { Activity, fromJson as activityFromJson } from "../dto/Activity";
import CheckIn from "../dto/CheckIn";
import User from "../dto/User";
import Attendee from "../dto/Attendee";
import Zone from "../dto/Zone";
import Supervisor from "../dto/Supervisor";
import {Organization} from "../dto/Organization"; 
import Shift from "../dto/Shift";
import Checkpoint from "../dto/Checkpoint";
import Registration from "../dto/Registration";
import ListResponse from "./ListResponse";
import { Query } from "./Query";

export class TrackerAPI
{
    private apiRoot: string;

    public static _curr: TrackerAPI = null;
    public static GetCurrent()
    {
        if (!this._curr)
        {
            this._curr = new TrackerAPI(null, process.env.REACT_APP_API_URL);
        }

        return this._curr;
    }

    constructor(accessToken: string, apiRoot: string)
    {
        this.apiRoot = apiRoot;
    }

    // --------------------------------------------------
    // ACCOUNT FUNCTIONS
    // --------------------------------------------------

    public GetAccount(): Promise<User>
    {
        return this.getData('/users/me', d => { return User.fromJson(d)});
    }

    public getAccessToken(idToken: string)
    {
        return this.postData<any>('/auth/token', { token: idToken }, (response) => 
        {
            return response;
        });
    }

    public signOut()
    {
        return this.postData<any>('/auth/logout', {}, (response) => 
        {
            return response;
        });
    }
    
    // --------------------------------------------------
    // ORGANIZATION FUNCTIONS
    // --------------------------------------------------

    public GetOrganizations()
    {
        return this.getData('/organizations', (response: ListResponse<Organization>) =>
        {
            return response.items.map((orgJson) => { return orgJson; });
        });
    }

    public GetOrganiaztion(orgId: string)
    {
        return this.getData('/organizations/' + orgId, (org) => { return org });
    }

    // --------------------------------------------------
    // EVENT FUNCTIONS
    // --------------------------------------------------

    public GetEvents(orgId: string)
    {
        return this.getData('/organizations/' + orgId + '/events', (events: any) => 
        {
            return events.items.map((eventJson) => { return fromJson(eventJson); });
        });
    }
    
    public CreateEvent(event: Event): Promise<Event>
    {
        return this.postData('/events', event, (e) => { return fromJson(e) });
    }

    public GetEvent(eventId: string): Promise<Event>
    {
        return this.getData('/events/' + eventId, (eventJson) => { return fromJson(eventJson); });
    }

    public UpdateEvent(event: Event): Promise<Event>
    {
        return this.patchData('/events/' + event.id, event, (eventJson) => { return fromJson(eventJson); });
    }

    public DeleteEvent(eventId: string): Promise<string>
    {
        return this.deleteData('/events/' + eventId, (eventId) => { return eventId; });
    }

    // --------------------------------------------------
    // SESSION FUNCTIONS
    // --------------------------------------------------

    public GetSessionData(session: Session): Promise<any>
    {
        let url = '/events/' + session.eventId + '/sessions/' + session.id + '/data';
        url += '?type=checkins&interval=5&starttime=[now]&endtime=[now]';

        return this.getData(url, (data) => 
        {
            return data;
        });
    }

    // --------------------------------------------------
    // ACTIVITY FUNCTIONS
    // --------------------------------------------------

    public GetActivities(eventId: string, skip?: number, limit?: number, inlineCount?: boolean) : Promise<ListResponse<Activity>>
    {
        let url = `/events/${eventId}/activities`;

        if(isFinite(limit))
            url += `?$top=${limit}`;
        if(isFinite(skip))
            url += `&$skip=${skip}`;
        if(inlineCount)
            url += `&$inlineCount=all`

        return this.getData(url, (response: any) =>
        {
            let retval: ListResponse<Activity> = {
                items: response.items.map((a) => activityFromJson(a)),
                count: response['@odata.count']
            };

            //response.results = response.items.map((a) => { return Activity.FromJson(a); });
            return retval;
        });
    }

    public CreateActivity(activity: Activity): Promise<Activity>
    {
        return this.postData(`/events/${activity.event}/activities`, activity, (json) => { return activityFromJson(json); });
    }

    public UpdateActivity(activity: Activity): Promise<Activity>
    {
        return this.patchData(`/activities/${activity.id}`, activity, (json) => { return activityFromJson(json) });
    }

    public DeleteActivity(eventId: string, activityId: string): Promise<string>
    {
        return this.deleteData(`/activities/${activityId}`, (activityId) => { return activityId; });
    }

    public SearchActivities(eventId: string, searchText: string)
    {
        return this.getData(`/events/${eventId}/activities/actions/search?q=${searchText}`, (activities) => 
        { 
            return activities.map((a) => { return activityFromJson(a); });
        });
    }

    // --------------------------------------------------
    // ATTENDEE FUNCTIONS
    // --------------------------------------------------

    public GetAttendees(eventId: string, skip?: number, limit?: number, inlineCount?: boolean): Promise<ListResponse<Attendee>>
    {
        let url = `/events/${eventId}/attendees`;

        if(isFinite(limit))
            url += `?$top=${limit}`
        if(isFinite(skip))
            url += `&$skip=${skip}`
        if(inlineCount)
            url += `&$inlineCount=all`

        return this.getData(url, (response: any) => 
        {
            let retval = {
                value: response.items.map((a) => Attendee.fromJson(a)),
                count: response['@odata.count']
            };

            //response.results = response.items.map((a) => { return Attendee.FromJson(a); });
            return retval;
        });
    }

    public GetAttendee(attendeeId: string, includeRegistration: boolean = false)
    {
        let url = `/attendees/${attendeeId}`;

        if(includeRegistration)
        {
            url += '?$expand=registration';
        }

        return this.getData(url, (attendeeJson) => 
        {
            return Attendee.fromJson(attendeeJson);
        });
    }

    public CreateAttendee(attendee: Attendee)
    {
        return this.postData('/attendees/', attendee, (attendeeJson) => { return Attendee.fromJson(attendeeJson); });
    }

    public UpdateAttendee(attendee: Attendee)
    {
        return this.patchData('/attendees/' + attendee.id, attendee, (attendeeJson) => { return Attendee.fromJson(attendeeJson); });
    }

    public DeleteAttendee(attendee: Attendee)
    {
        return this.deleteData('/attendees/' + attendee.id, (attendeeId) => { return attendeeId });
    }

    public SearchAttendees(eventId: string, searchText: string)
    {
        return this.getData(`/events/${eventId}/attendees/actions/search?q=${searchText}`, (attendees) => 
        { 
            return attendees.map((a) => { return Attendee.fromJson(a); });
        });
    }

    // --------------------------------------------------
    // ZONE FUNCTIONS
    // --------------------------------------------------

    public GetZones(eventId: string): Promise<Zone[]>
    {
        return this.getData(`/events/${eventId}/volunteering/zones`, (json: any[]) => 
        { 
            return json.map((zone) => { return Zone.FromJson(zone); });
        });
    }

    public GetZone(eventId: string, zoneId: string, includeShifts: boolean): Promise<Zone>
    {
        return this.getData(`/events/${eventId}/volunteering/zones/${zoneId}?$expand=shifts`, (json) => 
        {
            return Zone.FromJson(json);
        });
    }

    public CreateZone(eventId: string, zone: Zone)
    {
        return this.postData(`/events/${eventId}/volunteering/zones/`, zone, (json) => { return Zone.FromJson(json); });
    }

    public UpdateZone(eventId: string, zone: Zone)
    {
        return this.patchData(`/events/${eventId}/volunteering/zones/${zone.id}`, zone, (json) => { return Zone.FromJson(json); });
    }

    public DeleteZone(eventId: string, zoneId: string)
    {
        return this.deleteData(`/events/${eventId}/volunteering/zones/${zoneId}`, (zoneId) => { return zoneId; });
    }

    // --------------------------------------------------
    // SUPERVISOR FUNCTIONS
    // --------------------------------------------------

    public GetSupervisors(eventId: string)
    {
        return this.getData(`/events/${eventId}/volunteering/supervisors`, (response) => 
        {
            return response.map((supervisor) => { return Supervisor.FromJson(supervisor); });
        });
    }

    public VerifySupervisor(eventId: string, badgeId: string)
    {
        return this.getData(`/events/${eventId}/volunteering/supervisors/actions/verify?badgeId=${badgeId}`, (response) => { return response });
    }

    // --------------------------------------------------
    // SHIFT FUNCTIONS
    // --------------------------------------------------

    public GetShifts(eventId: string, zoneId: string, expandAttendee: boolean = false): Promise<Shift[]>
    {
        let url = `/events/${eventId}/volunteering/zones/${zoneId}/shifts`;
        if(expandAttendee)
            url += '?$expand=attendee';

        return this.getData(url, (json) => { 
            return json.map((shift) => { return Shift.FromJson(shift); });
        });
    }

    public GetActiveShifts(eventId: string, zoneId: string)
    {
        return this.getData(`/events/${eventId}/volunteering/zones/${zoneId}/shifts/active`, (json) => { 
            return json.map((shift) => { return Shift.FromJson(shift); });
        });
    }

    public CreateShift(eventId: string, zoneId: string, shift: Shift)
    {
        return this.postData(`/events/${eventId}/volunteering/zones/${zoneId}/shifts`, shift, (response) => { return Shift.FromJson(response); });
    }

    public UpdateShift(eventId: string, zoneId: string, shiftId: string, shift: Shift)
    {
        return this.patchData(`/events/${eventId}/volunteering/zones/${zoneId}/shifts/${shiftId}`, shift, (json) => { return Shift.FromJson(json); });
    }

    public DeleteShift(eventId: string, zoneId: string, shiftId: string)
    {
        return this.deleteData(`/events/${eventId}/volunteering/zones/${zoneId}/shifts/${shiftId}`, (json) => { return Shift.FromJson(json); });
    }

    public CheckInVolunteer(eventId: string, zoneId: string, attendeeId: string): Promise<any>
    {
        let postData = { 'attendeeId': attendeeId };
        return this.postData(`/events/${eventId}/volunteering/zones/${zoneId}/actions/checkin`, postData, (response) => 
        {
            return response;
        });
    }

    public CheckOutVolunteer(eventId: string, zoneId: string, attendeeId: string): Promise<any>
    {
        let postData = { 'attendeeId': attendeeId };
        return this.postData(`/events/${eventId}/volunteering/zones/${zoneId}/actions/checkout`, postData, (response) => 
        {
            return response;
        });
    }

    public CheckInVolunteerBadge(eventId: string, zoneId: string, cardId: string): Promise<any>
    {
        let postData = { 'cardId': cardId };
        return this.postData(`/events/${eventId}/volunteering/zones/${zoneId}/actions/checkin`, postData, (response) => 
        {
            return response;
        });
    }

    public CheckOutVolunteerBadge(eventId: string, zoneId: string, cardId: string): Promise<any>
    {
        let postData = { 'cardId': cardId };
        return this.postData(`/events/${eventId}/volunteering/zones/${zoneId}/actions/checkout`, postData, (response) => 
        {
            return response;
        });
    }

    // --------------------------------------------------
    // CHECKIN FUNCTIONS
    // --------------------------------------------------

    public GetCheckIns(eventId: string, activityId: string): Promise<CheckIn[]>
    {
        //return this.getData('/events/' + eventId + '/sessions/' + sessionId + '/checkins?$expand=person', (checkins: any[]) => 
        return this.getData(`/events/${eventId}/activities/${activityId}/checkins?$expand=attendee`, (checkins: any[]) =>
        {
            return checkins.map((checkIn) => { return CheckIn.fromJson(checkIn); });
        });
    }
    
    // --------------------------------------------------
    // CHECKPOINT FUNCTIONS
    // --------------------------------------------------

    public GetCheckpoints(eventId: string): Promise<Checkpoint[]>
    {
        return this.getData(`/events/${eventId}/checkpoints`, (json: any[]) => 
        { 
            return json.map((c) => { return Checkpoint.FromJson(c); });
        });
    }

    public GetCheckpoint(eventId: string, checkpointId: string): Promise<Checkpoint>
    {
        return this.getData(`/events/${eventId}/checkpoints/${checkpointId}`, (json) => 
        {
            return Checkpoint.FromJson(json);
        });
    }

    public CreateCheckpoint(eventId: string, checkpoint: Checkpoint)
    {
        return this.postData(`/events/${eventId}/checkpoints/`, checkpoint, (json) => { return Checkpoint.FromJson(json); });
    }

    public UpdateCheckpoint(eventId: string, checkpoint: Checkpoint)
    {
        return this.patchData(`/events/${eventId}/checkpoints/${checkpoint.id}`, checkpoint, (json) => { return Checkpoint.FromJson(json); });
    }

    public DeleteCheckpoint(eventId: string, checkpointId: string)
    {
        return this.deleteData(`/events/${eventId}/checkpoints/${checkpointId}`, (checkpointId) => { return checkpointId; });
    }

    public CheckpointCheckIn(eventId: string, checkpointId: string, cardId: string)
    {
        let data = { 'card': cardId };
        return this.postData(`/events/${eventId}/checkpoints/${checkpointId}/actions/checkin`, data, (response) => 
        {
            return response;
        });
    }

    public CheckpointCheckOut(eventId: string, attendeeId: string)
    {
        let data = { 'attendee' : attendeeId }
        return this.postData(`/events/${eventId}/checkpoints/actions/checkout`, data, (response) =>
        {
            return response;
        });
    }

    public CheckpointCheckOutAll(eventId: string)
    {
        return this.postData(`/events/${eventId}/checkpoints/actions/checkoutall`, {}, (response) => 
        {
            return response;
        });
    }

    public CheckpointAttendees(eventId: string, skip?: number, limit?: number, inlineCount?: boolean): Promise<ListResponse<Attendee>>
    {
        let url = `/events/${eventId}/checkpoints/attendees`;

        if(isFinite(limit))
            url += `?$top=${limit}`
        if(isFinite(skip))
            url += `&$skip=${skip}`
        if(inlineCount)
            url += `&$inlineCount=all`

        return this.getData(url, (response: any) => 
        { 
            var retval: ListResponse<Attendee> = {
                items: response.items.map((a) => Attendee.fromJson(a)),
                count: response['@odata.count']
            };
            
            //response.results = response.items.map((a) => { return Attendee.FromJson(a); });
            return retval;
        });
    }

    public CheckpointAttendeesSearch(eventId: string, searchString: string)
    {
        let url = `/events/${eventId}/checkpoints/attendees/search?q=${searchString}`;

        return this.getData(url, (response: any) => 
        { 
            response.results = response.items.map((a) => { return Attendee.fromJson(a); });
            return response;
        });
    }

    // --------------------------------------------------
    // CHECKIN FUNCTIONS
    // --------------------------------------------------

    public CreateRegistration(eventId: string, registration: Registration)
    {
        return this.postData(`/events/${eventId}/registration`, registration, (response) => 
        {
            return response;
        });
    }

    public UpdateRegistration(eventId: string, registrationId: string, registration: Registration)
    {
        return this.patchData(`/events/${eventId}/registration/${registrationId}`, registration, (response) => 
        {
            return response;
        });
    }

    public DeleteRegistration(eventId: string, registrationId: string)
    {
        return this.deleteData(`/events/${eventId}/registration/${registrationId}`, (response) => 
        {
            return response;
        });
    }

    // HELPER FUNCTIONS

    private async getData(endpointUrl: string, callback: (data) => any)
    {
        var options = await this.getGetOptions();
        
        return fetch(this.apiRoot + endpointUrl, options).then((response) =>
        {
            if(response.ok)
            {
                return response.json().then(callback);
            }
            else
            {
                if(response.status === 401)
                    console.error(response);
                    //window.location.href = `/auth/login?r=${window.location.href}`;

                throw new Error('Error getting data from service: ' + response.statusText);
            }
        });
    }

    private async postData<T>(endpointUrl: string, obj: T, callback: (data) => T)
    {
        let options = await this.getPostOptions();
        options.body = JSON.stringify(obj);

        return fetch(this.apiRoot + endpointUrl, options).then((response) =>
        {
            if(response.ok)
            {
                return response.json().then(callback);
            }
            else
            {
                throw new Error('Error posting data to service: ' + response.statusText);
            }
        });
    }

    private async patchData<T>(endpointUrl: string, obj: T, callback: (data) => T)
    {
        let options = await this.getPatchOptions();
        options.body = JSON.stringify(obj);

        return fetch(this.apiRoot + endpointUrl, options).then((response) =>
        {
            if(response.ok)
            {
                return response.json().then(callback);
            }
            else
            {
                throw new Error('Error patching data to service: ' + response.statusText);
            }
        });
    }

    private async deleteData(endpointUrl: string, callback: (data) => any)
    {
        var options = await this.getDeleteOptions();

        return fetch(this.apiRoot + endpointUrl, options).then((response) =>
        {
            if(response.ok)
            {
                return response.json().then(callback);
            }
            else
            {
                throw new Error('Error deleting data from service: ' + response.statusText);
            }
        });
    }

    // OPTIONS FUNCTIONS

    private async getGetOptions(): Promise<RequestInit>
    {
        const accessToken = await Query.authProvider();

        return {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'authorization': `bearer ${accessToken}`
            }
        };
    }

    private async getPostOptions(): Promise<RequestInit>
    {
        const accessToken = await Query.authProvider();

        return {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'authorization': `bearer ${accessToken}`
            }
        }
    }

    private async getPatchOptions(): Promise<RequestInit>
    {
        const accessToken = await Query.authProvider();

        return {
            method: 'PATCH',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'authorization': `bearer ${accessToken}`
            }
        }
    }

    private async getDeleteOptions(): Promise<RequestInit>
    {
        const accessToken = await Query.authProvider();

        return {
            method: 'DELETE',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'authorization': `bearer ${accessToken}`
            }
        }
    }
}