
import { Component, ComponentProps, useCallback, useEffect, useRef } from "react";
import { Portal } from "./Portal";

import "./Drawer.scss";
import { CircularProgress } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

export interface DrawerProps extends ComponentProps<any>
{
    title?: string;
    //subtitle?: string;

    open: boolean;
    modal?: boolean;
    onDismiss?: () => void;
    footer?: React.ReactNode;
    loading?: boolean;
}

export const Drawer = (props: DrawerProps) =>
{
    const { open, loading = false, onDismiss, modal = false, title = '', children, footer } = props;
    const drawerRef = useRef<HTMLDivElement>(null);

    const handleDismiss = (e: React.MouseEvent<HTMLButtonElement>) =>
    {
        e.target === e.currentTarget && !modal && onDismiss?.();
    }

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) =>
        {
            if(drawerRef.current 
                && !drawerRef.current.contains(e.target as Node)
                && (e.target as HTMLElement).closest('#app-root') !== null)
                onDismiss?.();
        }

        if(open)
        {
            setTimeout(() => {
                document.body.addEventListener('click', handleClickOutside);
            }, 0);
        }

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        }
    }, [open]);

    if(open)
    {
        return (
            <Portal>
                <div className="drawer" ref={drawerRef}>
                    <div className="drawer-title">
                        <h3>{title}</h3>
                        <div>
                            {/* <button onClick={handleDismiss}>&gt;</button> */}
                            <button onClick={handleDismiss}>X</button>
                        </div>
                    </div>
                    {/* <div className="drawer-content">

                    </div> */}

                    { !loading && children }
                    {
                        loading &&
                        <div className="drawer-loading">
                            <CircularProgress color="primary" />
                        </div>
                    }
                    
                    {
                        footer &&
                        <div className="drawer-footer">
                            {footer}
                        </div>
                    }
                </div>
            </Portal>
        );
    }
    else
    {
        return null;
    }
}
