import React, { useEffect, useCallback } from "react";

import "./GridMenuDivider.scss";

export interface GridMenuItemDivider {
};

export const GridMenuDivider = (props: GridMenuItemDivider) => {

    return (
        <hr className="grid-menu-divider" />
    );
};