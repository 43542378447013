import { FunctionComponent, useEffect } from "react";
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Dashboard } from "../pages/Dashboard";
import { AttendeesList } from "../pages/Attendees/AttendeesList";
import { ArchivedAttendeesPage } from "../pages/Attendees/ArchivedAttendees";
import { ActivityList } from "../pages/Activities/ActivityList";
import { VolunteersPage } from "../pages/Volunteers/VolunteersPage";
import { ActivityRoute } from "./ActivityRoute";
import { ZoneRoute } from "./ZoneRoute";
import { AttendeeDetails } from "../pages/Attendees/AttendeeDetails";
import { CheckpointAttendees } from "../pages/Checkpoints/CheckpointAttendees";
import { CheckpointList } from "../pages/Checkpoints/CheckpointList";
import { useCurrentContext } from "../hooks/useCurrentContext";
import { StorageKeys } from "../constants/Constants";
import { SideNavigation } from "../components/SideNavigation";
import { ActivitiesRoot } from "../pages/Activities/ActivitiesRoot";
import { AttendeesRoot } from "../pages/Attendees/AttendeesRoot";
import { RegistrationRoot } from "../pages/Registration/RegistrationRoot";
import { EventSettingsRoot } from "../pages/EventSettings/EventSettingsRoot";
import { BadgePage } from "../pages/Badges/BadgePage";

export const EventPage: FunctionComponent = (props) => 
{
    const { event, isLoaded } = useCurrentContext();

    useEffect(() => {
        if(event)
            localStorage.setItem(StorageKeys.LastEventId, event.id);
    }, [event]);

    return (
        <>
            <SideNavigation />
            <PageContainer>
                {
                    isLoaded &&
                    <>
                        <Routes>
                            <Route path={`overview`} element={<Dashboard />} />

                            <Route path={`activities/*`} element={<ActivitiesRoot />} />
                            <Route path={`attendees/*`} element={<AttendeesRoot />} />
                            <Route path={`registration/*`} element={<RegistrationRoot />} />
                            <Route path={`settings/*`} element={<EventSettingsRoot />} />

                            <Route path={`checkpoints/list`} element={<CheckpointList />} />
                            <Route path={`checkpoints/attendees`} element={<CheckpointAttendees />} />
                            <Route path={`volunteers`} element={<VolunteersPage />} />
                            <Route path={`volunteers/zones/:zoneId`} element={<ZoneRoute />} />

                            <Route path={`badges`} element={<BadgePage />} />
            
                            <Route path={`checkpoints`} element={<Navigate to={`checkpoints/list`} />} />
                            <Route path="" element={<Navigate to={`overview`} />} />
                        </Routes>
                    </>
                }
            </PageContainer>
        </>
    );
};

const PageContainer = (props) => {
    const { children } = props;

    return (
        <main className="page-container" style={{ 
            width: 'calc(100vw - var(--sidenav-width))', 
            height: 'calc(100vh - var(--topnav-height))',
            display: 'flex'
        }}>
            {children}
        </main>
    );
}

