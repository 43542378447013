import { useField, useFormikContext } from "formik";
import { CSSProperties } from "react";
import "./Fields.scss";
import { BMFieldProps } from "./BMFieldProps";

export interface BMTextArea extends BMFieldProps
{
    rows?: number;
}

export const BMTextArea = (props: BMTextArea) => 
{
    const { name, label, description, rows = 4, style, autoFocus = false, placeholder, required = false, disabled = false, layout = 'horizontal' } = props;
    const [field, meta, helpers] = useField(name);
    const context = useFormikContext();

    const fieldStyle: CSSProperties = Object.assign({ gridArea: name }, style);

    return (
        <div className={`bm-field ${layout}`} style={fieldStyle}>

            { label && <label style={layout === 'horizontal' ? { alignSelf: 'start', padding: '8px 0' } : {}}>{label}</label> }
            <textarea
                name={name}
                placeholder={placeholder}
                disabled={disabled || context.isSubmitting}
                required={required}
                autoFocus={autoFocus}
                style={{ gridArea: name, flex: 1 }}
                rows={rows}
                {...field}
            />
            {
                description &&
                <div className="subtle">{description}</div>
            }
            {
                meta.touched && !!meta.error &&
                <div className="error">{meta.error}</div>
            }
        </div>
    );
};
