import { FunctionComponent, useEffect } from "react";
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { TopNavigation } from "./components/TopNavigation";
import { useCurrentContext } from "./hooks/useCurrentContext";
import { StorageKeys } from "./constants/Constants";
import { Events } from "./pages/Events";
import { EventPage } from "./routes/EventRoute";

export const OrgDashboard: FunctionComponent = (props) => 
{
    const { organization } = useCurrentContext();

    useEffect(() => {
        if(organization)
            localStorage.setItem(StorageKeys.LastOrganizationId, organization.id);
    }, [organization]);

    return (
        <>
            <TopNavigation fullWidth={window.location.pathname.indexOf('/events/') === -1} />
            <div>
                <Routes>
                    {/* <Route path="/settings" element={<OrgSettings />} /> */}
                    <Route path="/events" element={<Events />} />
                    <Route path="/events/:eventId/*" element={<EventPage />} /> 
                    <Route path="/" element={<RedirectToEvents />} />
                </Routes>
            </div>
        </>
    );
};

const RedirectToEvents = (props) => 
{
    const { orgId } = useParams();
    const nav = useNavigate();

    useEffect(() => nav(`/${orgId}/events`), []);

    return null;
};
