import { Card, CardContent, Grid, Skeleton } from "@mui/material";

export const EventListShimmer = () => (
    <div>
        <Skeleton variant="text"></Skeleton>
        <Skeleton variant="text"></Skeleton>
    </div>
);

export const EventsShimmer = () => (
    <>
        <Grid item xs={4}>
            <Card>
                <CardContent sx={{ minHeight: '220px' }}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={4}>
            <Card>
                <CardContent sx={{ minHeight: '220px' }}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={4}>
            <Card>
                <CardContent sx={{ minHeight: '220px' }}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </CardContent>
            </Card>
        </Grid>
    </>
);

export const SideNavigationShimmer = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <div style={{ padding: '10px 32px 10px 32px', borderBottom: '1px solid var(--color-sidenav-border)' }}> */}
            <div style={{ padding: '10px 32px 10px 32px' }}>
                <Skeleton />
                <Skeleton />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '10px 32px' }}><Skeleton variant="circular" width={20} height={20} /><Skeleton style={{ flex: '1', fontSize: '1.5rem' }} /></div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '10px 32px' }}><Skeleton variant="circular" width={20} height={20} /><Skeleton style={{ flex: '1', fontSize: '1.5rem' }} /></div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '10px 32px' }}><Skeleton variant="circular" width={20} height={20} /><Skeleton style={{ flex: '1', fontSize: '1.5rem' }} /></div>
            </div>
        </div>
    );
}