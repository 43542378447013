import { ReactNode, useEffect } from "react";

import "./Page.scss";

export interface PageProps
{
    //title?: ReactNode;
    pageTitle?: string;
    className?: string;
    framed?: boolean;
    //frameSize?: number;
    style?: React.CSSProperties;
    //actionBarItems?: string | JSX.Element | Array<JSX.Element>;
    children?: ReactNode;
}

export const Page = (props: PageProps) =>
{
    const { children, style, className, pageTitle = '', framed = false } = props;

    const classes = ["component-page"];
    if(className)
        classes.push(props.className);
    if(framed)
        classes.push('framed');

    useEffect(() => {
        if(pageTitle)
            document.title = `${pageTitle} | Badgemeister`;
        else
            document.title = `Badgemeister`;
    }, [[pageTitle]])

    return (
        <div className={classes.join(' ')} style={style}>
            {children}
        </div>
    );
};

export const PageHeader = (props) => 
{
    const { children } = props;

    return (
        <div className="page-header">
            {children}
        </div>
    );
};

export const PageContent = (props) =>
{
    const { children } = props;

    return (
        <div className="page-content">
            {children}
        </div>
    );
};
