import { Query } from "./Query";
import User from "../dto/User";

export default class UserQuery
{
    private query: Query<User>;
    private rootUrl: string;

    constructor(rootUrl?: string)
    {
        this.rootUrl = (rootUrl ?? '') + '/users';
        this.query = new Query<User>(this.rootUrl, User.fromJson);
    }

    public me()
    {
        return this.query.action<User>('me', 'GET');
    }

    public getById(id: string)
    {
        return {
            //update: (data: any) => { return this.update(id, data) },
            get: () => { return this.getOne(id); },
            //delete: () => { return this.delete(id); },
            //events: new CandidatesQuery(this.rootUrl + `/${id}`)
        };
    }

    // private update(id: string, data: Partial<User>)
    // {
    //     return this.query.update(id, data);
    // }

    private getOne(id: string)
    {
        return this.query.getById(id);
    }

    // private delete(id: string)
    // {
    //     return this.query.delete(id);
    // }
}