import React, { Children, cloneElement, useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import "./PageToolbar.scss";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";


export interface PageToolbar
{
    children?: React.ReactNode;
    startItems?: React.ReactNode;
    endItems?: React.ReactNode;
    style?: React.CSSProperties;
}

export const PageToolbar = (props: PageToolbar) =>
{
    const { children, endItems, startItems, style } = props;

    return (
        <div className="page-toolbar" style={style}>
            {children || startItems}
            <div className="end-items">
                {endItems}
            </div>
        </div>
    );
}

export interface ToolbarButtonProps
{
    children?: React.ReactNode;
    icon?: React.ReactNode;
    endIcon?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    variant?: 'text' | 'outlined' | 'contained';
}

export const ToolbarButton = (props: ToolbarButtonProps) =>
{
    const { children, icon, endIcon, onClick, variant = 'outlined' } = props;

    return (
        <Button 
            onClick={onClick}
            variant={variant}
            color="secondary"
            style={{ fontSize: 'var(--font-subtle-size)', padding: '5px 10px', fontWeight: '400'  }}
            startIcon={icon}
            endIcon={endIcon}>
                {children}
        </Button>
    );

    // return (
    //     <button
    //         onClick={onClick}
    //         className="link"
    //         style={{ fontSize: 'var(--font-subtle-size)', fontWeight: 400 }}>
    //         {children}
    //     </button>
    // )
};

export interface ToolbarIconButtonProps 
{
    children?: React.ReactNode;
    tooltip?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    style?: React.CSSProperties
}

export const ToolbarIconButton = (props: ToolbarIconButtonProps) =>
{
    const { children, tooltip, onClick, style } = props;

    const newChildren = Children.map(children, (c: any) => {
        return cloneElement(c, { fontSize: 'small' });
    });

    if(tooltip)
    {
        return (
            <Tooltip title={tooltip} placement="top" style={style}>
                <IconButton 
                    onClick={onClick}
                    color="secondary"
                    size="small"
                    style={{ fontSize: 'var(--font-subtle-size)', fontWeight: '400'  }}>
                    {newChildren}
                </IconButton>
            </Tooltip>
        );
    }
    else
    {
        return (
            <IconButton 
                onClick={onClick}
                color="secondary"
                size="small"
                style={{ fontSize: 'var(--font-subtle-size)', fontWeight: '400', width: '32px', height: '32px', ...style }}>
                {newChildren}
            </IconButton>
        );
    }
};

export interface ToolbarSearchBoxProps
{
    defaultValue?: string;
    onChange?: (value: string) => void;
    debounceTime?: number;
    placeholder?: string;
}

export const ToolbarSearchBox = (props: ToolbarSearchBoxProps) =>
{
    const { debounceTime = 200, onChange, defaultValue = '', placeholder = 'Search...' } = props;
    const [value, setValue] = useState(defaultValue);
    const [timeoutId, setTimeoutId] = useState(-1);

    useEffect(() => {
        clearTimeout(timeoutId);
        const newTimeoutId: any = setTimeout(() => {
            onChange?.(value);
        }, debounceTime);
        setTimeoutId(newTimeoutId);
    }, [value, debounceTime]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }, []);

    const handleClear = useCallback(() => {
        setValue('');
    }, []);

    return (
        <div style={{ flex: '0 1 320px', position: 'relative' }}>

            <SearchIcon style={{
                position: 'absolute',
                left: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'rgba(255,255,255,0.5)'
            }} />

            <input 
                type="text" 
                value={value} 
                onChange={handleChange} 
                placeholder={placeholder}
                style={{ width: '100%', paddingLeft: '36px', paddingRight: '40px' }}
            />

            {
                value.length > 0 &&
                <ToolbarIconButton 
                    onClick={handleClear}
                    style={{ position: 'absolute', right: '0px', top: '0px', borderRadius: '0 5px 5px 0' }}
                >
                    <ClearIcon />
                </ToolbarIconButton>
            }

        {/* <TextField
            variant="filled"
            size="small"
            sx={{ width: '280px', input: { padding: '6px 12px' } }}
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder="Search"
        /> */}

        </div>
    );
}