import { useField, useFormikContext } from "formik";
import { CSSProperties } from "react";
import "./Fields.scss";
import { BMFieldProps } from "./BMFieldProps";

export interface BMTextFieldProps extends BMFieldProps
{
    type?: 'text' | 'number' | 'email';
    autoComplete?: string;
}

export const BMTextField = (props: BMTextFieldProps) => 
{
    const { name, label, description, type = 'text', autoFocus = false, placeholder, required = false, disabled = false, autoComplete = undefined, layout = 'horizontal', style } = props;
    const [field, meta, helpers] = useField(name);
    const context = useFormikContext();

    const fieldStyle: CSSProperties = Object.assign({ gridArea: name }, style);

    return (
        <div className={`bm-field ${layout}`} style={fieldStyle}>
            { label && <label>{label} {required ? <span className="required">*</span> : ''}</label> }
            <div style={{ flex: 1 }}>
                <input 
                    type={type} 
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled || context.isSubmitting}
                    autoComplete={autoComplete}
                    required={required}
                    autoFocus={autoFocus}
                    style={{ width: '100%' }}
                    {...field}
                />
                {
                    description &&
                    <div className="subtle">{description}</div>
                }
                {
                    meta.touched && !!meta.error &&
                    <div className="error">{meta.error}</div>
                }
            </div>
        </div>
    );
};
