import { useState, useCallback } from "react";
import { useToggle } from "../hooks/useToggle";
import { Grid, GridColumn, GridProps } from "../components/Grid/Grid"
import { GridMenu } from "../components/Grid/Menus/GridMenu";
import { GridMenuItem } from "../components/Grid/Menus/GridMenuItem";

import EditIcon from "@mui/icons-material/Edit";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ArchiveIcon from '@mui/icons-material/Archive';
import PrintIcon from "@mui/icons-material/Print";
import { GridMenuDivider } from "../components/Grid/Menus/GridMenuDivider";

export interface BMGridProps<T> extends GridProps<T>
{
    onViewItem?: (item: T) => void;
    onEditItem?: (item: T) => void;
    onDeleteItem?: (item: T) => void;

    headerMenuItems?: [
        {type: 'button' | 'divider', icon: React.ReactNode, label: string, onClick: () => void},
    ]
}

export const ActivityGrid = function<T>(props: BMGridProps<T>) 
{
    const { onViewItem, onEditItem, onDeleteItem, ...gridProps } = props;
    const headerMenu = useToggle(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>(null);
    const itemMenu = useToggle(false);
    const [itemMenuPos, setItemMenuPos] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const [selectedItem, setSelectedItem] = useState(null);

    const handleHeaderContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>, col: GridColumn) => {
        if(menuAnchorEl !== e.currentTarget)
        {
            headerMenu.open();
            setMenuAnchorEl(e.currentTarget);
        }
        else
        {
            headerMenu.close();
            setMenuAnchorEl(null);
        }
    }, [menuAnchorEl, headerMenu]);

    const closeHeaderContextMenu = useCallback(() => {
        headerMenu.close();
        setMenuAnchorEl(null);
    }, [menuAnchorEl, headerMenu]);

    const handleItemMenu = useCallback((e: React.MouseEvent<HTMLDivElement>, item: T) => {
        setItemMenuPos({ x: e.clientX, y: e.clientY });
        itemMenu.open();
        setSelectedItem(item);
    }, [itemMenu]);

    const closeItemMenu = useCallback(() => {
        itemMenu.close();
    }, [itemMenu]);

    const handleViewItem = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        onViewItem?.(selectedItem);
        itemMenu.close();
    }, [onViewItem, selectedItem]);

    const handleEditItem = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        onEditItem?.(selectedItem);
        itemMenu.close();
    }, [onEditItem, selectedItem]);

    const handleDeleteItem = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        onDeleteItem?.(selectedItem);
        itemMenu.close();
    }, [onDeleteItem, selectedItem]);

    return (
        <>
            <Grid
                {...gridProps}
                //onItemContextMenu={handleItemMenu}
                //onColumnContextMenu={handleHeaderContextMenu}
            />
        
            <GridMenu open={headerMenu.isOpen} anchorEl={menuAnchorEl} onRequestClose={closeHeaderContextMenu}>
                {/* <GridMenuItem icon={EditIcon}>Edit Field</GridMenuItem> */}
                <GridMenuItem icon={<ArrowDownwardIcon />}>Sort A-Z</GridMenuItem>
                <GridMenuItem icon={<ArrowUpwardIcon />}>Sort Z-A</GridMenuItem>
                {/* <GridMenuDivider />
                <GridMenuItem icon={DeleteIcon}>Delete Field</GridMenuItem> */}
            </GridMenu>

            <GridMenu open={itemMenu.isOpen} onRequestClose={closeItemMenu} anchorPosition={itemMenuPos}>
                <GridMenuItem icon={<VisibilityIcon />} onClick={handleViewItem}>View session</GridMenuItem>
                <GridMenuItem icon={<EditIcon />} onClick={handleEditItem}>Edit session</GridMenuItem>
                <GridMenuDivider />
                <GridMenuItem icon={<DeleteIcon />} onClick={handleDeleteItem}>Delete</GridMenuItem>
            </GridMenu>
        </>
    );
}
