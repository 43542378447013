import { FunctionComponent, useContext } from "react";
import { Page } from "../components/Page";
import { Header } from "../components/Header";
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Grid, Paper } from "@mui/material";
import { Utils } from "../utils/Utils";
import EventContext from "../context/EventContext";
import OrganizationContext from "../context/OrganizationContext";
import { useNavigate } from "react-router-dom";
import { useCurrentContext } from "../hooks/useCurrentContext";

export const Dashboard = (props: any) => 
{
    // const orgContext = useContext(OrganizationContext);
    // const eventContext = useContext(EventContext);
    const { organization, event } = useCurrentContext();
    const nav = useNavigate();

    return (
        <Page pageTitle={event.title ?? 'Loading...'} framed>
            <Header>Dashboard</Header>

            <Grid container spacing={2}>

                <Grid item xs={6}>
                    <Card>
                        <CardActionArea onClick={() => { 
                                nav(Utils.joinPaths(`/${organization.id}/events/${event.id}/attendees`));
                            }}>
                            <CardHeader title="Attendees" />
                            <CardContent>
                                Click here to view all attendees
                            </CardContent>
                            {/* <CardActions>
                                <Button>Attendees</Button>
                            </CardActions> */}
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={6}>
                    <Card>
                        <CardActionArea onClick={() => {
                            nav(Utils.joinPaths(`/${organization.id}/events/${event.id}/attendees`));
                        }}>
                            <CardHeader title="Activities" />
                            <CardContent>
                                Click here to view all activities
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                {/* <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Activities" />
                        <CardContent>
                            
                        </CardContent>
                    </Card>
                </Grid> */}

            </Grid>
        </Page>
    );
}
