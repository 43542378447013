import { useEffect } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Skeleton } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "../../constants/AppRoutes";

import "./Login.scss";
import { useAuth, useClerk, useSignIn } from "@clerk/clerk-react";

export const Login = (props: any) =>
{
    const [searchParams, setSearchParams] = useSearchParams();
    //const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
    const { isLoaded, isSignedIn } = useAuth();
    const clerk = useClerk();
    const nav = useNavigate();
    const returnTo = searchParams.get('returnTo');

    useEffect(() => 
    {
        if(isLoaded)
        {
            if(isSignedIn)
            {
                // try
                // {
                //     const result = await handleRedirectCallback();
                //     const navState = JSON.parse(result.appState) ?? '{}';
                //     nav(navState?.redirectTo ?? AppRoutes.Home());
                // }
                // catch(ex)
                // {
                //     alert(ex);
                //     console.log(ex);
                // }
                
                nav(AppRoutes.Root());
            }
            else
            {
                const nonce = btoa(crypto.getRandomValues(new Uint8Array(16)).toString())
                window.sessionStorage.setItem(nonce, JSON.stringify({
                    returnTo
                }));

                clerk.redirectToSignIn();

                // loginWithRedirect({
                //     appState: nonce
                // });
            }
        }
    }, [clerk, isLoaded, isSignedIn, nav]);

    return (
        <div className="auth-page">
            <div className="auth-card">
                <div className="auth-hole"></div>
                <div className="card-title">
                    <img className="logo" src="/images/logo.svg" alt="Badgemeister Logo" />
                </div>
                <div className="card-content">
                    <CircularProgress />
                    <div>Signing in...</div>
                </div>
            </div>
        </div>
    );
}
