
export interface BMDisplayFieldProps
{
    name?: string;
    label?: string;
    layout?: 'horizontal' | 'vertical';
    children?: React.ReactNode;
    value?: React.ReactNode;
    style?: React.CSSProperties;
}

export const BMDisplayField = (props: BMDisplayFieldProps) =>
{
    const { name, label, layout = 'horizontal', value, children, style } = props;

    const fStyle: React.CSSProperties = layout === 'horizontal' ? {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        flexDirection: 'row'
    } : {
        display: 'flex',
        flexDirection: 'column'
    };

    const fieldStyle = Object.assign({ gridArea: name }, fStyle, style);

    return (
        <div style={fieldStyle}>
            { label && <label style={layout === 'horizontal' ? { alignSelf: 'start', padding: '8px 0' } : { marginBottom: '0' }}>{label}</label> }
            <div style={{ gridArea: name, flex: 1 }}>
                {value || children || '(none)'}
            </div>
        </div>
    );
}