export class Payload
{
    public source: string;
    public action: AppAction;
}

export interface AppAction
{
    type: string;
    value: any;
    args?: any;
}

export class Dispatcher
{
    private static callbacks: Array<Function> = [];
    private static promises: Array<Promise<any>> = [];

    public static Register(callback: (payload: Payload) => {})
    {
        this.callbacks.push(callback);
    }

    public static Dispatch(payload: Payload)
    {
        // First create array of promises for callbacks to reference.
        var resolves: Function[] = [];
        var rejects: Function[] = [];
        this.promises = this.callbacks.map(function (_, i) 
        {
            return new Promise(function (resolve, reject) 
            {
                resolves[i] = resolve;
                rejects[i] = reject;
            });
        });

        // Dispatch to callbacks and resolve/reject promises.
        this.callbacks.forEach(function (callback, i) 
        {
            // Callback can return an obj, to resolve, or a promise, to chain.
            // See waitFor() for why this might be useful.
            Promise.resolve(callback(payload)).then(function () 
            {
                resolves[i](payload);
            }, function () 
                {
                    rejects[i](new Error('Dispatcher callback unsuccessful'));
                });
        });
        this.promises = [];
    }
}