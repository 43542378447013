import BaseQuery from "./BaseQuery";
import { View } from "../dto/View";

export default class EventViewsQuery extends BaseQuery<View>
{
    constructor(rootUrl?: string)
    {
        super((rootUrl ?? '') + '/views');
    }

    // public getById(id: string)
    // {
    //     return {
    //         ...super.getById(id),
    //         activities: new ActivityQuery(`${this.rootUrl}/${id}`),
    //         attendees: new AttendeeQuery(`${this.rootUrl}/${id}`),
    //         regItems: new RegItemQuery(`${this.rootUrl}/${id}`),
    //         regTokens: new RegTokenQuery(`${this.rootUrl}/${id}`)
    //     };
    // }
}