import RegItem from "../../dto/RegItem";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { PageToolbar, ToolbarButton, ToolbarIconButton, ToolbarSearchBox } from "../../components/PageToolbar";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useToggle } from "../../hooks/useToggle";
import { useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Currency } from "../../utils/Currency";
import { groupBy, sortByProperty } from "../../utils/Utils";
import { useCallback, useContext, useState } from "react";
import { EditRegTierDrawer } from "./EditRegTierDrawer";
import { Grid } from "../../components/Grid/Grid";
import { Table, TableConfig } from "../../components/Table/Table";

import "./RegTierPage.scss";
import { Chip } from "../../components/Chip";
import { ViewRegTierDrawer } from "./ViewRegTierDrawer";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ClearIcon from "@mui/icons-material/Clear";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import { DialogContext } from "../../context/DialogContext";

export interface RegItemsPageProps
{

}

export const RegTierPage = (props: RegItemsPageProps) =>
{
    const { event, isLoaded } = useCurrentContext();
    const dialog = useContext(DialogContext);
    const queryClient = useQueryClient();
    const api = useAPI();
    const editDrawer = useToggle(false);
    const viewDrawer = useToggle(false);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedItem, setSelectedItem] = useState<RegItem>(null);
    const [tableConfig, setTableConfig] = useState<TableConfig<RegItem>>({
        sorts: [{ key: 'title', dir: 'asc' }],
        columns: [
            { key: 'title', name: 'Name', width: 1, sortable: true, render: (value, item) => {
                return <a href="#" className="text" onClick={() => viewItem(item)}>{value}</a>
            } },
            { key: 'group', name: 'Group', sortable: true },
            { key: 'price', name: 'Price (USD)', sortable: true, render: (value) => {
                return Currency.format(value);
            }},
            { key: 'status', name: 'Status', render: (value, item) => {
                if(item.active && item.public)
                    return <Chip color="success">Available to Public</Chip>
                else if(item.active && item.public === false)
                    return <Chip color="warning">Available via Token</Chip>
                else
                    return <Chip>Disabled</Chip>
            }},
            { key: 'actions', name: 'Actions', render: (value, item) => {
                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <button onClick={() => editItem(item)}>Edit</button>
                        <button onClick={() => viewItem(item)}>View</button>
                    </div>
                )
            }}
        ]
    });

    const itemsQuery = useQuery(
        QueryKeys.RegTiers.All(event.id, JSON.stringify(tableConfig.sorts)), 
        async () => {
            if(tableConfig.sorts.length > 0)
            {
                const sort = tableConfig.sorts[0];
                return api.events.getById(event.id).regItems.get({ orderBy: { field: sort.key, direction: sort.dir } })
            }
            else
                return api.events.getById(event.id).regItems.get();
        }, 
        { enabled: isLoaded, initialData: { items: [] } }
    );

    const viewItem = useCallback((item: RegItem) => {
        editDrawer.close();
        setSelectedItem(item);
        viewDrawer.open();
    }, [editDrawer, viewDrawer]);

    const editItem = useCallback((item: RegItem) => {
        viewDrawer.close();
        setSelectedItem(item);
        editDrawer.open();
    }, [editDrawer, viewDrawer]);

    const disableItem = useCallback(async (item: RegItem) => {
        await api.events.getById(event.id).regItems.getById(item.id).update({ active: false });
        itemsQuery.refetch();
    }, [api, event.id, itemsQuery]);

    const enableItem = useCallback(async (item: RegItem) => {
        await api.events.getById(event.id).regItems.getById(item.id).update({ active: true });
        itemsQuery.refetch();
    }, [api, event.id, itemsQuery]);

    const makePublic = useCallback(async (item: RegItem) => {
        await api.events.getById(event.id).regItems.getById(item.id).update({ public: true });
        itemsQuery.refetch();
    }, [api, event.id, itemsQuery]);

    const makePrivate = useCallback(async (item: RegItem) => {
        await api.events.getById(event.id).regItems.getById(item.id).update({ public: false });
        itemsQuery.refetch();
    }, [api, event.id, itemsQuery]);

    const deleteItem = useCallback((item: RegItem) => {
        dialog.show({
            title: 'Delete registration tier',
            content: 'Are you sure you want to delete this registration tier?',
            options: [
                { text: 'Delete', type: 'danger' },
                { text: 'Cancel' }
            ],
            onSelect: async (option) => {
                if(option.text === 'Delete')
                {
                    await api.events.getById(event.id).regItems.getById(item.id).delete()
                    queryClient.invalidateQueries(QueryKeys.RegItems.All(event.id));
                    itemsQuery.refetch();
                }
            }
        });
    }, []);

    const handleItemContextMenu = useCallback((e, item: RegItem) => {
        return [
            { label: 'View', onClick: viewItem, icon: <VisibilityIcon />},
            { label: 'Edit', onClick: editItem, icon: <EditIcon />},
            { type: 'divider' },
            ...(item.active ? [{ label: 'Disable', onClick: disableItem, icon: <ToggleOffIcon /> }] : []),
            ...(item.active === false ? [{ label: 'Enable', onClick: enableItem, icon: <ToggleOnIcon /> }] : []),
            ...(item.active && item.public ? [{ label: 'Make private', onClick: makePrivate, icon: <PublicOffIcon /> }] : []),
            ...(item.active && item.public === false ? [{ label: 'Make public', onClick: makePublic, icon: <PublicIcon /> }] : []),
            { type: 'divider' },
            { label: 'Delete', onClick: deleteItem, icon: <DeleteIcon /> },
        ]
    }, []);

    const items = itemsQuery.data?.items
        .filter(i => {
            if(searchText.length > 0)
                return i.title.toLowerCase().includes(searchText.toLowerCase()) && i.type === 'registration';
            else 
                return i.type === 'registration';
        }) || [];

    return (
        <>
            <PageToolbar
                startItems={
                    <>
                        <ToolbarButton icon={<AddIcon />} onClick={() => editItem(null)}>New registration tier</ToolbarButton>
                        <ToolbarSearchBox defaultValue={searchText} onChange={value => { setSearchText(value) }} placeholder="Search name..." />
                    </>
                }
                endItems={
                    <>
                        {/* <ToolbarIconButton tooltip="Refresh" onClick={() => { }}><RefreshIcon /></ToolbarIconButton> */}
                    </>
                }
            />

            <div className="reg-items-table" style={{ padding: '20px' }}>

                <Table
                    items={items}
                    loading={itemsQuery.isFetched === false}
                    onItemContextMenu={handleItemContextMenu}
                    config={tableConfig}
                    onConfigChange={setTableConfig}
                />

            </div>

            <ViewRegTierDrawer
                open={viewDrawer.isOpen}
                onDismiss={viewDrawer.close}
                regItemId={selectedItem?.id}
            />

            <EditRegTierDrawer
                open={editDrawer.isOpen}
                onDismiss={editDrawer.close}
                onSave={item => { 
                    editDrawer.close(); 
                    itemsQuery.refetch()
                }}
                regItemId={selectedItem?.id}
            />
        </>
    );
};
