import { Component } from "react";

export interface SearchBoxProps
{
    value?: string;
    placeholder?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    className?: string;
    style?: React.CSSProperties;
    autofocus?: boolean;
    debounceTime?: number;
}

export interface SearchBoxState
{
    internalValue: string;
}

export class SearchBox extends Component<SearchBoxProps, SearchBoxState>
{
    private inputElement: HTMLElement;
    private intervalId: any;

    public static defaultProps: Partial<SearchBoxProps> = {
        value: '',
        placeholder: 'Search...',
        autofocus: false,
        debounceTime: 250
    };

    constructor(props: SearchBoxProps)
    {
        super(props);

        this.state = {
            internalValue: props.value
        };
    }

    public componentDidMount()
    {

    }

    public componentDidUpdate(prevProps: SearchBoxProps, prevState: SearchBoxState)
    {
        if(this.props.value !== prevProps.value
            && this.props.value !== this.state.internalValue)
        {
            this.setState({ internalValue: this.props.value });
        }
    }

    public render()
    {
        let clearButtonClasses = "clear-button";

        if(!this.state.internalValue)
            clearButtonClasses += ' hidden';

        let classes = this.props.className;
        let style = this.props.style || {};

        return (
            <div style={style} className={"component-searchbox" + (classes ? ' ' + classes : '')}>
                <i className="fa fa-search" />
                <input
                    className="bm-input"
                    type="text"
                    placeholder={this.props.placeholder}
                    value={this.state.internalValue}
                    ref={(el) => { this.inputElement = el; }}
                    onChange={this.onChange.bind(this)} />
                <a href="#" className={clearButtonClasses} onClick={this.onClearSearchClick.bind(this)}>
                    <i className="fa fa-times" />
                </a>
            </div>
        );
    }

    private onChange(e)
    {
        this.setState({ internalValue: e.target.value });

        if(this.props.onChange)
        {
            if(this.props.debounceTime > 0)
            {
                clearTimeout(this.intervalId);
                e.persist();
                this.intervalId = setTimeout(() => 
                { 
                    this.props.onChange(e);
                }, this.props.debounceTime);
            }
            else
            {
                this.props.onChange(e);
            }
        }
    }

    private onClearSearchClick(e)
    {
        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window['HTMLInputElement'].prototype, "value").set;
        nativeInputValueSetter.call(this.inputElement, '');
        
        var ev2 = new Event('input', { bubbles: true });
        this.inputElement.dispatchEvent(ev2);
    }
}
