import { Activity } from "../dto/Activity";
import BaseQuery from "./BaseQuery";
import CheckinQuery from "./CheckinQuery";
import { Query } from "./Query";

export default class ActivityQuery extends BaseQuery<Activity>
{
    constructor(rootUrl?: string)
    {
        super((rootUrl ?? '') + '/activities');
        this.query = new Query<Activity>(this.rootUrl);
    }

    public getById(id: string)
    {
        return {
            ...super.getById(id),
            checkins: new CheckinQuery(`${this.rootUrl}/${id}`),
            checkin: (info: { badgeId?: string, attendeeId?: string }) => {
                return this.query.actionById<void>(id, 'checkin', 'POST', info);
            },
            generateJury: (email: string, numberOfJuries: number, numberOfJurors: number) => {
                return this.query.actionById<void>(id, 'generateJury', 'POST', {
                    email, numberOfJuries, numberOfJurors
                })
            }
        };

        // const retval = {
        //     update: (data: Partial<Activity>) => { return this.update(id, data) },
        //     get: () => { return this.getOne(id); },
        //     delete: () => { return this.delete(id); },
        //     select: (...fields: string[]) => {
        //         this.select(...fields);
        //         return retval;
        //     },
        //     expand: (...fields: string[]) => {
        //         this.expand(...fields);
        //         return retval;
        //     },
        //     checkin: (info: { badgeId?: string, attendeeId?: string }) => {
        //         return this.query.actionById<void>(id, 'checkin', 'POST', info);
        //     },
        //     checkins: new CheckinQuery(`${this.rootUrl}/${id}`),
        //     generateJury: (email: string, numberOfJuries: number, numberOfJurors: number) => {
        //         return this.query.actionById<void>(id, 'generateJury', 'POST', {
        //             email, numberOfJuries, numberOfJurors
        //         })
        //     }
        //     // checkins: () => {
        //     //     return this.query.actionById<void>(id, 'checkins?expand=attendee', 'GET');
        //     // }
        // };
        // return retval;
    }
}