import { Component } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogSpinner } from "../components/DialogSpinner";

export interface ConfirmDialogProps 
{
    isOpen: boolean;
    onRequestClose?: any;
    onConfirm?: any;

    title: string;
    message: string;
    buttonText: string;
}

export interface ConfirmDialogState
{
    isLoading: boolean;
}

export class ConfirmDialog extends Component<ConfirmDialogProps, ConfirmDialogState>
{
    constructor(props: ConfirmDialogProps)
    {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    public componentDidMount()
    {

    }

    public componentDidUpdate(prevProps: ConfirmDialogProps, prevState: ConfirmDialogState)
    {
        if(this.props.isOpen === false && prevProps.isOpen === true)
            this.setState({ isLoading: false });
    }

    private onButtonClick(e: React.FormEvent<HTMLFormElement>)
    {
        this.setState({ isLoading: true });

        if(this.props.onConfirm)
            this.props.onConfirm();

        e.preventDefault();
    }

    public render() 
    {
        return (
            <Dialog
                maxWidth="sm"
                open={this.props.isOpen}
                title={this.props.title}
                onClose={this.props.onRequestClose}>
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <DialogTitle className="bm-dialog-title">{this.props.title}</DialogTitle>
                    <form className="bm-dialog-form" onSubmit={this.onButtonClick.bind(this)} style={{ minWidth: '460px' }}>
                        <DialogContent>
                            <DialogContentText>{this.props.message}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.props.onRequestClose()}>Cancel</Button>
                            <Button type="submit" color="primary" variant="contained">{this.props.buttonText}</Button>
                        </DialogActions>
                    </form>
                </DialogSpinner>
            </Dialog>
        )
    }
}
