import { useCallback, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Activity } from "../../dto/Activity";
import { ActivityDialog } from "../../dialogs/ActivityDialog";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { AppRoutes } from "../../constants/AppRoutes";
import { PageToolbar, ToolbarButton, ToolbarIconButton, ToolbarSearchBox } from "../../components/PageToolbar";
import { Grid, GridConfig } from "../../components/Grid/Grid";
import { useQuery } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useToggle } from "../../hooks/useToggle";
import { DeleteActivity, GetActivities, UpdateActivity } from "../../data/ActivityQueries";

import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { ActivityGrid } from "../../grids/ActivityGrid";
import { useAPI } from "../../hooks/useAPI";
import { EditActivityDrawer } from "./EditActivityDrawer";
import { ViewActivityDrawer } from "./ViewActivityDrawer";

export const ActivityList = () => 
{
    const nav = useNavigate();
    const api = useAPI();
    const editDrawer = useToggle(false);
    const viewDrawer = useToggle(false);
    const { organization, event, isLoaded } = useCurrentContext();
    const [searchText, setSearchText] = useState('');
    const [selectedActivity, setSelectedActivity] = useState<string>(null);

    const [config, setConfig] = useState<GridConfig>({
        columns: [
            { key: 'title', name: 'Name', type: 'text', width: 200, pinned: true },
            { key: 'type', name: 'Type', type: 'select', width: 200, options: [
                { key: 'Charity' },
                { key: 'Session' },
                { key: 'Workshop' },
                { key: 'Test Type' }
            ], pinned: true },
            { key: 'location', name: 'Location', type: 'text', width: 300 },
            { key: 'totalCheckins', name: 'Check-ins', type: 'number', width: 140 },
            { key: 'createdAt', name: 'Created', type: 'datetime', width: 200, readOnly: true }
        ],
        pageIndex: 0,
        pageSize: 50
    });

    const sessionQuery = useQuery(QueryKeys.Activities.All(event.id, `${searchText}|${JSON.stringify(config)}`), () => {
        
        GetActivities(event.id, { search: searchText, orderBy: { field: 'startTime', direction: 'asc' } })

        const q = api.events.getById(event.id).activities
            .skip(config.pageIndex * config.pageSize)
            .top(config.pageSize)
            .includeCount();

        if(searchText)
            q.search(searchText);

        q.orderBy('startTime', 'asc');

        return q.get();
    }, { enabled: isLoaded, initialData: { items: [], count: 0 } });
    const activityDialog = useToggle(false);

    const viewItem = useCallback((activity: Activity) => {
        editDrawer.close();
        setSelectedActivity(activity.id);
        viewDrawer.open();
    }, [editDrawer, viewDrawer]);

    const editItem = useCallback((activity?: Activity) => {
        viewDrawer.close();
        setSelectedActivity(activity?.id);
        editDrawer.open();
    }, [editDrawer, viewDrawer]);

    const deleteItem = useCallback(async (activity: Activity) =>
    {
        if(window.confirm('Are you sure you want to delete this activity?'))
        {
            await api.events.getById(event.id).activities.getById(activity.id).delete();
            sessionQuery.refetch();
        }
    }, [event, api, sessionQuery]);

    return (
        <>
            <PageToolbar 
                startItems={
                    <>
                        <ToolbarButton icon={<AddIcon />} onClick={() => editItem(null)}>New activity</ToolbarButton>
                        <ToolbarSearchBox defaultValue={searchText} onChange={value => setSearchText(value)} />
                    </>
                }
                endItems={
                    <>
                        {/* <ToolbarButton variant="text" endIcon={<ExpandMoreIcon />}>All activities</ToolbarButton> */}
                        <ToolbarIconButton tooltip="Refresh" onClick={() => sessionQuery.refetch()}><RefreshIcon /></ToolbarIconButton>
                        {/* <ToolbarIconButton><MoreVertIcon /></ToolbarIconButton> */}
                    </>
                } />

            <ActivityGrid 
                items={sessionQuery.data.items}
                totalItems={sessionQuery.data.count}
                config={config}
                loading={sessionQuery.isLoading}
                onConfigChanged={async config => { console.log(config); setConfig(config); }} 
                onSelectItem={viewItem}
                onSaveItem={async (row: Activity, key: string, value: any) => {
                    UpdateActivity(event.id, row.id, { [key]: value });
                }}

                onItemContextMenu={(e, item) => {
                    return [
                        { label: 'View', onClick: () => viewItem(item), icon: <VisibilityIcon />},
                        { label: 'Edit', onClick: () => editItem(item), icon: <EditIcon />},
                        { type: 'divider' },
                        { label: 'Delete', onClick: () => deleteItem(item), icon: <DeleteIcon />}
                    ]
                }}

                //onViewItem={useCallback((item: Activity) => nav(AppRoutes.Event.Activities.Item(organization.slug, event.id, item.id)), [event.id, organization.slug, nav])}
                onViewItem={viewItem}
                onEditItem={editItem}
                onDeleteItem={deleteItem}
            />

            <EditActivityDrawer
                open={editDrawer.isOpen}
                onDismiss={editDrawer.close}
                onSave={() => { editDrawer.close(); sessionQuery.refetch(); }}
                activityId={selectedActivity}
            />

            <ViewActivityDrawer
                open={viewDrawer.isOpen}
                onDismiss={viewDrawer.close}
                activityId={selectedActivity}
                onSave={() => { viewDrawer.close(); sessionQuery.refetch(); }}
            />
        </>
    );
}
