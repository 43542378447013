
export default class User
{
    public name: string;
    public profilePhoto: string;
    public email: string;

    public static fromJson(accountJson: any): User
    {
        const newAccount = new User();
        newAccount.name = accountJson.name;
        newAccount.profilePhoto = accountJson.profilePhoto;
        newAccount.email = accountJson.email;

        return newAccount;
    }
}
