import { useContext, useState, useEffect } from "react";
import {Organization} from "../../dto/Organization";
import { Event } from "../../dto/Event";
import OrganizationContext from "../../context/OrganizationContext";
import EventContext from "../../context/EventContext";
import { Page } from "../../components/Page";
import { Header } from "../../components/Header";
import { Button, Chip, MenuItem, Paper, Stack, Tooltip } from "@mui/material";
import RegItem from "../../dto/RegItem";
import { Currency } from "../../utils/Currency";
import { RegItemDialog } from "../../dialogs/RegItemDialog";
import { Utils } from "../../utils/Utils";
import { QuickSearchToolbar } from "../../components/QuickSearchToolbar";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import { BM } from "../../api/BM";
import { PageToolbar, ToolbarButton, ToolbarIconButton, ToolbarSearchBox } from "../../components/PageToolbar";
import { Card } from "../../components/Card";
import { Form, Formik } from "formik";
import { BMTextField } from "../../components/Fields/BMTextField";
import { BMDateTimeField } from "../../components/Fields/BMDateTimeField";
import { BMTextArea } from "../../components/Fields/BMTextArea";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMDropdownField } from "../../components/Fields/BMDropdownField";
import { getTimeZones } from "../../utils/DateUtils";


export interface WebhookSettingsPage
{

}

export const WebhookSettingsPage = (props: WebhookSettingsPage) =>
{
    return (
        <div style={{ maxWidth: '640px', padding: '0 20px', margin: '40px auto' }}>
            <h3>Webhooks</h3>
            <hr />



            {/* <Formik
                initialValues={{
                    name: 'Fake Con 2021 (DEV)',
                    startTime: '2024-04-10T10:00:00',
                    endTime: '2024-04-10T18:00:00'
                }}    
                onSubmit={(values, formikHelpers) => {
                    console.log(values);
                }}
            >
            { formProps => (
                <Form>
                    <h3>General</h3>
                    <hr />

                    <BMTextField name="name" label="Name" layout="vertical" />
                    <BMTextArea name="description" label="Description" layout="vertical" />
                    <BMDateTimeField name="startTime" label="Start Time" layout="vertical" />
                    <BMDateTimeField name="endTime" label="End Time" layout="vertical" />

                    <h3>Region</h3>
                    <hr />
                    <BMTextField name="location" label="Location" layout="vertical" />
                    <BMDropdownField name="timezone" label="Time Zone" layout="vertical" options={
                        getTimeZones().map(tz => ({ value: tz, label: tz }))
                    } />
                    <BMDropdownField name="displayTimeZone" label="Displayed Time Zone" layout="vertical" options={[
                        { label: 'Show times in my local time zone', value: 'user' },
                        { label: 'Show times in the event\'s time zone', value: 'local' },
                    ]} />

                    <h3>Currency</h3>
                    <hr />
                    <BMDropdownField name="currency" label="Currency" layout="vertical" options={[
                        { label: 'Canadian Dollar (CAD)', value: 'CAD' },
                        { label: 'US Dollar (USD)', value: 'USD' },
                        { label: 'Euro (EUR)', value: 'EUR' },
                        { label: 'British Pound (GBP)', value: 'GBP' }
                    ]} />

                    <hr />

                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                        <BMSubmitButton>Update</BMSubmitButton>
                        <button>Cancel</button>
                    </div>
                </Form>
            )}
            </Formik> */}
        </div>
    );
}