import { useState } from "react";
import { Drawer, DrawerProps } from "../../components/Drawer";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Tab, Tabs } from "../../components/Tabs";
import { BMDisplayField } from "../../components/Fields/BMDisplayField";
import RegItem from "../../dto/RegItem";
import { Currency } from "../../utils/Currency";
import { MessageBar } from "../../components/MessageBar";

import "./ViewRegTierDrawer.scss";

export interface ViewRegTierDrawerProps extends DrawerProps
{
    regItemId: string;
}

export const ViewRegTierDrawer = (props: ViewRegTierDrawerProps) => 
{
    const { open, onSave, onDismiss, regItemId } = props;
    const { eventId, isLoaded } = useCurrentContext();
    const queryClient = useQueryClient();
    const api = useAPI();
    const [error, setError] = useState<string>('');

    const regItemQuery = useQuery(QueryKeys.RegItems.GetById(eventId, regItemId), () => api.events.getById(eventId).regItems.getById(regItemId).get(), { enabled: isLoaded && !!eventId && !!regItemId, initialData: null });
    const regItem = regItemQuery.data;

    return (
        <Drawer title={regItem?.title || ''} loading={regItemQuery.isFetching} {...props}>
            <div className="view-regtier-drawer">
                {/* <div className="activity-drawer-header">
                    <h1>{activity?.title}</h1>
                    <div className="subtle">{activity?.location}</div>
                </div> */}
                {/* <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
                    <button>Edit</button>
                </div> */}

                {
                    regItem?.active === false &&
                    <MessageBar message="This registration item is disabled" type="warning" />
                }

                <Tabs>
                    <Tab label="Details">
                        <ActivityDetailsTab eventId={eventId} regItem={regItem} />
                    </Tab>
                    {/* <Tab label="Checked-in">
                        <CheckedInTab eventId={eventId} regItem={regItem} />
                    </Tab> */}
                </Tabs>
            </div>
        </Drawer>
    );


// <Tab label="Activity Log">
//     <ActivityLogTab eventId={eventId} regItem={regItem} />
// </Tab>
}

const ActivityDetailsTab = (props: { eventId: string, regItem: RegItem }) =>
{
    const api = useAPI();
    const { eventId, regItem } = props;

    //const startTimeStr = isValid(new Date(regItem?.startTime)) ? format(new Date(activity?.startTime), 'PP p') : '';
    //const endTimeStr = isValid(new Date(activity?.endTime)) ? format(new Date(activity?.endTime), 'PP p') : '';

    return (
        <div className="details-tab">
            <div className="details-tab-grid" style={{  }}>
                <BMDisplayField label="Title" value={regItem?.title} layout="vertical" />
                <BMDisplayField label="Group" value={regItem?.group} layout="vertical" />
                <BMDisplayField label="Price" value={Currency.format(regItem?.price)} layout="vertical" />
                <BMDisplayField label="Description" value={regItem?.description} layout="vertical" />
            </div>
        </div>
    );
}

const CheckedInTab = (props: { eventId: string, regItem: RegItem }) =>
{
    return (
        <div className="details-tab">
            Checked-in tab
        </div>
    );
}

const ActivityLogTab = (props: { eventId: string, regItem: RegItem }) =>
{
    return (
        <div className="details-tab">
            Activity Tab
        </div>
    );
}