import { format, isDate, isValid, toDate, parse } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { CellProps } from "./CellProps";
import { usePrevious } from "../usePrevious";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";

import "./DateTimeCell.scss";
import { GridCell } from "./GridCell";
import { formatInTimeZone } from "date-fns-tz";

export interface DateTimeCellProps extends CellProps<string>
{
    format?: 'default' | 'friendly' | 'relative';
    timeZone?: string;
}

export const DateTimeCell = (props: DateTimeCellProps) =>
{
    const { value = null, selected, onSave, readOnly, format: dateFormat = 'friendly' } = props;
    const [internalValue, setInternalValue] = useState(value); 
    const [dirty, setDirty] = useState(false);
    const prevSelected = usePrevious(selected);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);

    const saveValue = useCallback(async (value: string) => {
        if(dirty)
        {
            await Promise.resolve(onSave?.(value));
            setDirty(false);
        }
        setEditing(false);
    }, [onSave, dirty]);

    useAsyncEffect(async () => {
        if(prevSelected && !selected)
            await saveValue(internalValue);
    }, [prevSelected, selected, saveValue]);

    const onDoubleClick = useCallback(() => {
        if(!readOnly)
        {
            setEditing(true);
            setTimeout(() => inputRef.current?.showPicker());
        }
    }, [inputRef]);

    const onDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(parse(e.target.value.replace('T', ' '), 'yyyy-MM-dd HH:mm', new Date()).toISOString());
        setDirty(true);
    }, []);

    let inputValue = null;
    let textVal = 'Invalid date';
    let tooltipVal = textVal;

    if(isValid(new Date(internalValue)))
    {
        inputValue = format(new Date(internalValue), 'yyyy-MM-dd HH:mm').replace(' ', 'T');
    }
    else
    {
        console.error('[DateTimeCell] Value must be a string. Received', typeof internalValue);
    }

    if(isValid(new Date(internalValue)))
    {
        textVal = format(new Date(internalValue), dateFormat === 'friendly' ? 'PP p' : 'P p' );
        tooltipVal = formatInTimeZone(new Date(internalValue), Intl.DateTimeFormat().resolvedOptions().timeZone, 'MMM d yyyy, h:mm:ss b (z)');
    }

    return (
        <GridCell className="cell-date" onDoubleClick={onDoubleClick}>
            {!editing && <div title={tooltipVal} className="date-display">{textVal}</div>}
            {editing && (
                // Formatted as YYYY-MM-DDThh:mm in local time zone
                <input ref={inputRef} type="datetime-local" value={inputValue} onChange={onDateChange} />
            )}
        </GridCell>
    );
};