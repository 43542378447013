import ListResponse from "./ListResponse";
import BMError from "./BMError";
import axios, { AxiosError, AxiosInstance } from 'axios';

interface CachedItem
{
    item: any;
    expiry: number;
}

export interface QueryParams
{
    search?: string;
    top?: number;
    skip?: number;
    orderBy?: { field: string, direction: 'asc' | 'desc' };
    select?: string[];
    expand?: string[];
    includeCount?: boolean;
}

export class Query<T>
{
    public readonly resourceUrl: string;
    private shouldUseCache: boolean = false;
    private authInitialized: boolean;
    private idToken: string;
    private searchParams: URLSearchParams;
    private deserializer: (json: any) => T;
    private axiosInstance: AxiosInstance;

    private static _cachedRequests: {[key: string]: CachedItem} = {};
    public static authProvider: () => Promise<string> = null;
    
    public static useCaching = true;

    constructor(resourceUrl: string, deserializer?: (json: any) => T)
    {
        this.resourceUrl = resourceUrl;
        this.searchParams = new URLSearchParams();
        this.deserializer = deserializer;
        this.authInitialized = false;
        this.idToken = null;

        this.axiosInstance = axios.create({
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json'
            },
            transformResponse: (data) => {
                try
                {
                    let parsedData = JSON.parse(data);
                    if(parsedData.status)
                        return parsedData;

                    if(Array.isArray(parsedData.items))
                        parsedData.items = deserializer ? parsedData.items.map(v => deserializer(v)) : parsedData.items;
                    else
                        parsedData = deserializer ? deserializer(parsedData) : parsedData;
                    return parsedData;
                }
                catch(error)
                {
                    console.error(error);
                    return data;
                }
            }
        });

        this.axiosInstance.interceptors.request.use(async request => {
            if(Query.authProvider) {
                request.headers.Authorization = `Bearer ${await Query.authProvider()}`;
            }
            return request;
        });

        // const auth = getAuth();
        // onAuthStateChanged(auth, async (user) => 
        // {
        //     if(user)
        //         this.idToken = await user.getIdToken();
        //     this.authInitialized = true;
        // });
    }

    public async get(params?: QueryParams): Promise<ListResponse<T>>
    {
        if(params)
            this.processParams(params);
        
        try { return (await this.axiosInstance.get<ListResponse<T>>(this.makeUrl(this.resourceUrl), { params: this.searchParams })).data; }
        catch(error) { this.handleError(error); }
    }

    public async getById(id: string): Promise<T>
    {
        // const response = await fetch(this.url(`${this.resourceUrl}/${id}`), await this.requestOptions('GET'));
        // return this.handleResponse<T>(response, this.deserializer);

        try { return (await this.axiosInstance.get<T>(this.makeUrl(`${this.resourceUrl}/${id}`), { params: this.searchParams })).data; }
        catch(error) { this.handleError(error); }

        //return this.performFetch<T>(`${this.resourceUrl}/${id}`, 'GET', this.deserializer);
    }

    public async add(body: Partial<T>): Promise<T>
    {
        try { return (await this.axiosInstance.post<T>(this.makeUrl(this.resourceUrl), body, { params: this.searchParams })).data; }
        catch(error) { this.handleError(error); }

        //const response = await fetch(this.url(this.resourceUrl), await this.requestOptions('POST', body));
        //return this.handleResponse<T>(response, this.deserializer);
    }

    private handleError(error: any)
    {
        let errorCode = 'unknown_error';
        let errorMessage = 'Unknown error';

        if(error.response)
        {
            // Response was received, but status code was not 2XX
            throw new BMError(
                error.response.data?.code || 'unknown_error', 
                error.response.data?.message,
                error.response.data?.status
            );
        }
        if(error.request)
        {
            // Request was made but no response was received
            throw new BMError(errorCode, errorMessage, null);
        }
        else
        {
            // Generic error occurred
            throw new BMError(errorCode, errorMessage, null);
        }
    }

    public async update(id: string, body: Partial<T>): Promise<T>
    {
        try { return (await this.axiosInstance.patch<T>(this.makeUrl(`${this.resourceUrl}/${id}`), body, { params: this.searchParams })).data; }
        catch(error) { this.handleError(error); }

        //const response = await fetch(this.url(`${this.resourceUrl}/${id}`), await this.requestOptions('PATCH', body));
        //return this.handleResponse<T>(response, this.deserializer);
    }

    public async delete(id: string): Promise<T>
    {
        try { return (await this.axiosInstance.delete<T>(this.makeUrl(`${this.resourceUrl}/${id}`), { params: this.searchParams })).data; }
        catch(error) { this.handleError(error); }

        //const response = await fetch(this.url(`${this.resourceUrl}/${id}`), await this.requestOptions('DELETE'));
        //return this.handleResponse<T>(response, this.deserializer);
    }

    public async action<K>(actionName: string, method: 'GET' | 'POST', body?: any)
    {
        try
        {
            if(method === 'GET')
                return (await this.axiosInstance.get<K>(this.makeUrl(`${this.resourceUrl}/${actionName}`), { params: this.searchParams })).data;
            else if(method === 'POST')
                return (await this.axiosInstance.post<K>(this.makeUrl(`${this.resourceUrl}/${actionName}`), body, { params: this.searchParams })).data;
        }
        catch(error) { this.handleError(error); }

        //const response = await fetch(this.url(`${this.resourceUrl}/${actionName}`), await this.requestOptions(method, body));
        //return this.handleResponse<K>(response);
    }

    public async actionById<K>(id: string, actionName: string, method: 'GET' | 'POST', body?: any): Promise<any>
    {
        try
        {
            if(method === 'GET')
                return (await this.axiosInstance.get<K>(this.makeUrl(`${this.resourceUrl}/${id}/${actionName}`), { params: this.searchParams })).data;
            else if(method === 'POST')
                return (await this.axiosInstance.post<K>(this.makeUrl(`${this.resourceUrl}/${id}/${actionName}`), body, { params: this.searchParams })).data;
        }
        catch(error) { this.handleError(error); }

        //const response = await fetch(this.url(`${this.resourceUrl}/${id}/${actionName}`), await this.requestOptions(method, body));
        //return this.handleResponse<K>(response);
    }

    public select(...fields: string[])
    {
        this.searchParams.set('select', fields.join(','));
        return this;
    }

    public expand(...fields: string[])
    {
        this.searchParams.set('expand', fields.join(','));
        return this;
    }

    public skip(count: number)
    {
        this.searchParams.set('skip', count.toString());
        return this;
    }

    public top(maxItems: number)
    {
        this.searchParams.set('top', maxItems.toString());
        return this;
    }

    public search(searchString: string)
    {
        this.searchParams.set('search', searchString);
        return this;
    }

    public orderBy(field: string, direction: 'asc' | 'desc')
    {
        this.searchParams.set('orderby', `${field} ${direction}`);
        return this;
    }

    public includeCount(include: boolean = true)
    {
        this.searchParams.set('inlineCount', include ? 'true' : 'false');
        return this;
    }

    public param(name: string, value: string)
    {
        this.searchParams.set(name, value);
        return this;
    }

    public useCache(useCache: boolean)
    {
        this.shouldUseCache = useCache;
        return this;
    }

    private processParams(params: QueryParams)
    {
        if(params.top) { this.top(params.top); }
        if(params.skip) { this.skip(params.skip); }
        if(params.search) { this.search(params.search); }
        if(params.orderBy) { this.orderBy(params.orderBy.field, params.orderBy.direction); }
        if(params.expand) { this.expand(...params.expand); }
        if(params.select) { this.select(...params.select); }
        if(params.includeCount) { this.includeCount(params.includeCount); }
    }
    
    private makeUrl(url: string)
    {
        const retval = url + (this.searchParams.toString().length > 0 ? `?${this.searchParams.toString()}` : '');
        this.searchParams = new URLSearchParams();
        return retval;
    }
}