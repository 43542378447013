import { useAuth0 } from "@auth0/auth0-react"
import { useMemo } from "react";
import { BM } from "../api/BM"
import { useAuth } from "@clerk/clerk-react";

const {
    REACT_APP_CLERK_TOKEN_TEMPLATE: clerkTokenTemplate,
} = process.env;

export const useAPI = () =>
{
    //const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();
    const { isLoaded, isSignedIn, getToken } = useAuth();

    const api = useMemo(() => 
    {
        if(isLoaded && isSignedIn)
        {
            return new BM({
                apiUrl: process.env['REACT_APP_API_URL'],
                authProvider: async () => {
                    return await getToken({ template: clerkTokenTemplate });
                    //return getAccessTokenSilently({ audience: process.env['REACT_APP_AUTH_AUDIENCE'] });
                } 
            });
        }
        else
        {
            return null;
        }

    }, [isLoaded, isSignedIn, getToken]);

    return api;
};
