
export class AppEvent
{
    eventName: string;
    payload: any;
}

class InternalAppDispatcher
{
    private callbacks: any;

    constructor()
    {
        this.callbacks = {};
    }

    public on(eventName: string, callback: (e: AppEvent) => void, context?: any)
    {
        let listeners: Array<any> = this.callbacks[eventName] = this.callbacks[eventName] || [];

        listeners.push({
            callback: callback,
            context: context || null
        });

        this.callbacks[eventName] = listeners;
    }

    public off(eventName: string, callback: Function)
    {
        var listeners: Array<Function> = [];
        listeners = this.callbacks[eventName] = this.callbacks[eventName] || [];

        let index = -1;
        listeners.forEach((item: any) => 
        {
            if (item.callback === callback)
                index = listeners.indexOf(item);
        });

        if (index >= 0)
            listeners.splice(index, 1);

        this.callbacks[eventName] = listeners;
    }

    public emit(event: AppEvent)
    {
        let listeners: Array<any> = this.callbacks[event.eventName];

        if (listeners)
        {
            listeners.forEach((item: { callback: (e: AppEvent) => void, context: any }) => 
            {
                item.callback.call(item.context, event);
            });
        }
    }
}

export let AppDispatcher = new InternalAppDispatcher();
export default AppDispatcher;