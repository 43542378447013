import React, { useEffect, useCallback } from "react";

import "./GridMenuItem.scss";

export interface GridMenuItemProps {
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    icon?: JSX.Element;
    disabled?: boolean;
};

export const GridMenuItem = (props: GridMenuItemProps) => {

    const { children, disabled = false, onClick, icon } = props;

    let iconElement = <span></span>;
    if(icon)
        iconElement = React.cloneElement(icon, { style: { fontSize: '18px !important' } });
        //iconElement = React.cloneElement(icon, { fontSize: "inherit", style: { fontSize: '18px' } });

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if(!disabled)
            onClick?.(e);
    }, [onClick])

    return (
        <div className={['grid-menu-item', ...[disabled ? 'disabled' : []]].join(' ')} onClick={handleClick}>
            {/* {Icon && <Icon fontSize="inherit" style={{ fontSize: '18px' }} />} */}
            {/* {!Icon && <span></span>} */}
            {icon}
            {children}
        </div>
    );
};