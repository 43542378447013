import CheckIn from "../dto/CheckIn";
import BaseQuery from "./BaseQuery";
import { Query } from "./Query";

export default class CheckinQuery extends BaseQuery<CheckIn>
{
    constructor(rootUrl?: string)
    {
        super((rootUrl ?? '') + '/checkins');
        this.query = new Query<CheckIn>(this.rootUrl, CheckIn.fromJson);
    }

    public getById(id: string)
    {
        const retval = {
            update: (data: Partial<CheckIn>) => { return this.update(id, data) },
            get: () => { return this.getOne(id); },
            delete: () => { return this.delete(id); },
            select: (...fields: string[]) => {
                this.select(...fields);
                return retval;
            },
            expand: (...fields: string[]) => {
                this.expand(...fields);
                return retval;
            }
        };
        return retval;
    }
}