import { useField, useFormikContext } from "formik";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { BMFieldProps } from "./BMFieldProps";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

export interface SelectFieldOption
{
    label: string;
    value: string;
}

export interface BMMultiSelectFieldProps extends BMFieldProps
{
    options: SelectFieldOption[] | ((values: string[]) => Promise<SelectFieldOption[]>);
    search?: (searchString: string) => SelectFieldOption[] | Promise<SelectFieldOption[]>;
}

export const BMMultiSelectField = (props: BMMultiSelectFieldProps) =>
{
    const { name, label, description, disabled = false, layout = 'horizontal', style, required, options, search } = props;
    const [field, meta, helpers] = useField<any[]>(name);
    const context = useFormikContext();

    const fieldStyle = Object.assign({ gridArea: name }, style);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const [timeoutId, setTimeoutId] = useState<number>(0);
    const [internalOptions, setInternalOptions] = useState<SelectFieldOption[]>([]);
    const [internalSearchResults, setInternalSearchResults] = useState<SelectFieldOption[]>([]);

    useAsyncEffect(async () => {
        if (options instanceof Function)
        {
            const result = await options(field.value);
            setInternalOptions(result.map(x => ({ label: x.label, value: x.value })));
        }
        else
        {
            setInternalOptions(options.map(x => ({ label: x.label, value: x.value })));
        }
    }, [options]);

    useAsyncEffect(async () => {
        clearTimeout(timeoutId);
        setTimeoutId(setTimeout(async () => {
            const result = await (search?.(searchString) || internalOptions.filter(x => x.label.toLowerCase().includes(searchString.toLowerCase())));
            setInternalSearchResults(result
                .filter(x => !field.value.includes(x.value))
                .map(x => ({ label: x.label, value: x.value }))
            );
        }, 250) as any);
    }, [searchString, field.value]);

    const handleRemove = useCallback((value: string) => {
        helpers.setValue(field.value.filter(x => x !== value));
    }, [field.value, helpers]);

    const selectedOptions = (field.value || []).map((value: string) => {
        const label = internalOptions.find(x => x.value === value)?.label || value;
        return <SelectFieldItem key={value} label={label} value={value} onSelect={handleRemove} />
    });

    return (
        <div className={`bm-field ${layout}`} style={fieldStyle}>
            { label && <label>{label} {required ? <span className="required"> *</span> : null}</label> }
            <div style={{ flex: 1 }}>
                <div className="bm-field-area" style={{ flex: 1 }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                        {selectedOptions}
                    </div>
                    <input type="text" value={searchString} onChange={e => setSearchString(e.target.value)}></input>

                    <SelectFieldMenu options={internalSearchResults} onSelect={(value) => {
                        setSearchString('');
                        helpers.setValue([...field.value, value]);
                    }} />
                </div>
                {
                    description &&
                    <div className="subtle">{description}</div>
                }
                {
                    meta.touched && !!meta.error &&
                    <div className="error">{meta.error}</div>
                }
            </div>
        </div>
    );
};

export interface SelectFieldItemProps 
{
    label: string;
    value: string;
    onSelect?: (value: string) => void;
}

export const SelectFieldItem = (props: SelectFieldItemProps) => 
{
    const { label, value, onSelect } = props;

    const handleSelect = useCallback(() => {
        onSelect?.(value);
    }, [onSelect, value]);

    return (
        <button className="small" onClick={handleSelect}>
            <span style={{ fontWeight: 400 }}>{label}</span>
            <span style={{ marginLeft: '5px' }}>X</span>
        </button>
    );
}

export const SelectFieldMenu = (props: { options: SelectFieldOption[], onSelect: (value: string) => void }) =>
{
    const { options, onSelect } = props;

    return (
        <div>
            {
                options.map(option => (
                    <div key={option.value} onClick={() => onSelect(option.value)}>{option.label}</div>
                ))
            }
        </div>
    );
}