import { useField, useFormikContext } from "formik";
import { CSSProperties } from "react";
import { BMFieldProps } from "./BMFieldProps";

export interface BMDropdownFieldProps extends BMFieldProps
{
    options: { label: string, value: string, disabled?: boolean }[];
}

export const BMDropdownField = (props: BMDropdownFieldProps) =>
{
    const { name, label, description, disabled = false, layout = 'horizontal', style, required } = props;
    const [field, meta, helpers] = useField(name);
    const context = useFormikContext();

    const fieldStyle = Object.assign({ gridArea: name }, style);

    return (
        <div className={`bm-field ${layout}`} style={fieldStyle}>
            { label && <label>{label} {required ? <span className="required"> *</span> : null}</label> }
            <div style={{ flex: 1 }}>
                <select 
                    name={name}
                    disabled={disabled || context.isSubmitting}
                    required={required}
                    {...field}>
                    { props.options.map(option => (
                        <option key={option.value} value={option.value} disabled={option.disabled || false}>
                            {option.label}
                        </option>
                    ))
                    }
                </select>
                {
                    description &&
                    <div className="subtle">{description}</div>
                }
                {
                    meta.touched && !!meta.error &&
                    <div className="error">{meta.error}</div>
                }
            </div>
        </div>
    );
};
