import { useCallback, useEffect, useState } from "react";
import { Drawer, DrawerProps } from "../../components/Drawer";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { BMTextField } from "../../components/Fields/BMTextField";
import { BMDateField } from "../../components/Fields/BMDateField";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMButton } from "../../components/Fields/BMButton";
import { Utils, copyInto } from "../../utils/Utils";
import * as Yup from "yup";

import "./EditAttendeeDrawer.scss";
import Attendee from "../../dto/Attendee";
import { BMTextArea } from "../../components/Fields/BMTextArea";

export interface AttendeeDrawerProps extends DrawerProps
{
    attendeeId: string;
    onSave?: (attendee: Attendee) => void;
}

export const EditAttendeeDrawer = (props: AttendeeDrawerProps) => 
{
    const { open, onSave, onDismiss, attendeeId } = props;
    const { event, isLoaded } = useCurrentContext();
    const queryClient = useQueryClient();
    const api = useAPI();
    const [error, setError] = useState<string>('');

    const fieldsQuery = useQuery(QueryKeys.Events.Fields.All(event.id), () => api.events.getById(event.id).fields.get(), { enabled: isLoaded, initialData: null });
    const attendeeQuery = useQuery(QueryKeys.Attendees.GetById(event.id, attendeeId), () => api.events.getById(event.id).attendees.getById(attendeeId).get(), { enabled: isLoaded && !!attendeeId, initialData: null });
    const updateAttendee = useMutation(async (attendeeData: Partial<Attendee>) => {
        const retval = await api.events.getById(event.id).attendees.getById(attendeeId).update(attendeeData);
        queryClient.invalidateQueries(QueryKeys.Attendees.GetById(event.id, attendeeId));
        return retval;
    });
    const createAttendee = useMutation(async (attendeeData: Partial<Attendee>) => {
        const retval = await api.events.getById(event.id).attendees.add(attendeeData);
        queryClient.invalidateQueries(QueryKeys.Attendees.GetById(event.id, retval.id));
        return retval;
    });

    const onSubmit = useCallback(async (values: FormikValues, formikHelpers: FormikHelpers<any>) =>
    {
        try
        {
            let retval: Attendee;
            if(attendeeId)
                retval = await updateAttendee.mutateAsync(values);
            else
                retval = await createAttendee.mutateAsync(values);

            onDismiss();
            onSave(retval);
        }
        catch(e: any)
        {
            setError(e.message);
        }
    }, [attendeeQuery.data, updateAttendee.mutateAsync, onDismiss, onSave]);

    const schema = Yup.object({
        firstName: Yup.string().required('First name is required').trim(),
        lastName: Yup.string().required('Last name is required').trim(),
        email: Yup.string().required('Email is required').email('Email address is not valid').trim(),
        phone: Yup.string().optional().trim(),
        birthdate: Yup.date().optional(),
    });

    const customFields = fieldsQuery.data ? [...fieldsQuery.data.items].sort(Utils.sortByProperty('name', true)) : [];
    let initialData = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthdate: '',
        fields: {}
    }
    customFields.forEach(field => {
        initialData.fields[field.name] = '';
    });

    let title = ''
    if(attendeeId && attendeeQuery.data)
    {
        initialData = copyInto(initialData, attendeeQuery.data);
        title = `${initialData.firstName} ${initialData.lastName}`;
    }

    return (
        <Drawer title={attendeeId ? title : 'New Attendee'} loading={fieldsQuery.isFetching || (attendeeQuery.isFetching && !!attendeeId)} {...props}>
            <Formik 
                initialValues={initialData} 
                onSubmit={onSubmit}
                validationSchema={schema}
                enableReinitialize>
                { formProps => (
                    <Form className="attendee-drawer">
                        <h4>Basic Information</h4>
                        <BMTextField name="firstName" label="First Name" autoComplete="off" required />
                        <BMTextField name="lastName" label="Last Name" autoComplete="off" />
                        <BMTextField name="email" label="Email" autoComplete="off" required type="email" />
                        <BMTextField name="phone" label="Phone Number" autoComplete="off" />
                        <BMDateField name="birthdate" label="Date of Birth" autoComplete="off" />
                        <BMTextArea name="notes" label="Notes" /> 
                        <hr />
                        <h4>Custom Fields</h4>
                        {
                            customFields.map(field => {
                                switch(field.type)
                                {
                                    case 'text':
                                        return (
                                            <BMTextField 
                                                key={`fields.${field.name}`}
                                                name={`fields.${field.name}`}
                                                label={field.name}
                                                autoComplete="off"
                                            />
                                        );
                                        break;
                                    case 'number':
                                        return (
                                            <BMTextField
                                                type="number"
                                                key={`fields.${field.name}`}
                                                name={`fields.${field.name}`}
                                                label={field.name}
                                                autoComplete="off"
                                            />
                                        );
                                        break;
                                }
                            })
                        }
                        <hr />
                        {
                            error && <div className="error">{error}</div>
                        }
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <BMSubmitButton>{attendeeId ? 'Save' : 'Create'}</BMSubmitButton>
                            <BMButton onClick={onDismiss}>Cancel</BMButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
}
