import "./MessageBar.scss";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export interface MessageBarProps
{
    type: 'info' | 'success' | 'warning' | 'error';
    message: string;
}

export const MessageBar = (props: MessageBarProps) =>
{
    const { type, message } = props;

    return (
        <div className={`message-bar ${type}`}>
            <WarningAmberIcon />
            <span className="message">{message}</span>
        </div>
    );
}