import { Event, fromJson } from "./Event";
import Registration from "./Registration";
import RegItem from "./RegItem";

export interface KeyValue 
{
    //fieldId: string,
    label: string,
    value: string
};

export interface Address 
{
    address1: string,
    address2?: string,
    city: string,
    region?: string,
    country: string,
    postalCode: string
};

export default class Attendee
{
    public id: string;
    public event: Event | string;

    public name: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public company: string;
    public jobTitle: string;
    public birthdate: string;

    public attendeeNumber: string;

    public fields: { [key: string]: string | number | boolean };

    public customFields: KeyValue[];

    public addresses: {
        home?: Address,
        work?: Address,
        shipping?: Address
    };

    public contactMethods: {
        twitter: string,
        telegram: string,
        discord: string
    };

    public notes: string;

    public archived: boolean;
    public createdAt?: Date;
    public updatedAt?: Date;

    public cards: string[];

    public registration: Registration;

    public registrationItems: string[] | RegItem[];

    constructor()
    {
        this.customFields = [];
        this.registrationItems = [];
        this.contactMethods = {
            twitter: '',
            telegram: '',
            discord: ''
        };
    }

    public getName()
    {
        return (this.firstName || '') + (this.lastName ? ' ' + this.lastName : '');
    }

    public PreferredName()
    {
        if(this.name)
            return this.name
        else
            return (this.firstName || '') + (this.lastName ? ' ' + this.lastName : '');
    }

    public static fromJson(attendeeJson: any): Attendee
    {
        const newAttendee = new Attendee();
        newAttendee.id = attendeeJson.id || attendeeJson._id;
        newAttendee.event = attendeeJson.event;

        newAttendee.name = attendeeJson.name || '';
        newAttendee.firstName = attendeeJson.firstName || '';
        newAttendee.lastName = attendeeJson.lastName || '';
        newAttendee.email = attendeeJson.email || '';
        newAttendee.phone = attendeeJson.phone || '';
        newAttendee.company = attendeeJson.company || '';
        newAttendee.jobTitle = attendeeJson.jobTitle || '';
        newAttendee.birthdate = attendeeJson.birthdate || '';

        newAttendee.attendeeNumber = attendeeJson.attendeeNumber || null;

        newAttendee.customFields = attendeeJson.customFields;
        newAttendee.fields = attendeeJson.fields;
        newAttendee.addresses = attendeeJson.addresses || { home: null, work: null };

        newAttendee.notes = attendeeJson.notes;

        newAttendee.archived = attendeeJson.archived;
        newAttendee.createdAt = new Date(attendeeJson.createdAt);
        newAttendee.updatedAt = new Date(attendeeJson.updatedAt);

        newAttendee.cards = attendeeJson.cards;

        newAttendee.contactMethods.twitter = attendeeJson.contactMethods?.twitter;
        newAttendee.contactMethods.telegram = attendeeJson.contactMethods?.telegram;
        newAttendee.contactMethods.discord = attendeeJson.contactMethods?.discord;

        if(typeof attendeeJson.event === 'object' && attendeeJson.event != null)
            newAttendee.event = fromJson(attendeeJson.event);
        else
            newAttendee.event = attendeeJson.event;

        //if(typeof attendeeJson.registration === 'object' && attendeeJson.registration != null))
        if(typeof attendeeJson.registration === 'object')
        {
            //newAttendee.registration = Registration.FromJson(attendeeJson.registration[0]);
            newAttendee.registration = attendeeJson.registration;
        }
        else
        {
            newAttendee.registration = null;
        }

        // if(Array.isArray(attendeeJson.registrationItems) && attendeeJson.registrationItems.length > 0 && typeof attendeeJson.registrationItems[0] === 'object')
        // {
        //     newAttendee.registrationItems = attendeeJson.registrationItems.map((i) => RegItem.fromJson(i));
        // }
        // else if(attendeeJson.registrationItems)
        // {
        //     newAttendee.registrationItems = attendeeJson.registrationItems;
        // }

        newAttendee.registrationItems = attendeeJson.registrationItems;

        return newAttendee;
    }

    // public getRegistrationItems() : RegItem[]
    // {
    //     let retval: RegItem[] = [];
    //     let items: any = this.registrationItems;

    //     if(items && items.length > 0 && typeof items[0] === 'object')
    //     {
    //         retval = items.map((i: any) => { return RegItem.fromJson(i); });
    //     }

    //     return retval;
    // }

    public static customFieldValue(attendee: Attendee, fieldName: string)
    {
        let retval: string = null;
        if(attendee.customFields)
        {
            const keyValue = attendee.customFields.find(f => f.label === fieldName);
            if(keyValue)
                retval = keyValue.value;
        }

        return retval;
    }

    public static registrationLevel(attendee: Attendee)
    {
        let retval: string = '';
        if(attendee.registrationItems && attendee.registrationItems.length > 0 && typeof attendee.registrationItems[0] === 'object')
        {
            const items: any[] = attendee.registrationItems;
            const item = items.find(i => {
                return i.type === 'registration';
            });

            retval = item.title;
        }

        return retval;
    }

    public getCustomField(fieldName: string)
    {
        let retval: KeyValue = null;

        if(this.customFields)
        {
            retval = this.customFields.find(f => f.label === fieldName) || null;
        }

        return retval;
    }

    public statuses()
    {
        // TODO: check birthdate for minor flag

        // TODO: check for staff flag, volunteer flag?

        // TODO: check for not registered?
    }
}
