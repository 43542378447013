import { useCallback, useEffect, useState } from "react";
import { Drawer, DrawerProps } from "../../components/Drawer";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { BMTextField } from "../../components/Fields/BMTextField";
import { BMDateField } from "../../components/Fields/BMDateField";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMButton } from "../../components/Fields/BMButton";
import { Utils, copyInto } from "../../utils/Utils";
import * as Yup from "yup";

import "./EditActivityDrawer.scss";
import { Activity } from "../../dto/Activity";
import { BMTextArea } from "../../components/Fields/BMTextArea";
import { BMDateTimeField } from "../../components/Fields/BMDateTimeField";

export interface ActivityDrawerProps extends DrawerProps
{
    activityId: string;
    onSave?: (activity: Activity) => void;
}

export const EditActivityDrawer = (props: ActivityDrawerProps) => 
{
    const { open, onSave, onDismiss, activityId } = props;
    const { event, isLoaded } = useCurrentContext();
    const queryClient = useQueryClient();
    const api = useAPI();
    const [error, setError] = useState<string>('');

    const fieldsQuery = useQuery(QueryKeys.Events.Fields.All(event.id), () => api.events.getById(event.id).fields.get(), { enabled: isLoaded, initialData: null });
    const activityQuery = useQuery(QueryKeys.Activities.Id(event.id, activityId), () => api.events.getById(event.id).activities.getById(activityId).get(), { enabled: isLoaded && !!activityId, initialData: null });
    const updateActivity = useMutation(async (activityData: Partial<Activity>) => {
        const retval = await api.events.getById(event.id).activities.getById(activityId).update(activityData);
        queryClient.invalidateQueries(QueryKeys.Activities.Id(event.id, activityId));
        return retval;
    });
    const createActivity = useMutation(async (activityData: Partial<Activity>) => {
        const retval = await api.events.getById(event.id).activities.add(activityData);
        queryClient.invalidateQueries(QueryKeys.Activities.Id(event.id, retval.id));
        return retval;
    });

    useEffect(() => {
        if(open)
        {
            if(activityId)
                activityQuery.refetch();
            setError('');
        }
    }, [open]);

    const onSubmit = useCallback(async (values: FormikValues, formikHelpers: FormikHelpers<any>) =>
    {
        console.log(values);
        
        try
        {
            let retval: Activity;
            if(activityId)
                retval = await updateActivity.mutateAsync(values);
            else
                retval = await createActivity.mutateAsync(values);

            onDismiss();
            onSave(retval);
        }
        catch(e: any)
        {
            setError(e.message);
        }
    }, [activityQuery.data, updateActivity.mutateAsync, onDismiss, onSave]);

    const schema = Yup.object({
        title: Yup.string().required('Name is required').trim(),
        type: Yup.string().trim(),
        location: Yup.string().trim(),
        startTime: Yup.date().optional(),
        endTime: Yup.date().optional(),
    });

    let initialData = {
        title: '',
        type: '',
        location: '',
        startTime: new Date().toString(),
        endTime: new Date().toString(),
        description: ''
    }

    if(activityId && activityQuery.data)
        initialData = copyInto(initialData, activityQuery.data);

    return (
        <Drawer title={activityId ? 'Edit Session' : 'New Session'} loading={fieldsQuery.isFetching || (activityQuery.isFetching && !!activityId)} {...props}>
            <Formik 
                initialValues={initialData} 
                onSubmit={onSubmit}
                validationSchema={schema}
                enableReinitialize>
                { formProps => (
                    <Form className="activity-drawer">
                        <h4>Session Information</h4>
                        <BMTextField name="title" label="Name" autoComplete="off" required />
                        <BMTextField name="type" label="Type" />
                        <BMTextField name="location" label="Location" />
                        <BMDateTimeField name="startTime" label="Start Time" required />
                        <BMDateTimeField name="endTime" label="End Time" required />
                        <BMTextArea name="description" label="Description" />
                        <hr />
                        {
                            error && <div className="error">{error}</div>
                        }
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <BMSubmitButton>{activityId ? 'Save' : 'Create'}</BMSubmitButton>
                            <BMButton onClick={onDismiss}>Cancel</BMButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
}
