import { Component, Children, cloneElement, useState } from "react";

import "./Tabs.scss";

export interface TabsProps
{
    style?: any;
    children: React.ReactNode;
}

export const Tabs = (props: TabsProps) => 
{
    const { style, children } = props;
    const [index, setIndex] = useState(0);

    const childArray = Children.toArray(children);

    const tabButtons = Children.map(childArray, (child, i) =>
        cloneElement(child as React.ReactElement<any>, {
            onClick: () => setIndex(i),
            active: (i === index),
            index: index
        })
    );

    return (
        <div className="component-tabs">
            <div className="tabs">
                {tabButtons}
            </div>
            <div className="tab">
                { (childArray[index] as any)?.props.children }
            </div>
        </div>
    );
}

export interface TabProps
{
    label: string;
    style?: any;
    active?: boolean;
    index?: number;
    onClick?: () => void;
    children?: React.ReactNode;
}

export const Tab = (props: TabProps) =>
{
    const { label, active, onClick, index } = props;

    return (
        <button className={'tab' + (active ? ' active': '')} onClick={onClick}>
            {label}
        </button>
    );
}
