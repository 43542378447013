import { format, isDate, isValid, toDate, parse } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { CellProps } from "./CellProps";
import { usePrevious } from "../usePrevious";
import { DatePicker } from "@mui/lab";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";

import "./DateCell.scss";
import { GridCell } from "./GridCell";
import { formatInTimeZone } from "date-fns-tz";

export interface DateCellProps extends CellProps<string>
{
    format?: 'default' | 'friendly';
}

export const DateCell = (props: DateCellProps) =>
{
    const { value = '', selected, onSave, readOnly, format: dateFormat = 'friendly' } = props;
    const [internalValue, setInternalValue] = useState(value); // Formatted as YYYY-MM-DD in local time zone
    const [dirty, setDirty] = useState(false);
    const prevSelected = usePrevious(selected);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);

    const saveValue = useCallback(async (value: string) => {
        if(dirty)
        {
            await Promise.resolve(onSave?.(value) || '');
            setDirty(false);
        }
        setEditing(false);
    }, [onSave, dirty]);

    useAsyncEffect(async () => {
        if(prevSelected && !selected)
            await saveValue(internalValue);
    }, [prevSelected, selected, saveValue]);

    const onDoubleClick = useCallback(() => {
        if(!readOnly)
        {
            setEditing(true);
            setTimeout(() => inputRef.current?.showPicker());
        }
    }, [inputRef]);

    const onDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(e.target.value);
        setDirty(true);
    }, []);

    const parsedDate = parse(internalValue, 'yyyy-MM-dd', new Date());
    let textVal = internalValue?.length > 0 ? 'Invalid date' : '';
    let tooltipVal = textVal;

    if(isValid(parsedDate))
    {
        textVal = format(parsedDate, dateFormat === 'friendly' ? 'PP' : 'P' );
        tooltipVal = formatInTimeZone(parsedDate, Intl.DateTimeFormat().resolvedOptions().timeZone, 'MMM d yyyy, h:mm:ss b (z)');
    }

    return (
        <GridCell className="cell-date" onDoubleClick={onDoubleClick}>
            {!editing && <div className="date-display">{textVal}</div>}
            {editing && (
                <input ref={inputRef} type="date" value={internalValue} onChange={onDateChange} />
            )}
        </GridCell>
    );
};