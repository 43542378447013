import { Event } from "../dto/Event";
import ActivityQuery from "./ActivityQuery";
import AttendeeQuery from "./AttendeeQuery";
import RegItemQuery from "./RegItemQuery";
import RegTokenQuery from "./RegTokenQuery";
import BaseQuery from "./BaseQuery";
import { Field } from "../dto/Field";

export default class EventFieldsQuery extends BaseQuery<Field>
{
    constructor(rootUrl?: string)
    {
        super((rootUrl ?? '') + '/fields');
    }

    // public getById(id: string)
    // {
    //     return {
    //         ...super.getById(id),
    //         activities: new ActivityQuery(`${this.rootUrl}/${id}`),
    //         attendees: new AttendeeQuery(`${this.rootUrl}/${id}`),
    //         regItems: new RegItemQuery(`${this.rootUrl}/${id}`),
    //         regTokens: new RegTokenQuery(`${this.rootUrl}/${id}`)
    //     };
    // }
}