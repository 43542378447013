import { Event } from "../dto/Event";
import ActivityQuery from "./ActivityQuery";
import AttendeeQuery from "./AttendeeQuery";
import RegItemQuery from "./RegItemQuery";
import RegTokenQuery from "./RegTokenQuery";
import BaseQuery from "./BaseQuery";
import EventFieldsQuery from "./EventFieldsQuery";
import EventViewsQuery from "./EventViewsQuery";

export default class EventQuery extends BaseQuery<Event>
{
    constructor(rootUrl?: string)
    {
        super((rootUrl ?? '') + '/events');
    }

    public getById(id: string)
    {
        return {
            ...super.getById(id),
            activities: new ActivityQuery(`${this.rootUrl}/${id}`),
            attendees: new AttendeeQuery(`${this.rootUrl}/${id}`),
            regItems: new RegItemQuery(`${this.rootUrl}/${id}`),
            regTokens: new RegTokenQuery(`${this.rootUrl}/${id}`),
            fields: new EventFieldsQuery(`${this.rootUrl}/${id}`),
            views: new EventViewsQuery(`${this.rootUrl}/${id}`)
        };
    }
}