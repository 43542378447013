import { Dispatcher, AppAction } from "./Dispatcher";

export class AppDispatcher extends Dispatcher
{
    public static HandleViewAction(action: AppAction)
    {
        Dispatcher.Dispatch({
            source: "VIEW_ACTION",
            action: action
        });
    }

    public static HandleServerAction(action: AppAction)
    {
        Dispatcher.Dispatch({
            source: "SERVER_ACTION",
            action: action
        });
    }
}