import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { Drawer, DrawerProps } from "../../components/Drawer";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Utils } from "../../utils/Utils";

import "./ViewAttendeeDrawer.scss";
import Attendee from "../../dto/Attendee";
import { Tab, Tabs } from "../../components/Tabs";
import { BMDisplayField } from "../../components/Fields/BMDisplayField";
import { parse } from "date-fns";
import { usePrevious } from "../../components/Grid/usePrevious";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { BMDropdownField } from "../../components/Fields/BMDropdownField";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMButton } from "../../components/Fields/BMButton";
import { BMMultiSelectField } from "../../components/Fields/BMSelectField";
import RegItem from "../../dto/RegItem";
import { Currency } from "../../utils/Currency";

export interface AttendeeDrawerProps extends DrawerProps
{
    attendeeId: string;
}

export const ViewAttendeeDrawer = (props: AttendeeDrawerProps) => 
{
    const { open, attendeeId } = props;
    const { event, isLoaded } = useCurrentContext();
    const api = useAPI();

    const attendeeQuery = useQuery(QueryKeys.Attendees.GetById(event.id, attendeeId), () => api.events.getById(event.id).attendees.getById(attendeeId).get(), { enabled: isLoaded && !!attendeeId, initialData: null });
    const attendee = attendeeQuery.data;
    const name = attendee ? `${attendee?.firstName} ${attendee?.lastName}` : '';

    return (
        <Drawer title={name} loading={attendeeQuery.isFetching} {...props}>
            <div className="view-attendee-drawer">
                {/* <div className="attendee-drawer-header">
                    <h1>{name}</h1>
                    <div className="subtle">{attendee?.email}</div>
                </div> */}
                {/* <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
                    <button>Edit</button>
                    <button>Delete</button>
                </div> */}
                <Tabs>
                    <Tab label="Details">
                        <AttendeeDetailsTab eventId={event.id} attendee={attendee} />
                    </Tab>
                    
                    <Tab label="Registration">
                        <RegistrationTab eventId={event.id} attendee={attendee} />
                    </Tab>
                    <Tab label="Activity Log">
                        <ActivityLogTab eventId={event.id} attendeeId={attendeeId} />
                    </Tab>

                </Tabs>
            </div>
        </Drawer>
    );
}

const AttendeeDetailsTab = (props: { eventId: string, attendee: Attendee }) =>
{
    const api = useAPI();
    const { eventId, attendee } = props;

    const fieldsQuery = useQuery(QueryKeys.Events.Fields.All(eventId), () => api.events.getById(eventId).fields.get(), { initialData: null });
    const customFields = fieldsQuery.data ? fieldsQuery.data.items.sort(Utils.sortByProperty('name', true)) : [];
    const birthdate = attendee?.birthdate ? format(parse(attendee?.birthdate, 'yyyy-MM-dd', new Date()), 'PPP') : null;

    return (
        <div className="details-tab">

            <div className="details-tab-grid" style={{ gridTemplateColumns: '1fr 1fr' }}>
                <BMDisplayField label="First Name" value={attendee?.firstName} layout="vertical" />
                <BMDisplayField label="Last Name" value={attendee?.lastName} layout="vertical" />
                <BMDisplayField label="Email" layout="vertical">
                    {attendee?.email && <a href={`mailto:${attendee?.email}`}>{attendee?.email}</a>}
                </BMDisplayField>
                <BMDisplayField label="Phone Number" value={attendee?.phone} layout="vertical" />
                <BMDisplayField label="Date of Birth" value={birthdate} layout="vertical" />
                <BMDisplayField label="Notes" value={attendee?.notes} layout="vertical" style={{ gridColumn: '1 / span 2' }}  />
            </div>

            <hr />
            <h4>Custom Fields</h4>
            <div className="details-tab-grid">
                {
                    customFields.map(field => (
                        <BMDisplayField 
                            key={field.id} 
                            label={field.name} 
                            value={attendee?.fields?.[field.name]?.toString()} 
                            layout="vertical" 
                        />
                    ))
                }
            </div>

            <hr />
            <div className="details-tab-grid">
                <BMDisplayField label="Attendee ID" value={attendee?.id} layout="vertical" />
            </div>
        </div>
    );
}

const RegistrationTab = (props: { eventId: string, attendee: Attendee }) =>
{
    const { eventId, attendee } = props;
    const api = useAPI();
    const queryClient = useQueryClient();
    const regTiersQuery = useQuery(
        QueryKeys.RegTiers.All(eventId), 
        async () => api.events.getById(eventId).regItems.get(),
        { initialData: { items: [] }, enabled: !!eventId }
    );
    const [error, setError] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);

    let selectedRegTier: RegItem = null;
    let selectedAddOns: RegItem[] = [];

    if(regTiersQuery.isFetched && attendee)
    {
        const attendeeRegItems = attendee.registrationItems as string[];
        selectedRegTier = regTiersQuery.data.items.filter(tier => tier.type === 'registration').find(tier => attendeeRegItems.find(i => i === tier.id));
        selectedAddOns = regTiersQuery.data.items.filter(item => item.type === 'addon').filter(item => attendeeRegItems.some(i => i === item.id));
    }

    const initialData = {
        regTier: selectedRegTier?.id,
        addOns: selectedAddOns.map(addon => addon.id)
    };

    const onSubmit = useCallback(async (values: { regTier: string, addOns: string[] }, actions: FormikHelpers<any>) => {
        try
        {
            await api.events.getById(eventId).attendees.getById(attendee.id).update({
                registrationItems: [values.regTier, ...values.addOns].filter(i => i !== '')
            });
            queryClient.invalidateQueries(QueryKeys.Attendees.GetById(eventId, attendee.id));
            queryClient.invalidateQueries(QueryKeys.Attendees.All(eventId));
        }
        catch(e: any)
        {
            setError(e.message);
        }
    }, [regTiersQuery.data]);

    return (
        <div className="details-tab">

            {regTiersQuery.isFetched === false &&
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            }

            {
                regTiersQuery.isFetched && isEditing === false &&
                <div>
                    <h4>Registration Tier</h4>
                    {
                        selectedRegTier &&
                        <div className="regItem-container">
                            <div style={{ fontWeight: '500', marginBottom: '5px' }}>{selectedRegTier?.title} ({Currency.format(selectedRegTier?.price)})</div>
                            <p>{selectedRegTier?.description}</p>
                        </div>
                    }
                    {
                        !selectedRegTier &&
                        <p>No registration tier</p>
                    }
                    <hr />
                    <h4>Add-ons</h4>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {
                        selectedAddOns.map(addon => (
                            <div key={addon.id} className="regItem-container">
                                <div style={{ fontWeight: '500', marginBottom: '5px' }}>{addon.title} ({Currency.format(addon.price)})</div>
                                <p>{addon.description}</p>
                            </div>
                        ))
                    }
                    </div>
                    {
                        selectedAddOns.length === 0 &&
                        <p>No add-ons</p>
                    }
                    <hr />
                    <div>
                        <button onClick={() => setIsEditing(true)}>Edit Registration</button>
                    </div>
                </div>
            }

            {
                regTiersQuery.isFetched && isEditing &&
                <Formik 
                    initialValues={initialData}
                    onSubmit={onSubmit}
                >
                    <Form>
                        {/* <h4>Registration Tier</h4> */}

                        <BMDropdownField name="regTier" label="New Registration Tier" options={
                            [{ label: 'None', value: '' }, ...regTiersQuery.data.items.filter(i => i.type === 'registration').map(tier => ({ label: `${tier.title} (${Currency.format(tier.price)})`, value: tier.id }))]
                        } 
                            layout="vertical" 
                            description="Changing the attendee's registration tier here will not charge the attendee."
                        />

                        <BMMultiSelectField
                            name="addOns"
                            label="Add-ons"
                            options={regTiersQuery.data.items.filter(tier => tier.type === 'addon').map(tier => ({ label: `${tier.title} (${Currency.format(tier.price)})`, value: tier.id }))}
                            layout="vertical"
                            description="Changing the attendee's add-ons here will not charge the attendee."
                        />

                        <hr />
                        {
                            error && <div className="error">{error}</div>
                        }
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <BMSubmitButton>Save</BMSubmitButton>
                            <BMButton onClick={() => setIsEditing(false)}>Cancel</BMButton>
                        </div>
                    </Form>
                </Formik>
            }
        </div>
    );
}

const ActivityLogTab = (props: { eventId: string, attendeeId: string }) =>
{
    return (
        <div className="details-tab">
            Activity Tab
        </div>
    );
}