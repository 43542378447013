import Attendee from "./Attendee";

export default class Supervisor
{
    public id: string;
    public attendee: string | Attendee;
    public event: string;

    public static FromJson(json: any): Supervisor
    {
        let obj = new Supervisor();
        obj.id = json.id || json._id;
        obj.attendee = json.attendee;
        obj.event = json.event;

        if(typeof json.attendee === 'object')
            obj.attendee = Attendee.fromJson(json.attendee);

        return obj;
    }
}
