import { InputAdornment, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { CSSProperties, useEffect, useState } from "react";
import { BMFieldProps } from "./BMFieldProps";
import { useCurrentContext } from "../../hooks/useCurrentContext";

export interface BMCurrencyFieldProps extends BMFieldProps
{

}

const currencyToString = (value: number, currency?: string) => 
{
    const formatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: currency ?? 'CAD' });
    if(typeof value === 'number' && isFinite(value))
        return formatter.format(value / 100).replace(/\$/g, '');
    else
        return '';
};

const parseCurrency = (currencyStr: string) =>
{
    return parseInt((parseFloat(currencyStr.replace(/[^\d\.]/g, '')) * 100).toString());
};

export const BMCurrencyField = (props: BMCurrencyFieldProps) => 
{
    const { name, label, disabled = false, description, required, placeholder, autoFocus, layout = 'horizontal', style } = props;
    const [field, meta, helpers] = useField(name);
    const [focused, setFocused] = useState(false);
    const [strValue, setStrValue] = useState('');
    const context = useFormikContext();

    const { event, isLoaded } = useCurrentContext();

    const fieldStyle: CSSProperties = Object.assign({ gridArea: name }, style);

    useEffect(() => {
        if(!focused)
            setStrValue(currencyToString(field.value));
    }, [field.value]);

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);

        const numberValue = parseCurrency(e.target.value);
        helpers.setValue(isFinite(numberValue) ? numberValue : '', true);
        setStrValue(currencyToString(field.value));
        field.onBlur(e);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const numberValue = parseCurrency(newValue);
        helpers.setValue(isFinite(numberValue) ? numberValue : '', true);
        setStrValue(newValue);
    };

    return (
        <div className={`bm-field ${layout}`} style={fieldStyle}>
            { label && 
                <label>
                    {label}
                    {event?.currency ? ` (${event?.currency})` : ''} 
                    {required ? <span className="required"> *</span> : ''}
                </label> 
            }
            <div style={{ flex: 1 }}>
                <input 
                    type="text" 
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled || context.isSubmitting}
                    required={required}
                    autoFocus={autoFocus}
                    style={{ width: '100%' }}
                    value={strValue}
                    onFocus={() => setFocused(true)}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {
                    description &&
                    <div className="subtle">{description}</div>
                }
                {
                    meta.touched && !!meta.error &&
                    <div className="error">{meta.error}</div>
                }
            </div>
        </div>
    );
};
