import { Component } from "react";
//import { ModalDialog } from "../components/ModalDialog";
//import { TextField } from "../components/TextField";
import { StoreActions } from "../store/StoreActions";
import { AppDispatcher, AppEvent } from "../events/AppDispatcher";
import * as AppConstants from "../events/AppConstants";
import { Event } from "../dto/Event";
import Zone from "../dto/Zone";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { DialogSpinner } from "../components/DialogSpinner";

export interface ZoneModalProps 
{
    isOpen: boolean;
    type: FormType;
    onRequestClose?: any;
    onItemUpdated?: (e: AppEvent) => void;
    onItemCreated?: (e: AppEvent) => void;
    onError?: (e: any) => void;

    event?: Event; // Required for new zone
    zone?: Zone; // Required for update zone
}

export interface ZoneModalState
{
    modalTitle: string;
    title: string;
    isLoading: boolean;
}

export enum FormType
{
    New,
    Edit
}

export class ZoneModal extends Component<ZoneModalProps, ZoneModalState>
{
    private CreateEventName         = AppConstants.ZONE_CREATED;
    private CreateEventNameError    = AppConstants.ZONE_CREATED_ERROR;
    private UpdateEventName         = AppConstants.ZONE_UPDATED;
    private UpdateEventNameError    = AppConstants.ZONE_UPDATED_ERROR;
    private originalTitle: string = '';

    constructor(props: ZoneModalProps)
    {
        super(props);

        this.state = {
            modalTitle: 'Modal Dialog',
            isLoading: false,
            title: ''
        };
    }

    private updateState(nextProps: ZoneModalProps)
    {
        if(nextProps.type === FormType.New)
        {
            this.setState({
                modalTitle: 'New Zone',
                title: ''
            });
        }
        else if(nextProps.type === FormType.Edit && nextProps.zone)
        {
            this.originalTitle = nextProps.zone.title;
            this.setState({
                modalTitle: 'Edit Zone',
                title: nextProps.zone.title
            });
        }
    }

    public componentDidMount()
    {
        if(this.props.type === FormType.New)
        {
            AppDispatcher.on(this.CreateEventName, this.onItemCreated, this);
            AppDispatcher.on(this.CreateEventNameError, this.onItemCreatedError, this);
        }
        else if(this.props.type === FormType.Edit)
        {
            AppDispatcher.on(this.UpdateEventName, this.onItemUpdated, this);
            AppDispatcher.on(this.UpdateEventNameError, this.onItemUpdatedError, this);
        }

        this.updateState(this.props);
    }

    public componentWillUnmount()
    {
        if(this.props.type === FormType.New)
        {
            AppDispatcher.off(this.CreateEventName, this.onItemCreated);
            AppDispatcher.off(this.CreateEventNameError, this.onItemCreatedError);
        }
        else if(this.props.type === FormType.Edit)
        {
            AppDispatcher.off(this.UpdateEventName, this.onItemUpdated);
            AppDispatcher.off(this.UpdateEventNameError, this.onItemUpdatedError);
        }
    }

    public componentWillReceiveProps(nextProps: ZoneModalProps)
    {
        this.updateState(nextProps);
    }

    private onButtonClick(e: React.FormEvent<HTMLFormElement>)
    {
        e.preventDefault();

        if (this.state.title)
        {
            if(this.props.type === FormType.New)
            {
                StoreActions.CreateZone(
                    this.props.event.id,
                    this.state.title.trim()
                );
            }
            else if(this.props.type === FormType.Edit)
            {
                let zone = this.props.zone;
                zone.title = this.state.title.trim();
    
                StoreActions.UpdateZone(zone);
            }

            this.setState({ isLoading: true });
        }
        else
        {
            this.forceUpdate();
        }
    }

    public render() 
    {
        return (
            <Dialog
                maxWidth="sm"
                open={this.props.isOpen}
                title={this.state.modalTitle}
                onClose={this.props.onRequestClose}>
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <DialogTitle className="bm-dialog-title">{this.state.modalTitle}</DialogTitle>
                    <form className="bm-dialog-form" onSubmit={this.onButtonClick.bind(this)} style={{ minWidth: '460px' }}>
                        <DialogContent>
                            <FormControl margin="dense" fullWidth>
                                <FormLabel>Name</FormLabel>
                                <TextField
                                    value={this.state.title}
                                    onChange={(e) => this.setState({ title: e.target.value })}
                                    autoFocus
                                    autoComplete="off"
                                    placeholder="e.g. Registration, Setup/Teardown"
                                    variant="outlined"
                                    required
                                    disabled={this.state.isLoading}
                                />
                            </FormControl>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.props.onRequestClose()}>Cancel</Button>
                            <Button type="submit" color="primary" variant="contained">{this.props.type === FormType.New ? 'Create' : 'Save'}</Button>
                        </DialogActions>
                    </form>
                </DialogSpinner>
            </Dialog>
        )
    }

    private onItemCreated(e: AppEvent)
    {
        if(this.props.onItemCreated)
            this.props.onItemCreated(e);

        this.setState({ isLoading: false });
        
        if(this.props.onRequestClose)
            this.props.onRequestClose();
    }

    private onItemCreatedError(e: AppEvent)
    {
        if(this.props.onError)
            this.props.onError(e)
        else
            alert(`Error creating item: ${e.payload.error}`);
    }
    
    private onItemUpdated(e: AppEvent)
    {
        if (this.props.onItemUpdated)
            this.props.onItemUpdated(e);

        this.setState({ isLoading: false });

        if(this.props.onRequestClose)
            this.props.onRequestClose();
    }

    private onItemUpdatedError(e: AppEvent)
    {
        if(this.props.onError)
            this.props.onError(e);
        else
            alert(`Error updating item: ${e.payload.error}`);
    }
}
