import { Tooltip } from "@mui/material";
import { formatDistanceToNow, format, subDays, isValid, differenceInMinutes } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export const currentTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const toDateElement = (date: Date, humanized: boolean = false) => 
{
    if(date instanceof Date && isValid(date))
    {
        return (
            <Tooltip title={formatInTimeZone(date, currentTimeZone(), 'MMM d yyyy, h:mm:ss b (z)')} placement="right">
                <span>{toDateString(date, humanized)}</span>
            </Tooltip>
        );
    }
    else
    {
        return <span>Invalid Date</span>;
    }
}

export const getTimeZones = (): string[] =>
{
    return (Intl as any).supportedValuesOf('timeZone');
}

export const toDifferenceString = (startTime: Date, endTime: Date) =>
{
    const total = differenceInMinutes(endTime, startTime);

    return (
        Math.trunc(total / 60) + (Math.trunc(total / 60) === 1 ? ' hour' : ' hours') +
        ', ' +
        total % 60 + (total % 60 === 1 ? ' minute' : ' minutes')
    );
}

export const toDateString = (date: Date, humanized: boolean = false) =>
{
    let retval = 'Invalid date';

    if(date instanceof Date && isValid(date))
    {
        if(humanized)
        {
            if(date < subDays(new Date(), 7))
                retval = format(date, 'PP');
            else
                retval = formatDistanceToNow(date, { addSuffix: true });
        }
        else
        {
            const now = new Date();
            if(date.getFullYear() === now.getFullYear())
            {
                retval = format(date, 'MMM d h:mmaaa');
            }
            else
            {
                retval = format(date, 'PP h:mmaaa');
            }
        }
    }

    return retval;
}

export const toHumanizedDateSpan = (startDate: string, endDate: string, timeZone?: string) =>
{
    const start = startDate ? new Date(startDate) : undefined;
    const end = endDate ? new Date(endDate) : undefined;

    // TODO: take event timezone into account
    if(isValid(start) && isValid(end))
    {
        if(start.getFullYear() === end.getFullYear())
        {
            if(start.getMonth() === end.getMonth())
            {
                if(start.getDay() === end.getDay())
                {
                    return format(start, 'PP');
                }
                else
                {
                    return `${format(start, 'MMM d')} - ${format(end, 'd, yyyy')}`
                }
            }
            else
            {
                return `${format(start, 'MMM d')} - ${format(end, 'MMM d, yyyy')}`;
            }
        }
        else
        {
            return `${format(start, 'PP')} - ${format(end, 'PP')}`;
        }
    }
    else
    {
        console.warn(`[toHumanizedDateSpan] startDate or endDate is invalid`);
        return '';
    }
}

// export default class DateUtils
// {
//     // public static currentTimeZone()
//     // {
//     //     return Intl.DateTimeFormat().resolvedOptions().timeZone;
//     // }

//     // public static toDateElement(date: Date, humanized: boolean = false)
//     // {
//     //     if(date instanceof Date && isValid(date))
//     //     {
//     //         return (
//     //             <Tooltip title={formatInTimeZone(date, this.currentTimeZone(), 'MMM d yyyy, h:mm:ss b (z)')} placement="right">
//     //                 <span>{this.toDateString(date, humanized)}</span>
//     //             </Tooltip>
//     //         );
//     //     }
//     //     else
//     //     {
//     //         return <span>Invalid Date</span>;
//     //     }
//     // }

//     public static toHumanizedDateSpan(startDate: string, endDate: string, timeZone?: string)
//     {
//         const start = startDate ? new Date(startDate) : undefined;
//         const end = endDate ? new Date(endDate) : undefined;

//         // TODO: take event timezone into account
//         if(isValid(start) && isValid(end))
//         {
//             if(start.getFullYear() === end.getFullYear())
//             {
//                 if(start.getMonth() === end.getMonth())
//                 {
//                     if(start.getDay() === end.getDay())
//                     {
//                         return format(start, 'PP');
//                     }
//                     else
//                     {
//                         return `${format(start, 'MMM d')} - ${format(end, 'd, yyyy')}`
//                     }
//                 }
//                 else
//                 {
//                     return `${format(start, 'MMM d')} - ${format(end, 'MMM d, yyyy')}`;
//                 }
//             }
//             else
//             {
//                 return `${format(start, 'PP')} - ${format(end, 'PP')}`;
//             }
//         }
//         else
//         {
//             console.warn(`[toHumanizedDateSpan] startDate or endDate is invalid`);
//             return '';
//         }
//     }
    
//     public static toDateString(date: Date, humanized: boolean = false)
//     {
//         let retval = 'Invalid date';

//         if(date instanceof Date && isValid(date))
//         {
//             if(humanized)
//             {
//                 if(date < subDays(new Date(), 7))
//                     retval = format(date, 'PP');
//                 else
//                     retval = formatDistanceToNow(date, { addSuffix: true });
//             }
//             else
//             {
//                 const now = new Date();
//                 if(date.getFullYear() === now.getFullYear())
//                 {
//                     retval = format(date, 'MMM d h:mmaaa');
//                 }
//                 else
//                 {
//                     retval = format(date, 'PP h:mmaaa');
//                 }
//             }
//         }

//         return retval;
//     }

//     // public static toHumanizedDateString(date: Date)
//     // {
//     //     let retval = 'Invalid date';

//     //     if(date instanceof Date && isValid(date))
//     //     {
//     //         if(date < subDays(new Date(), 7))
//     //         {
//     //             retval = format(date, 'PP');
//     //         }
//     //         else
//     //         {
//     //             retval = formatDistanceToNow(date, { addSuffix: true });
//     //         }
//     //     }

//     //     return retval;
//     // }

//     public static toDifferenceString(startTime: Date, endTime: Date)
//     {
//         const total = differenceInMinutes(endTime, startTime);

//         return (
//             Math.trunc(total / 60) + (Math.trunc(total / 60) === 1 ? ' hour' : ' hours') +
//             ', ' +
//             total % 60 + (total % 60 === 1 ? ' minute' : ' minutes')
//         );
//     }
// }