import { useCallback, useEffect, useState } from "react";
import { format, isValid } from "date-fns";
import { Drawer, DrawerProps } from "../../components/Drawer";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { BMTextField } from "../../components/Fields/BMTextField";
import { BMDateField } from "../../components/Fields/BMDateField";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMButton } from "../../components/Fields/BMButton";
import { Utils } from "../../utils/Utils";
import * as Yup from "yup";

import "./ViewActivityDrawer.scss";
import Attendee from "../../dto/Attendee";
import { Tab, Tabs } from "../../components/Tabs";
import { BMDisplayField } from "../../components/Fields/BMDisplayField";
import { Activity } from "../../dto/Activity";
import { Table, TableConfig } from "../../components/Table/Table";
import CheckIn from "../../dto/CheckIn";
import { toDateElement } from "../../utils/DateUtils";

export interface ActivityDrawerProps extends DrawerProps
{
    activityId: string;
}

export const ViewActivityDrawer = (props: ActivityDrawerProps) => 
{
    const { open, onSave, onDismiss, activityId } = props;
    const { event, isLoaded } = useCurrentContext();
    const queryClient = useQueryClient();
    const api = useAPI();
    const [error, setError] = useState<string>('');

    const fieldsQuery = useQuery(QueryKeys.Events.Fields.All(event.id), () => api.events.getById(event.id).fields.get(), { enabled: isLoaded, initialData: null });
    const activityQuery = useQuery(QueryKeys.Activities.Id(event.id, activityId), () => api.events.getById(event.id).activities.getById(activityId).get(), { enabled: isLoaded && !!activityId, initialData: null });

    useEffect(() => {
        if(open)
        {
            if(activityId)
                activityQuery.refetch();
        }
    }, [open]);

    const activity = activityQuery.data;

    return (
        <Drawer title={activity?.title || ''} loading={activityQuery.isFetching} {...props}>
            <div className="view-activity-drawer">
                {/* <div className="activity-drawer-header">
                    <h1>{activity?.title}</h1>
                    <div className="subtle">{activity?.location}</div>
                </div> */}
                {/* <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
                    <button>Edit</button>
                    <button>Check-in</button>
                    <button>Export</button>
                </div> */}
                <Tabs>
                    <Tab label="Details">
                        <ActivityDetailsTab eventId={event.id} activity={activity} />
                    </Tab>
                    
                    <Tab label="Checked-in">
                        <CheckedInTab eventId={event.id} activity={activity} />
                    </Tab>
                    <Tab label="Activity Log">
                        <ActivityLogTab eventId={event.id} activity={activity} />
                    </Tab>
                </Tabs>
            </div>
        </Drawer>
    );
}

const ActivityDetailsTab = (props: { eventId: string, activity: Activity }) =>
{
    const api = useAPI();
    const { eventId, activity } = props;

    const startTimeStr = isValid(new Date(activity?.startTime)) ? format(new Date(activity?.startTime), 'PP p') : '';
    const endTimeStr = isValid(new Date(activity?.endTime)) ? format(new Date(activity?.endTime), 'PP p') : '';

    return (
        <div className="details-tab">

            <div className="details-tab-grid" style={{ gridTemplateColumns: '1fr 1fr' }}>
                <BMDisplayField label="Title" value={activity?.title} layout="vertical" />
                <BMDisplayField label="Type" value={activity?.type} layout="vertical" />
                <BMDisplayField label="Location" value={activity?.location} layout="vertical" />
                <BMDisplayField label="Start Time" value={startTimeStr} layout="vertical" />
                <BMDisplayField label="End Time" value={endTimeStr} layout="vertical" />
                <BMDisplayField label="Description" value={activity?.description} layout="vertical" />
            </div>

            <hr />
            <div className="details-tab-grid">
                <BMDisplayField label="Activity ID" value={activity?.id} layout="vertical" />
            </div>
        </div>
    );
}

const CheckedInTab = (props: { eventId: string, activity: Activity }) =>
{
    const api = useAPI();
    const checkinQuery = useQuery(
        QueryKeys.Activities.Checkins(props.eventId, props.activity.id), 
        () => api.events.getById(props.eventId).activities.getById(props.activity.id).checkins
            .orderBy('timestamp', 'desc')
            .expand('attendee')
            .get(), 
        { enabled: !!props.activity, initialData: { items: [] } 
    });

    const tableConfig: TableConfig<CheckIn> = {
        sorts: [{ key: 'checkinTime', dir: 'desc' }],
        columns: [
            { key: 'attendee', name: '#', render: (value: Attendee) => value.attendeeNumber?.padStart(4, '0') },
            { key: 'attendee', name: 'Attendee', render: (value: Attendee) => `${value.firstName} ${value.lastName}` || 'Unknown' },
            { key: 'timestamp', name: 'Check-in Time', render: (value: string) => toDateElement(new Date(value)) },
        ]
    }

    return (
        <div className="details-tab">
            <Table
                config={tableConfig}
                items={checkinQuery.data?.items || []}
                loading={checkinQuery.isFetching}
            />        
        </div>
    );
}

const ActivityLogTab = (props: { eventId: string, activity: Activity }) =>
{
    return (
        <div className="details-tab">
            Activity Tab
        </div>
    );
}