import { ComponentProps, createContext, useCallback, useState } from "react";
import { useToggle } from "../hooks/useToggle";
import { Dialog } from "../components/Dialog";

export interface DialogConfig
{
    title: string;
    content: React.ReactNode;
    options?: DialogOption[];
    onSelect?: (option: DialogOption) => void | Promise<void>;
}

export interface DialogOption
{
    text: string;
    type?: 'normal' | 'primary' | 'danger'
}

export interface DialogContextInterface
{
    show: (config: DialogConfig) => void;
    close: () => void;
}

export const DialogContext = createContext<DialogContextInterface>({ 
    show: null, 
    close: null
});

export const DialogProvider = (props: ComponentProps<any>) => 
{
    const { children } = props;
    const dialog = useToggle(false);
    const [loading, setLoading] = useState(false);
    const [dialogConfig, setDialogConfig] = useState<DialogConfig>({
        title: '',
        content: null
    });

    const openDialog = useCallback((config: DialogConfig) =>
    {
        setDialogConfig(config);
        dialog.open();
    }, [dialog]);

    const closeDialog = useCallback(() =>
    {
        dialog.close();
    }, [dialog]);

    const handleSelect = useCallback(async (option: DialogOption) =>
    {
        setLoading(true);
        await dialogConfig.onSelect(option);
        dialog.close();
        setLoading(false);
    }, [dialogConfig]);

    const footer = dialogConfig?.options?.map((option, index) =>
    {
        return (
            <button 
                key={index} 
                className={option.type === 'danger' ? 'danger' : ''}
                onClick={() => handleSelect(option)}>{option.text}</button>
        );
    });

    return (
        <DialogContext.Provider value={{ show: openDialog, close: closeDialog }}>
            {children}
            <Dialog 
                open={dialog.isOpen}
                loading={loading}
                onDismiss={dialog.close}
                title={dialogConfig?.title}
                footer={<>{footer}</>}>
                {dialogConfig?.content}
            </Dialog>
        </DialogContext.Provider>
    );
}
