import Attendee from "./Attendee";

export default class Shift
{
    public id: string;
    public zone: string;
    public attendee: string | Attendee;
    public cardId: string;
    public startTime: Date;
    public endTime: Date;
    public autoCheckout: boolean;

    public static FromJson(json: any): Shift
    {
        let obj = new Shift();
        obj.id = json.id || json._id;
        obj.zone = json.zone;
        obj.attendee = json.attendee;
        obj.cardId = json.cardId;
        obj.startTime = new Date(json.startTime);
        obj.endTime = json.endTime ? new Date(json.endTime) : null;
        obj.autoCheckout = json.autoCheckout;

        if(typeof json.attendee === 'object')
            obj.attendee = Attendee.fromJson(json.attendee);

        return obj;
    }
}
