import { useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Login } from "./pages/Auth/Login";
import { Logout } from "./pages/Auth/Logout";
import { OrgDashboard } from "./OrgDashboard";
import { BM } from "./api/BM";
import { Callback } from "./pages/Auth/Callback";
import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "./constants/AppRoutes";
import { StorageKeys } from "./constants/Constants";
import { useQuery } from "react-query";
import ListResponse from "./api/ListResponse";
import { Organization } from "./dto/Organization";
import { QueryKeys } from "./data/QueryKeys";
import { hrefToRelativeUrl } from "./utils/Utils";
import { ErrorBoundary } from "./ErrorBoundary";
import { DialogProvider } from "./context/DialogContext";
import { ToastProvider } from "./context/ToastContext";
import { useAuth } from "@clerk/clerk-react";

const {
    REACT_APP_CLERK_TOKEN_TEMPLATE: clerkTokenTemplate,
} = process.env;

export const App = () => 
{
    //const { getAccessTokenSilently } = useAuth0();
    const { isLoaded, isSignedIn, getToken } = useAuth();
    const nav = useNavigate();

    // const dialog = useToggle(false);
    // const [dialogTitle, setDialogTitle] = useState<string>('');
    // const [dialogContent, setDialogContent] = useState<JSX.Element>(null);

    useEffect(() => 
    {
        BM.client.setAuthProvider(async () => 
        {
            try
            {
                if(isLoaded)
                {
                    if(isSignedIn)
                        return await getToken({ template: clerkTokenTemplate });
                    else
                        nav(AppRoutes.Login(hrefToRelativeUrl(window.location.href)));
                }
                else
                {
                    return null;
                }
            }
            catch(error: any)
            {
                console.error(error);
                if(error.error === 'login_required')
                    nav(AppRoutes.Login(hrefToRelativeUrl(window.location.href)));
                else
                    alert(error);
            }
        });
    }, [isLoaded, isSignedIn, getToken, nav]);

    // const openDialog = (config: DialogConfig) =>
    // {
    //     console.log('openDialog', config.title, config.content);
    //     setDialogTitle(config.title);
    //     setDialogContent(config.content);
    //     dialog.open();
    // }

    // const closeDialog = () =>
    // {
    //     console.log('closeDialog');
    //     dialog.close();
    // }

    return (
        <ErrorBoundary>
            {/* <DialogContext.Provider value={{ openDialog, closeDialog }}> */}
            <ToastProvider>
            <DialogProvider>
                <Routes>
                    <Route path="/auth/login" element={<Login />} />
                    <Route path="/auth/logout" element={<Logout />} />
                    <Route path="/auth/callback" element={<Callback />} />
                    <Route path="/:orgId/*" element={<OrgDashboard />} />
                    <Route path="/" element={<RedirectToOrg />} />
                </Routes>
            </DialogProvider>
            </ToastProvider>
            {/* </DialogContext.Provider> */}
        </ErrorBoundary>
    );
}

const RedirectToOrg = (props) => {
    
    const nav = useNavigate();
    const lastOrgId = localStorage.getItem(StorageKeys.LastOrganizationId);
    const orgQuery = useQuery<ListResponse<Organization>>(QueryKeys.Orgs.All(), () => BM.client.organizations.get(), { enabled: !lastOrgId });

    useEffect(() => {
        if(lastOrgId)
            nav(AppRoutes.OrgDashboard(lastOrgId))
        else if(orgQuery.isSuccess && orgQuery.data.items.length > 0)
            nav(AppRoutes.OrgDashboard(orgQuery.data.items[0].id))
        else if(orgQuery.isSuccess && orgQuery.data.items.length === 0)
        {
            // TODO: navigate to new org dialog
            console.error('No orgs associated with this account');
            //alert('No orgs associated with this account');
        }
    }, [orgQuery.isSuccess, lastOrgId]);

    return null;
}
