import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { QueryKeys } from "../data/QueryKeys";
import { Organization } from "../dto/Organization";
import User from "../dto/User";
import { Event } from "../dto/Event";
import { useAPI } from "./useAPI";

export const useCurrentContext = () => 
{
    const { orgId, eventId } = useParams();
    const api = useAPI();
    const userQuery = useQuery<User>(QueryKeys.Users.Current(), () => api.users.me(), { enabled: !!api, staleTime: 1000 * 60 * 1 });
    const orgQuery = useQuery<Organization>(QueryKeys.Orgs.Id(orgId), () => api.organizations.getById(orgId).get(), { enabled: !!api && !!orgId, staleTime: 1000 * 60 * 1 });
    const eventQuery = useQuery<Event>(QueryKeys.Events.Id(eventId), () => api.events.getById(eventId).get(), { enabled: !!api && !!eventId, staleTime: 1000 * 60 * 1 });

    return {
        isLoaded: userQuery.isSuccess && (!orgId || orgQuery.isSuccess) && (!eventId || eventQuery.isSuccess),
        isUserLoaded: userQuery.isSuccess,
        isOrganizationLoaded: orgQuery.isSuccess,
        isEventLoaded: eventQuery.isSuccess,
        user: userQuery.data,
        organization: orgQuery.data,
        organizationId: orgId,
        event: eventQuery.data,
        eventId
    };
};
