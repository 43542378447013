import { Query } from "./Query";
import BaseQuery from "./BaseQuery";
import RegToken from "../dto/RegToken";

export default class RegTokenQuery extends BaseQuery<RegToken>
{
    constructor(rootUrl?: string)
    {
        super((rootUrl ?? '') + '/reg/tokens');
        this.query = new Query<RegToken>(this.rootUrl);
    }
}