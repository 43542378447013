import Attendee from "../dto/Attendee";
import BaseQuery from "./BaseQuery";

export default class AttendeeQuery extends BaseQuery<Attendee>
{
    constructor(rootUrl?: string)
    {
        super((rootUrl ?? '') + '/attendees', Attendee.fromJson);
        //this.query = new Query<Attendee>(this.rootUrl, Attendee.fromJson);
    }

    public getById(id: string)
    {
        const retval = {
            update: (data: Partial<Attendee>) => { return this.update(id, data) },
            get: () => { return this.getOne(id); },
            delete: () => { return this.delete(id); },
            select: (...fields: string[]) => {
                this.select(...fields);
                return retval;
            },
            expand: (...fields: string[]) => {
                this.expand(...fields);
                return retval;
            },
            archive: () => {
                return this.query.actionById<void>(id, 'archive', 'POST');
            },
            restore: () => {
                return this.query.actionById<void>(id, 'restore', 'POST');
            },
            print: () => {
                return this.query.actionById<void>(id, 'print', 'POST');
            }
        };
        return retval;
    }

    public archived(showArchived: boolean)
    {
        return this.query.param('archived', showArchived ? 'true' : 'false');
    }
}