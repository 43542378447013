import { Component } from "react";

export interface CardProps
{
    title?: string;
    subtitle?: string;
    accent?: CardAccent;
    padded?: boolean;
    className?: string;
    children?: any;
}

export interface CardState
{

}

export enum CardAccent {
    None,
    Event,
    Session,
    CheckIn,
    Attendee,
    User
}

export class Card extends Component<CardProps, CardState>
{
    public static defaultProps: Partial<CardProps> = {
        subtitle: '',
        accent: CardAccent.None,
        padded: true
    };

    constructor(props: CardProps)
    {
        super(props);

        this.state = {
        };
    }

    public componentDidMount() 
    {

    }

    public componentDidUpdate()
    {

    }

    public componentWillUnmount()
    {
        
    }

    public render() 
    {
        let accentClass = '';
        let titleRow = null;

        if(this.props.className)
            accentClass += ' ' + this.props.className + ' ';

        switch(this.props.accent)
        {
            case CardAccent.Event: accentClass = 'accent1'; break;
            case CardAccent.CheckIn: accentClass = 'accent2'; break;            
            case CardAccent.Session: accentClass = 'accent3'; break;
            case CardAccent.Attendee: accentClass = 'accent4'; break;
            case CardAccent.User: accentClass = 'accent5'; break;
        }

        if(this.props.title)
        {
            titleRow = (
                <div className="card-title">
                    <h4>{this.props.title}</h4>
                    {
                        this.props.subtitle && 
                        <span className="subtle">{this.props.subtitle}</span>
                    }
                </div>
            );
        }

        return (
            <div className='card-container'>
                <div className={'card ' + accentClass}>
                    {titleRow}  
                    <div className={'card-content' + (this.props.padded ? ' padded' : '')}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
