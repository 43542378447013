import { Query } from "./Query";
import EventQuery from "./EventQuery";
import OrganizationQuery from "./OrganizationQuery";
import UserQuery from "./UserQuery";
//import AuthQuery from "./AuthQuery";

interface BMConfiguration
{
    apiUrl?: string;
    authProvider?: () => Promise<string>;
}

export class BM
{
    private _events: EventQuery;
    private _organizations: OrganizationQuery;
    private _users: UserQuery;
    //private _auth: AuthQuery;

    public get events() { return this._events; }
    public get organizations() { return this._organizations; }
    public get users() { return this._users; }
    //public get auth() { return this._auth; }

    private static _current: BM = null;
    public static get client()
    {
        return this._current = this._current ?? new BM({});
        //return this._current;
    }

    constructor(config: BMConfiguration)
    {
        const url = config.apiUrl || process.env['REACT_APP_API_URL'];
        //const url = config.apiUrl ||;

        if(config.authProvider)
            Query.authProvider = config.authProvider;
 
        this._events = new EventQuery(url);
        this._organizations = new OrganizationQuery(url);
        this._users = new UserQuery(url);
        //this._auth = new AuthQuery(url);

        BM._current = this;
        window['bm'] = this;
    }

    public setUrl(url: string)
    {
        this._events = new EventQuery(url);
        this._organizations = new OrganizationQuery(url);
        this._users = new UserQuery(url);
    }

    public setAuthProvider(provider: () => Promise<string>)
    {
        Query.authProvider = provider;
    }

    // public setAccessToken(apiKey: string)
    // {
    //     Query.accessToken = apiKey;
    // }
}