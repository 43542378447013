import { HTMLProps } from "react";

import "./GridCell.scss";

export const GridCell = (props: HTMLProps<HTMLDivElement>) => 
{
    const { children, className, tabIndex, ...otherProps } = props;

    const classes = ['grid-cell'];
    if(className)
        classes.push(className)

    return (
        <div className={classes.join(' ')} tabIndex={tabIndex ?? -1} {...otherProps}>
            {children}
        </div>
    );
}