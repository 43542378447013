import { Query } from "./Query";
import { Organization } from "../dto/Organization";
import EventQuery from "./EventQuery";
import BaseQuery from "./BaseQuery";

export default class OrganizationQuery extends BaseQuery<Organization>
{
    //private query: Query<Organization>;
    //private rootUrl: string;

    constructor(rootUrl?: string)
    {
        super((rootUrl ?? '') + '/orgs');

        //this.rootUrl = (rootUrl ?? '') + '/orgs';
        //this.query = new Query<Organization>(this.rootUrl, Organization.fromJson);
        //this.query = new Query<Organization>(this.rootUrl);
    }

    // public add(data: Partial<Organization>)
    // {
    //     return this.query.add(data);
    // }

    // public get()
    // {
    //     return this.query.get();
    // }

    // public getById(id: string)
    // {
    //     return {
    //         update: (data: any) => { return this.update(id, data) },
    //         get: () => { return this.getOne(id); },
    //         delete: () => { return this.delete(id); },
    //         events: new EventQuery(`${this.rootUrl}/${id}`)
    //         //events: new CandidatesQuery(this.rootUrl + `/${id}`)
    //     };
    // }

    public getById(id: string)
    {
        return {
            ...super.getById(id),
            events: new EventQuery(`${this.rootUrl}/${id}`)
        };
    }

    // private update(id: string, data: Partial<Organization>)
    // {
    //     return this.query.update(id, data);
    // }

    // private getOne(id: string)
    // {
    //     return this.query.getById(id);
    // }

    // private delete(id: string)
    // {
    //     return this.query.delete(id);
    // }
}