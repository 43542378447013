import { useRef } from "react";
import { Button, Menu, MenuItem, Divider, Avatar, IconButton, ListItemIcon, ListItemText, Skeleton } from "@mui/material";
import { useTheme } from "@mui/system";
import { Utils } from "../utils/Utils";

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Add from "@mui/icons-material/Add";
import Settings from "@mui/icons-material/Settings";
import Notifications from "@mui/icons-material/Notifications";
import ShareIcon from "@mui/icons-material/Share";
import PeopleIcon from "@mui/icons-material/People";
import Logout from "@mui/icons-material/Logout";
import { useQuery } from "react-query";
import { useCurrentContext } from "../hooks/useCurrentContext";
import { QueryKeys } from "../data/QueryKeys";
import { BM } from "../api/BM";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../constants/AppRoutes";
import { useToggle } from "../hooks/useToggle";

import "./TopNavigation.scss";
import Fade from "@mui/material/Fade";
import { ShareDialog } from "../dialogs/ShareDialog";
import { SignInButton, useClerk, UserButton } from "@clerk/clerk-react";

export interface TopNavigationProps
{
    fullWidth?: boolean
};

export const TopNavigation = (props: TopNavigationProps) =>
{
    const { fullWidth = true } = props;

    const { redirectToUserProfile }= useClerk();
    const orgButton = useRef(null);
    const orgMenu = useToggle(false);
    const userButton = useRef(null);
    const userMenu = useToggle(false);
    const shareDialog = useToggle(false);
    const nav = useNavigate();
    const theme = useTheme();

    const { isLoaded, user, organization, isOrganizationLoaded } = useCurrentContext();
    const orgQuery = useQuery(QueryKeys.Orgs.All(), () => BM.client.organizations.get());

    return (        
        <nav className="topnav">
            <div className="header-nav">
                <div className="header-buttons">
                    <div className="header-logo" style={{ marginRight: '20px' }}>
                        <a href="/" className="logo" style={{ height: '32px' }} onClick={(e) => { nav(AppRoutes.Root()); e.preventDefault(); }}>
                            <img src="/images/logo-white.svg" alt="Badgemeister Logo" />
                        </a>
                    </div>
                    {
                        organization &&
                        <Button onClick={() => orgMenu.open()} ref={orgButton} endIcon={<KeyboardArrowDown />} sx={{ textTransform: 'none', color: theme.palette.text.primary, letterSpacing: '0' }}>
                            <Avatar {...Utils.avatarFromString(organization.title, 32, { marginRight: '10px' })} variant="rounded" />
                            {organization.title} 
                        </Button>
                    }
                    {
                        !organization && 
                        <div style={{ display: 'flex', gap: '15px', marginLeft: '8px' }}>
                            <Skeleton variant="rectangular" animation="wave" width={32} height={32} style={{ borderRadius: '5px' }} />
                            <Skeleton variant="text" animation="wave" width={140} />
                        </div>
                    }
                    {
                        orgQuery.isSuccess && orgButton.current &&
                        <Menu open={orgMenu.isOpen} anchorEl={orgButton.current} onClose={() => orgMenu.close()} TransitionComponent={Fade} TransitionProps={{ timeout: 100 }}>
                            {
                                [...orgQuery.data.items].sort(Utils.sort('title')).map(org => {
                                    return (
                                        <MenuItem key={org.id} href={`/${org.id}`} component="a" sx={{ width: '320px' }} onClick={(e) => { nav(AppRoutes.OrgDashboard(org.id)); orgMenu.close(); e.preventDefault(); }}>
                                            <Avatar {...Utils.avatarFromString(org.title, 32, { marginRight: '10px' })} variant="rounded" />
                                            <ListItemText primary={org.title} />
                                        </MenuItem>
                                    );
                                })
                            }
                            <Divider />
                            <MenuItem disabled>
                                <ListItemIcon><Add /></ListItemIcon>
                                <ListItemText>Create new Organization</ListItemText>
                            </MenuItem>
                            <MenuItem disabled>
                                <ListItemIcon><Settings /></ListItemIcon>
                                <ListItemText>Organization Settings</ListItemText>
                            </MenuItem>
                        </Menu>
                    }

                </div>
            </div>

            <div className="header-buttons">
                {/* <UserButton /> */}
                {/* <SignInButton /> */}

                {
                    user &&
                    <>
                        <button className="" onClick={shareDialog.open}><PeopleIcon fontSize="small" />Share</button>
                        {/* <IconButton>
                            <Notifications />
                        </IconButton> */}
                        <IconButton ref={userButton} onClick={() => userMenu.open()}>
                            <Avatar {...Utils.avatarFromString(user.name)} />
                        </IconButton>

                        <Menu open={userMenu.isOpen} anchorEl={userButton.current} onClose={() => userMenu.close()} TransitionComponent={Fade} TransitionProps={{ timeout: 100 }}>
                            <MenuItem sx={{ width: '280px' }} >
                                <Avatar {...Utils.avatarFromString(user.name, 32, { marginRight: '10px' })} />
                                <ListItemText primary={user.name} secondary={user.email} />
                            </MenuItem>
                            <Divider />
                            <MenuItem component="a" onClick={() => redirectToUserProfile()}>
                                <ListItemIcon><Settings /></ListItemIcon>
                                <ListItemText>Account Settings</ListItemText>
                            </MenuItem>
                            <MenuItem component="a" href="/auth/logout">
                                <ListItemIcon><Logout /></ListItemIcon>
                                <ListItemText>Sign Out</ListItemText>
                            </MenuItem>
                        </Menu>
                    </>
                }
                { !user && <Skeleton width={32} height={32} variant="circular" style={{ marginRight: '8px' }} /> }

                <ShareDialog isOpen={shareDialog.isOpen} onDismiss={shareDialog.close} />
            </div>
        </nav>
    );
}

