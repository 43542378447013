import { useContext, useState, useEffect, useCallback } from "react";
import {Organization} from "../../dto/Organization";
import { Event } from "../../dto/Event";
import OrganizationContext from "../../context/OrganizationContext";
import EventContext from "../../context/EventContext";
import { Page } from "../../components/Page";
import { Header } from "../../components/Header";
import { Button, Chip, MenuItem, Paper, Stack, Tooltip } from "@mui/material";
import RegItem from "../../dto/RegItem";
import { Currency } from "../../utils/Currency";
import { RegItemDialog } from "../../dialogs/RegItemDialog";
import { Utils } from "../../utils/Utils";

import { QuickSearchToolbar } from "../../components/QuickSearchToolbar";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import { BM } from "../../api/BM";
import { PageToolbar, ToolbarButton, ToolbarIconButton, ToolbarSearchBox } from "../../components/PageToolbar";
import { Card } from "../../components/Card";
import { Form, Formik } from "formik";
import { BMTextField } from "../../components/Fields/BMTextField";
import { BMDateTimeField } from "../../components/Fields/BMDateTimeField";
import { BMTextArea } from "../../components/Fields/BMTextArea";
import { BMSubmitButton } from "../../components/Fields/BMSubmitButton";
import { BMDropdownField } from "../../components/Fields/BMDropdownField";
import { getTimeZones } from "../../utils/DateUtils";
import { useQuery } from "react-query";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { Table, TableConfig } from "../../components/Table/Table";
import { Field } from "../../dto/Field";
import { EditCustomFieldDrawer } from "./EditCustomFieldDrawer";
import { useToggle } from "../../hooks/useToggle";


export interface CustomFieldsSettingsPageProps
{

}

export const CustomFieldsSettingsPage = (props: CustomFieldsSettingsPageProps) =>
{
    const { event, isLoaded } = useCurrentContext();
    const editDrawer = useToggle(false);
    const api = useAPI();
    const fieldsQuery = useQuery(
        QueryKeys.Events.Fields.All(event.id), 
        () => api.events.getById(event.id).fields.get(), 
        { enabled: isLoaded, initialData: { items: [] } }
    );
    const [selectedItem, setSelectedItem] = useState<Field>(null);

    const [tableConfig, setTableConfig] = useState<TableConfig<Field>>({
        columns: [
            { key: 'name', name: 'Name', width: 1, sortable: true, render: (value, item) => {
                return <a href="#" onClick={() => editItem(item)}>{value}</a>;
            }},
            { key: 'type', name: 'Type', width: 1, sortable: true, render: (value) => {
                switch(value)
                {
                    case 'text': return 'Text';
                    case 'number': return 'Number';
                    case 'date': return 'Date';
                    case 'dropdown': return 'Dropdown';
                    default: return value;
                }
            }},
            // { key: 'actions', name: 'Actions', width: 1, sortable: false, render: (value, item) => {
            //     return (
            //         <div style={{ display: 'flex', gap: '5px' }}>
            //             <button>Up</button>
            //             <button>Down</button>
            //         </div>
            //     );
            // }}
        ]
    });

    const editItem = useCallback((item: Field) => {
        setSelectedItem(item);
        editDrawer.open();
    }, [editDrawer]);

    return (
        <div style={{ maxWidth: '800px', padding: '0 20px', margin: '40px auto' }}>
            <h3>Custom Fields</h3>
            <p className="subtle">Custom fields enable you to capture unique attendee information specific to your event, such as meal preferences or access requirements.</p>
            <hr />

            {/* <ul>
            {fieldsQuery.isFetched && 
                fieldsQuery.data?.items.map(field => (
                    <li>{field.name} {field.type}</li>
                ))
            }
            <li>Add Custom Field</li>
            </ul> */}

            <Table
                items={fieldsQuery.data.items}
                loading={fieldsQuery.isFetched === false}
                config={tableConfig}
                onConfigChange={setTableConfig}
            />

            <hr />

            <div style={{ marginTop: '20px' }}>
                <button onClick={() => editItem(null)} >Add Custom Field</button>
            </div>

            <EditCustomFieldDrawer 
                open={editDrawer.isOpen}
                onDismiss={editDrawer.close}
                customFieldId={selectedItem?.id}
                onSave={() => {
                    editDrawer.close();
                    fieldsQuery.refetch();
                }}
            />

        </div>
    );
}