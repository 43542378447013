
import { Navigate, Route, Routes } from 'react-router-dom';
import { Page, PageContent, PageHeader } from "../../components/Page";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { PageTabs } from "../../components/PageTabs";
import { AppRoutes } from "../../constants/AppRoutes";
import { GeneralSettingsPage } from './GeneralSettingsPage';
import { WebhookSettingsPage } from './WebhookSettingsPage';
import { CustomFieldsSettingsPage } from './CustomFieldsSettingsPage';

export const EventSettingsRoot = () => 
{
    const { organization, event } = useCurrentContext();

    const tabs = [
        { text: 'General', href: AppRoutes.Event.Settings.General(organization.id, event.id) },
        { text: 'Custom Fields', href: AppRoutes.Event.Settings.CustomFields(organization.id, event.id) },
        { text: 'Emails', href: AppRoutes.Event.Settings.Emails(organization.id, event.id) },
        { text: 'Notifications', href: AppRoutes.Event.Settings.Notifications(organization.id, event.id) },
        { text: 'Permissions', href: AppRoutes.Event.Settings.Permissions(organization.id, event.id) },
        { text: 'Webhooks', href: AppRoutes.Event.Settings.Webhooks(organization.id, event.id) },
        { text: 'Advanced', href: AppRoutes.Event.Settings.Advanced(organization.id, event.id) }
    ];

    return (
        <Page>
            <PageHeader>
                <h2>Event Settings</h2>
                <PageTabs tabs={tabs} />
            </PageHeader>
            <PageContent>
                <Routes>
                    <Route path="general" element={<GeneralSettingsPage />} />
                    <Route path="fields" element={<CustomFieldsSettingsPage />} />
                    <Route path="webhooks" element={<WebhookSettingsPage />} />
                    <Route path="" element={<Navigate to="general" />} />
                </Routes>
            </PageContent>
        </Page>
    );
}
