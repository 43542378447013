import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom"

import "./GridMenu.scss";

export interface GridMenuProps {
    open: boolean;
    anchorEl?: HTMLElement;
    anchorPosition?: { x: number, y: number };
    children: React.ReactNode;
    onRequestClose: () => void;
};

export const GridMenu = (props: GridMenuProps) => {

    const { open, children, anchorEl, anchorPosition, onRequestClose } = props;
    const selfRef = useRef<HTMLDivElement>(null);

    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    useEffect(() => {
        const handleMouseDown = (e: MouseEvent) => {
            if(open)
            {
                const el: HTMLElement = e.target as HTMLElement;
                if(!el.closest('.grid-menu'))
                    onRequestClose?.();
            }
        };

        document.body.addEventListener('mousedown', handleMouseDown, true);

        return () => {
            document.body.removeEventListener('mousedown', handleMouseDown, true);
        };
    }, [open, onRequestClose]);

    useEffect(() => {

        if(open)
        {
            let x = 0;
            let y = 0;

            if(anchorEl && selfRef.current)
            {
                const anchorRect = anchorEl.getBoundingClientRect();
                const self = selfRef.current.getBoundingClientRect();
                x = Math.round(anchorRect.x);
                y = Math.round(anchorRect.y + anchorRect.height);

                if(anchorRect.width < self.width && (x + self.width) > window.innerWidth)
                    x -= (self.width - anchorRect.width);
                if(anchorRect.height < self.height && (y + self.height) > window.innerHeight)
                    y -= (self.height + anchorRect.height);
            }
            else if(anchorPosition)
            {
                x = anchorPosition.x;
                y = anchorPosition.y;

                if(selfRef.current)
                {
                    const self = selfRef.current.getBoundingClientRect();
                    if((x + self.width) > window.innerWidth)
                        x -= self.width;
                    if((y + self.height) > window.innerHeight)
                        y -= self.height;
                }
            }
            else
            {
                console.warn('GridMenu: You must specify an anchorEl or anchorPosition');
            }

            setX(x);
            setY(y);
        }

    }, [anchorEl, anchorPosition, selfRef, open]);

    if(open)
    {
        return (
            createPortal(
                <div ref={selfRef} className="grid-menu" style={{ transform: `translate(${x}px, ${y}px)` }}>
                    {children}
                </div>
            , document.body)
        );
    }
    else
    {
        return null;
    }
};