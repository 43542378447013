import Shift from "./Shift";

export default class Zone
{
    public id: string;
    public event: string;
    public title: string;

    public shifts: Shift[];
    public activeShifts: Shift[]; // Not actually on model

    constructor()
    {
        this.shifts = null;
        this.activeShifts = null;
    }

    public static FromJson(json: any): Zone
    {
        let obj = new Zone();
        obj.id = json.id || json._id;
        obj.title = json.title;
        obj.event = json.event;

        if(json.shifts)
        {
            obj.shifts = json.shifts.map((s) =>
            {
                return Shift.FromJson(s);
            });

            obj.activeShifts = obj.shifts.filter(s => !s.endTime);
        }

        return obj;
    }

    // public getSlug()
    // {
    //     return this.id;
    // }
}
