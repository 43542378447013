import { useCallback, useState } from "react";
import { CellProps } from "./CellProps";

import "./SelectCell.scss";

export interface SelectOption
{
    key: string;
    text?: string;
}

export interface SelectCellProps extends CellProps<string> 
{
    options: SelectOption[];
}

export const SelectCell = (props: SelectCellProps) =>
{
    const { value, selected, options = [], onSave, readOnly } = props;
    const [internalValue, setInteralValue] = useState(value);
    const [error, setError] = useState('');

    const onChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => 
    {
        setInteralValue(e.target.value);
        onSave(e.target.value);
    }, [onSave]);

    let internalOptions = options;
    if(!options.find(o => o.key === value))
        internalOptions = [{ key: value }, ...options];

    return (
        <div className="cell-select">
            {(!selected || readOnly) && <div style={{ padding: '5px' }} title={internalValue}>{internalValue}</div>}
            {(selected && !readOnly) && 
                <select onChange={onChange}>
                    { internalOptions.map(option => (
                        <option selected={option.key === internalValue} key={option.key} value={option.key}>{option.text || option.key}</option>
                    ))}
                </select>
            }
            {error}
        </div>
    );
};
