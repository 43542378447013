import { Component } from "react";
import { Event } from "../dto/Event";
import Checkpoint from "../dto/Checkpoint";
import { TrackerAPI } from "../api/TrackerAPI";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { DialogSpinner } from "../components/DialogSpinner";

export interface CheckpointDialogProps 
{
    isOpen: boolean;
    type: FormType;
    onRequestClose?: any;
    onItemCreated?: (c: Checkpoint) => void;
    onItemUpdated?: (c: Checkpoint) => void;
    onError?: (e: any) => void;

    event?: Event; // Required for new zone
    checkpoint?: Checkpoint; // Required for update checkpoint
}

export interface CheckpointDialogState
{
    modalTitle: string;
    title: string;
    isLoading: boolean;
}

export enum FormType
{
    New,
    Edit
}

export class CheckpointDialog extends Component<CheckpointDialogProps, CheckpointDialogState>
{
    constructor(props: CheckpointDialogProps)
    {
        super(props);

        this.state = {
            modalTitle: 'Modal Dialog',
            isLoading: false,
            title: ''
        };
    }

    public componentWillReceiveProps(nextProps: CheckpointDialogProps)
    {
        this.updateState(nextProps);
    }

    private updateState(nextProps: CheckpointDialogProps)
    {
        if(nextProps.type === FormType.New)
        {
            this.setState({
                modalTitle: 'New Checkpoint',
                title: ''
            });
        }
        else if(nextProps.type === FormType.Edit && nextProps.checkpoint)
        {
            this.setState({
                modalTitle: 'Edit Checkpoint',
                title: nextProps.checkpoint.title
            });
        }
    }

    public render() 
    {
        return (
            <Dialog
                maxWidth="sm"
                open={this.props.isOpen}
                title={this.state.modalTitle}
                onClose={this.props.onRequestClose}>
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <DialogTitle className="bm-dialog-title">{this.state.modalTitle}</DialogTitle>
                    <form className="bm-dialog-form" onSubmit={this.onSaveClick.bind(this)} style={{ minWidth: '460px' }}>
                        <DialogContent>
                            <FormControl margin="dense" fullWidth>
                                <FormLabel>Name</FormLabel>
                                <TextField
                                    value={this.state.title}
                                    onChange={(e) => this.setState({ title: e.target.value })}
                                    autoFocus
                                    autoComplete="off"
                                    placeholder="e.g. Main Entrance, Parking Garage Elevators"
                                    variant="outlined"
                                    required
                                    disabled={this.state.isLoading}
                                />
                            </FormControl>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.props.onRequestClose()}>Cancel</Button>
                            <Button type="submit" color="primary" variant="contained">{this.props.type === FormType.New ? 'Create' : 'Save'}</Button>
                        </DialogActions>
                    </form>
                </DialogSpinner>
            </Dialog>
        );
    }

    private onSaveClick(e)
    {
        if (this.state.title)
        {
            if(this.props.type === FormType.New)
            {
                let c = new Checkpoint();
                c.event = this.props.event.id;
                c.title = this.state.title.trim();

                TrackerAPI.GetCurrent().CreateCheckpoint(this.props.event.id, c).then((response) => 
                {
                    this.onItemCreated(response);
                });
            }
            else if(this.props.type === FormType.Edit)
            {
                let checkpoint = this.props.checkpoint;
                checkpoint.title = this.state.title.trim();
    
                TrackerAPI.GetCurrent().UpdateCheckpoint(this.props.event.id, checkpoint).then((response) => 
                {
                   this.onItemUpdated(response); 
                });
            }

            this.setState({ isLoading: true });
        }
        else
        {
            this.forceUpdate();
        }

        e.preventDefault();
    }

    private onItemCreated(c: Checkpoint)
    {
        if(this.props.onItemCreated)
            this.props.onItemCreated(c);

        this.setState({ isLoading: false });
        
        if(this.props.onRequestClose)
            this.props.onRequestClose();
    }
    
    private onItemUpdated(c: Checkpoint)
    {
        if (this.props.onItemUpdated)
            this.props.onItemUpdated(c);

        this.setState({ isLoading: false });

        if(this.props.onRequestClose)
            this.props.onRequestClose();
    }
}
