import { Component, ComponentProps } from "react";

import "./ErrorBoundary.scss";

export interface ErrorBoundaryState 
{
    hasError: boolean;
    showDetails: boolean;
    errorDetails: string;
}

export class ErrorBoundary extends Component<ComponentProps<any>, ErrorBoundaryState>
{
    constructor(props: any)
    {
        super(props);
        this.state = { 
            hasError: false,
            showDetails: false,
            errorDetails: ''
        };
    }

    static getDerivedStateFromError(error) 
    {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    
    componentDidCatch(error, errorInfo) 
    {
        // You can also log the error to an error reporting service
        console.error("Uncaught error:", error, errorInfo);
        this.setState({ errorDetails: error.toString() + '\n' + errorInfo.componentStack });
    }
    
    render() 
    {
        if (this.state.hasError) 
        {
            // You can render any custom fallback UI
            return (
                <div className="error-boundary">
                    <h1>Oops, something went wrong.</h1>
                    <p>Try refreshing the page. If the issue persists, try again later or let us know at <a href="mailto:info@badgemeister.com">info@badgemeister.com</a></p>
                    <a href="#" onClick={() => this.setState({ showDetails: !this.state.showDetails })}>Show Details</a>
                    <p style={{ display: this.state.showDetails ? 'block' : 'none' }}>{this.state.errorDetails}</p>
                </div>
            );
        }
    
        return this.props.children;
    }
}