import { useCallback } from "react";
import { Drawer, DrawerProps } from "../../components/Drawer";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { useQuery } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { useAPI } from "../../hooks/useAPI";
import { copyToClipboard } from "../../utils/Utils";
import { Tab, Tabs } from "../../components/Tabs";
import { BMDisplayField } from "../../components/Fields/BMDisplayField";
import RegToken from "../../dto/RegToken";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./ViewTokenDrawer.scss";

const BASE_FORM_URL = process.env.REACT_APP_TOKEN_LINK_URL;

export interface ViewTokenDrawerProps extends DrawerProps
{
    tokenId: string;
}

export const ViewTokenDrawer = (props: ViewTokenDrawerProps) => 
{
    const { open, onSave, onDismiss, tokenId } = props;
    const { eventId, isLoaded } = useCurrentContext();
    const api = useAPI();

    const tokenQuery = useQuery(QueryKeys.RegTokens.GetById(eventId, tokenId), () => api.events.getById(eventId).regTokens.getById(tokenId).get(), { enabled: isLoaded && !!eventId && !!tokenId, initialData: null });
    const token = tokenQuery.data;

    return (
        <Drawer title={token?.name || token?.email || ''} loading={tokenQuery.isFetching} {...props}>
            <div className="view-token-drawer">
                {/* <div className="activity-drawer-header">
                    <h1>{activity?.title}</h1>
                    <div className="subtle">{activity?.location}</div>
                </div> */}
                {/* <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
                    <button>Edit</button>
                </div> */}
                <Tabs>
                    <Tab label="Details">
                        <ActivityDetailsTab eventId={eventId} item={token} />
                    </Tab>
                    {/* <Tab label="Checked-in">
                        <CheckedInTab eventId={eventId} regItem={regItem} />
                    </Tab> */}
                </Tabs>
            </div>
        </Drawer>
    );


// <Tab label="Activity Log">
//     <ActivityLogTab eventId={eventId} regItem={regItem} />
// </Tab>
}

const ActivityDetailsTab = (props: { eventId: string, item: RegToken }) =>
{
    const api = useAPI();
    const { eventId, item } = props;

    //const startTimeStr = isValid(new Date(regItem?.startTime)) ? format(new Date(activity?.startTime), 'PP p') : '';
    //const endTimeStr = isValid(new Date(activity?.endTime)) ? format(new Date(activity?.endTime), 'PP p') : '';

    const linkUrl = `${BASE_FORM_URL}?t=${item?.token}`;

    const copyLink = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        copyToClipboard(`${BASE_FORM_URL}?t=${item.token}`);
        e.preventDefault();
    }, [item]);

    return (
        <div className="details-tab">
            <div className="details-tab-grid" style={{  }}>
                <BMDisplayField label="Name" value={item?.name} layout="vertical" />
                <BMDisplayField label="Email" value={item?.email} layout="vertical" />

                <BMDisplayField label="Form Link" value={
                    <a href={linkUrl} onClick={copyLink}>Copy to clipboard <ContentCopyIcon fontSize="inherit" style={{ fontSize: '12px' }} /></a>
                } layout="vertical" />
            </div>
        </div>
    );
}
