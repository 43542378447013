import { Query } from "./Query";
import BaseQuery from "./BaseQuery";
import RegItem from "../dto/RegItem";

export default class RegItemQuery extends BaseQuery<RegItem>
{
    constructor(rootUrl?: string)
    {
        super((rootUrl ?? '') + '/reg/items');
        this.query = new Query<RegItem>(this.rootUrl);
    }
}