import { Component } from "react";
import { render, unmountComponentAtNode, createPortal } from "react-dom";

export const Portal = (props: any) => {
    return createPortal(props.children, document.body);
}

// export interface PortalProps
// {

// }

// export interface PortalState
// {

// }

// export class Portal extends Component<PortalProps, PortalState>
// {
//     private popup: HTMLDivElement;

//     constructor(props: PortalProps)
//     {
//         super(props);

//         this.state = {
//         };
//     }

//     public componentDidMount() 
//     {
//         this.popup = document.createElement('div');

//         document.body.appendChild(this.popup);
//     }

//     public componentDidUpdate()
//     {
//         this.renderLayer();
//     }

//     public componentWillUnmount()
//     {
//         unmountComponentAtNode(this.popup);
//         document.body.removeChild(this.popup);
//     }

//     private renderLayer()
//     {
//         render(
//             <div {...this.props}>
//                 {this.props.children}
//             </div>,
//             this.popup
//         );
//     }

//     public render() 
//     {
//         return null;
//     }
// }
