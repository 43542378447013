import { Component } from "react";
import { Event } from "../../dto/Event";
import {Organization} from "../../dto/Organization";
import Zone from "../../dto/Zone";
import { Button } from "../../components/Button";
import Store from "../../store/Store";
import { StoreActions } from "../../store/StoreActions";
import { TabbedPage } from "../../components/TabbedPage";
import { Tab } from "../../components/Tab";
import { ZonesWithContext } from "./Zones";
import { SupervisorsWithContext } from "./Supervisors";
import { VolunteersWithContext as VolunteersTabWithContext } from "./Volunteers";
import OrganizationContext from "../../context/OrganizationContext";
import EventContext from "../../context/EventContext";

export const VolunteersPage = (props) =>
{
    return null;
};

// export interface VolunteersProps extends RouteComponentProps<any>
// {
//     organization: Organization;
//     event: EventModel;
// }

// export interface VolunteersState
// {
//     newEventName: string;
//     newEventStartTime: Date;
//     newEventEndTime: Date;
//     searchText: string;
//     isModalOpen: boolean;
//     isLoading: boolean;

//     zones: Zone[];
// }

// export class VolunteersPageInternal extends Component<VolunteersProps, VolunteersState>
// {
//     constructor(props: VolunteersProps)
//     {
//         super(props);

//         let savedSearchText: string;
//         if (sessionStorage)
//         {
//             savedSearchText = sessionStorage.getItem('events-searchtext');
//         }

//         this.state = {
//             newEventName: '',
//             newEventStartTime: null,
//             newEventEndTime: null,
//             searchText: savedSearchText || '',
//             isModalOpen: false,
//             isLoading: false,
//             zones: null
//         };

//         this.onStoreUpdated = this.onStoreUpdated.bind(this);
//     }

//     public componentDidMount()
//     {
//         window.scrollTo(0, 0);
//         Store.GetCurrent().AddChangeListener(this.onStoreUpdated);

//         if(!this.props.event)
//             this.setState({ isLoading: true });
//     }

//     public componentWillUnmount()
//     {
//         Store.GetCurrent().RemoveChangeListener(this.onStoreUpdated);
//     }

//     public onStoreUpdated()
//     {
//         if(this.props.event)
//             this.setState({ isLoading: false });
//     }

//     public componentWillReceiveProps(nextProps: VolunteersProps)
//     {
//         if(nextProps.event)
//             this.setState({ isLoading: false });
//     }

//     public render()
//     {
//         let pageTitle = "Volunteers | Badgemeister";
//         if(this.props.event)
//             pageTitle = `Volunteers - ${this.props.event.title} | Badgemeister`;

//         let actionBarItems = [
//             <Button key="0" onClick={() => { StoreActions.GetEvents(this.props.organization.id); this.setState({ isLoading: true }); }}><i className="fa fa-sync-alt" /></Button>
//         ];

//         return (
//             <TabbedPage title="Volunteers" pageTitle={pageTitle} className="volunteers" actionBarItems={actionBarItems} framed>
//                 <Tab title="Zones">
//                     <ZonesWithContext />
//                 </Tab>

//                 <Tab title="Supervisors">
//                     <SupervisorsWithContext />
//                 </Tab>

//                 <Tab title="Volunteers">
//                     <VolunteersTabWithContext />
//                 </Tab>
//             </TabbedPage>
//         );
//     }

//     private onEventCreated(eventId: string)
//     {
//         this.props.history.push('/events/' + eventId + '/overview');
//     }

//     private onSearch(searchText)
//     {
//         this.setState({ searchText: searchText });
//         if (sessionStorage)
//         {
//             sessionStorage.setItem('events-searchtext', searchText);
//         }
//     }

//     private containsString(z: Zone, value: string)
//     {
//         let zoneString: string = z.title;
//         return zoneString.toLowerCase().indexOf(value.toLowerCase()) >= 0;
//     }
// }

// export function VolunteersPage(props)
// {
//     return (
//         <OrganizationContext.Consumer>
//             { orgCtx => 
//                 <EventContext.Consumer>
//                     { ctx => <VolunteersPageInternal organization={orgCtx.organization} event={ctx.event} {...props} /> }
//                 </EventContext.Consumer>
//             }
//         </OrganizationContext.Consumer>
//     );
// }
