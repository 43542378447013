import { ComponentProps, createContext, useState } from "react";
import { useToggle } from "../hooks/useToggle";
import { Alert, Snackbar } from "@mui/material";

export interface ToastConfig
{
    message: string;
    durationInMs?: number;
    type: 'info' | 'success' | 'warning' | 'error';
}

export interface DialogContextInterface
{
    showToast: (config: ToastConfig) => void;
    hideToast: () => void;
}

export const ToastContext = createContext<DialogContextInterface>({ 
    showToast: null, 
    hideToast: null
});

export const ToastProvider = (props: ComponentProps<any>) => 
{
    const { children } = props;
    const toast = useToggle(false);
    const [toastConfig, setToastConfig] = useState<ToastConfig>({
        message: '',
        durationInMs: 0,
        type: 'info'
    });

    const showToast = (config: ToastConfig) =>
    {
        setToastConfig(config);
        toast.open();
    }

    const hideToast = () =>
    {
        toast.close();
    }

    return (
        <ToastContext.Provider value={{ showToast, hideToast }}>
            {children}
            <Snackbar
                open={toast.isOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={toastConfig?.durationInMs || 6000}
                onClose={toast.close}>
                    <Alert severity={toastConfig?.type} onClose={toast.close}>{toastConfig?.message}</Alert>
            </Snackbar>
        </ToastContext.Provider>
    );
}

