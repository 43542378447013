import { KeyboardEvent, useCallback, useRef, useState } from "react";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import { CellProps } from "./CellProps";
import { usePrevious } from "../usePrevious";

import "./TextCell.scss";
import { GridCell } from "./GridCell";
import { isTextCharacter } from "../../../utils/Utils";

export interface TextCellProps extends CellProps<string> { }

export const TextCell = (props: TextCellProps) =>
{
    const { value, selected, required, readOnly = false, onSave } = props;
    const [internalValue, setInteralValue] = useState(value);
    const prevSelected = usePrevious(selected);

    const [dirty, setDirty] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);

    const saveValue = useCallback(async (value: string) => {
        if(dirty)
        {
            await Promise.resolve(onSave?.(value) || '');
            setDirty(false);
        }
        setEditing(false);
    }, [onSave, dirty]);

    useAsyncEffect(async () => {
        if(prevSelected && !selected)
            await saveValue(internalValue);
    }, [prevSelected, selected, saveValue]);

    const onDoubleClick = useCallback(() => {
        if(!readOnly)
        {
            setEditing(true);
            setTimeout(() => inputRef.current?.focus());
        }
    }, [inputRef, readOnly]);

    const onKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
        if(!editing && isTextCharacter(e.key) && !readOnly)
        {
            setEditing(true);
            setInteralValue(e.key);
            setTimeout(() => inputRef.current?.focus());
        }
        else if(editing && e.key === 'Enter')
        {
            saveValue(internalValue);
        }
    }, [editing, internalValue, inputRef, saveValue]);

    const onTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInteralValue(e.target.value);
        setDirty(true);
    }, []);

    return (
        <GridCell className="cell-text" readOnly={readOnly} onDoubleClick={onDoubleClick} onKeyDown={onKeyDown}>
            {!editing && <div className="text-display ellipsis" title={internalValue}>{internalValue}</div>}
            {editing && <input type="text" value={internalValue} ref={inputRef} onChange={onTextChange} />}
            {error}
        </GridCell>
    );
};
