import { weeksToDays } from "date-fns"

export const QueryKeys = {
    Users: {
        Current: () => ['user', 'current']
    },
    Orgs: {
        All: () => ['orgs', 'all'],
        Id: (orgId: string) => [`org-${orgId}`],
        Events: (orgId: string) => [`org-${orgId}-events`]
    },
    Events: {
        All: () => ['events', 'all'],
        ForOrg: (orgId: string) => [`org-${orgId}`, 'events', 'all'],
        Id: (eventId: string) => ['events', `event-${eventId}`],
        Views: (eventId: string) => [`event-${eventId}`, 'views'],
        Fields: {
            All: (eventId: string) => [`event-${eventId}`, 'fields', 'all']
        }
    },
    Attendees: {
        All: (eventId: string, query?: string) => [`event-${eventId}`, `attendees`, `query-${query}`],
        Archived: (eventId: string, query?: string) => [`event-${eventId}`, `attendees`, `query-${query}`, `archived`],
        GetById: (eventId: string, attendeeId: string) => [`event-${eventId}`, `attendee-${attendeeId}`]
    },
    Activities: {
        All: (eventId: string, query?: string) => [`event-${eventId}`, `activities`, `query-${query}`],
        Id: (eventId: string, activityId: string) => [`event-${eventId}`, `activity-${activityId}`],
        Checkins: (eventId: string, activityId: string) => [`event-${eventId}`, `activity-${activityId}`, 'checkins']
    },
    RegTiers: {
        All: (eventId: string, config: string = '', search: string = '') => [`event-${eventId}`, 'regtiers', 'all', `config-${config}`, `search-${search}`],
        GetById: (eventId: string, regTierId: string) => [`event-${eventId}`, `regtier-${regTierId}`]
    },
    RegAddOns: {
        All: (eventId: string, config: string = '', search: string = '') => [`event-${eventId}`, 'addons', 'all', `config-${config}`, `search-${search}`],
        GetById: (eventId: string, regAddOnId: string) => [`event-${eventId}`, `addon-${regAddOnId}`]
    },
    RegItems: {
        All: (eventId: string) => [`event-${eventId}`, 'regitems', 'all'],
        GetById: (eventId: string, regItemId: string) => [`event-${eventId}`, `regitem-${regItemId}`]
    },
    RegTokens: {
        All: (eventId: string, config: string = '', search: string = '') => [`event-${eventId}`, 'regtokens', 'all', `config-${config}`, `search-${search}`],
        GetById: (eventId: string, regTokenId: string) => [`event-${eventId}`, `regtoken-${regTokenId}`]
    },
    CustomFields: {
        All: (eventId: string) => [`event-${eventId}`, 'customfields', 'all'],
        GetById: (eventId: string, fieldId: string) => [`event-${eventId}`, `customfield-${fieldId}`]
    }
}
