
import { Navigate, Route, Routes } from 'react-router-dom';
import { Page, PageContent, PageHeader } from "../../components/Page";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { PageTabs } from "../../components/PageTabs";
import { AppRoutes } from "../../constants/AppRoutes";
import { RegTierPage } from './RegTierPage';
import { AddOnPage } from './AddOnPage';
import { TokensPage } from './TokensPage';

export const RegistrationRoot = () => 
{
    const { organization, event } = useCurrentContext();

    const tabs = [
        { text: 'Registration Tiers', href: AppRoutes.Event.Registration.Tiers(organization.id, event.id) },
        { text: 'Add-Ons', href: AppRoutes.Event.Registration.Addons(organization.id, event.id) },
        { text: 'Tokens', href: AppRoutes.Event.Registration.Tokens(organization.id, event.id) }
    ]

    return (
        <Page>
            <PageHeader>
                <h2>Registration</h2>
                <PageTabs tabs={tabs} />
            </PageHeader>
            <PageContent>
                <Routes>
                    <Route path="tiers" element={<RegTierPage />} />
                    <Route path="addons" element={<AddOnPage />} />
                    <Route path="tokens" element={<TokensPage />} />
                    <Route path="" element={<Navigate to="tiers" />} />
                </Routes>
            </PageContent>
        </Page>
    );
}
