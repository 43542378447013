import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

export interface BMButtonProps extends React.InputHTMLAttributes<HTMLButtonElement>
{
    
}

export const BMButton = (props: BMButtonProps) => 
{
    const { type, children = 'Close', disabled, ...otherProps } = props;
    const context = useFormikContext();

    return (
        <button type={(type as any) || 'button'} disabled={disabled || context.isSubmitting} {...otherProps}>{children}</button>
    );
};
