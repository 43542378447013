import { BM } from "../api/BM";
import { QueryParams } from "../api/Query";
import { Activity } from "../dto/Activity";

export interface GetActivitiesRequest {
    orderBy: { field: string, direction: 'asc' | 'desc' },
    skip: number;
    top: number;
    search: string;
}

export const GetActivities = (eventId: string, params?: QueryParams) => {
    return BM.client.events.getById(eventId).activities.get(params);
}

export const GetActivity = (eventId: string, activityId: string) => {
    return BM.client.events.getById(eventId).activities.getById(activityId).get();
}

export const CreateActivity = (eventId: string, activityData: Partial<Activity>) => {
    return BM.client.events.getById(eventId).activities.add(activityData);
}

export const UpdateActivity = (eventId: string, activityId: string, data: Partial<Activity>) => {
    return BM.client.events.getById(eventId).activities.getById(activityId).update(data);
}

export const DeleteActivity = (eventId: string, activityId: string) => {
    return BM.client.events.getById(eventId).activities.getById(activityId).delete();
}