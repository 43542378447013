import { useCallback, KeyboardEvent, useRef, useState } from "react";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import { CellProps } from "./CellProps";
import { usePrevious } from "../usePrevious";

import "./NumberCell.scss";
import { GridCell } from "./GridCell";
import { isTextCharacter } from "../../../utils/Utils";

export interface NumberCellProps extends CellProps<number | null> 
{
    integer?: boolean;
    allowNegative?: boolean;
    precision?: number;
}

export const NumberCell = (props: NumberCellProps) =>
{
    const { value, selected, onSave, readOnly, integer = true, allowNegative = true, precision = 0 } = props;
    const [internalValue, setInteralValue] = useState<string>(value?.toString() ?? '');
    const prevSelected = usePrevious(selected);

    const [dirty, setDirty] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);

    const saveValue = useCallback(async (strValue: string) => {

        const numberValue = parseFloat(strValue.replaceAll(/[^0-9.]/gi, ''));

        if(dirty)
        {
            await Promise.resolve(onSave?.(numberValue));
            setDirty(false);
        }
        setEditing(false);

        setInteralValue(isNaN(numberValue) ? '' : numberValue.toString());

    }, [onSave, dirty]);

    useAsyncEffect(async () => {
        if(prevSelected && !selected)
        {
            await saveValue(internalValue);
        }
    }, [prevSelected, selected, saveValue, internalValue]);

    const onDoubleClick = useCallback(() => {
        if(!readOnly)
        {
            setEditing(true);
            setTimeout(() => inputRef.current?.focus());
        }
    }, [inputRef]);

    const onKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
        if(!editing && isTextCharacter(e.key) && !readOnly)
        {
            setEditing(true);
            setInteralValue(e.key);
            setTimeout(() => inputRef.current?.focus());
        }
        else if(editing && e.key === 'Enter')
        {
            saveValue(internalValue);
        }
    }, [editing, internalValue, inputRef, saveValue]);

    return (
        <GridCell className="cell-number" onDoubleClick={onDoubleClick} onKeyDown={onKeyDown}>
            {!editing && <div className="text-display ellipsis">{internalValue}</div>}
            {editing && <input type="text" inputMode="numeric" pattern="[0-9\.]*" value={internalValue} ref={inputRef} onChange={e => { setInteralValue(e.target.value); setDirty(true); }} />}
            {error}
        </GridCell>
    );
};
