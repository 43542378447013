export const GET_ACCOUNT = "account.current.get";

export const GET_ORGANIZATIONS = "orgs.get.all";
export const GET_ORGANIZATION = "orgs.get";
export const CREATE_ORGANIZATION = "orgs.create";

export const GET_EVENTS = "events.get.all";
export const GET_EVENT = "events.get";
export const CREATE_EVENT = "events.create";
export const DELETE_EVENT = "events.delete";
export const ARCHIVE_EVENT = "events.archive";
export const UPDATE_EVENT = "events.update";

export const CREATE_SESSION = "sessions.create";
export const DELETE_SESSION = "sessions.delete";
export const ARCHIVE_SESSION = "sessions.archive";
export const UPDATE_SESSION = "sessions.update";

export const GET_ACTIVITIES = "activities.get.all";
export const CREATE_ACTIVITY = "activities.create";
export const DELETE_ACTIVITY = "activities.delete";
export const ARCHIVE_ACTIVITY = "activities.archive";
export const UPDATE_ACTIVITY = "activities.update";

export const FETCH_CHECKINS = "fetch-checkins";

export const GET_PEOPLE = "person.get.all";
export const GET_PERSON = "person.get";
export const CREATE_PERSON = "person.create";
export const DELETE_PERSON = "person.delete";
export const ARCHIVE_PERSON = "person.archive";
export const UPDATE_PERSON = "person.update";

export const GET_ATTENDEES = "attendee.get.all";
export const GET_ATTENDEE = "attendee.get";
export const CREATE_ATTENDEE = "attendee.create";
export const DELETE_ATTENDEE = "attendee.delete";
export const ARCHIVE_ATTENDEE = "attendee.archive";
export const UPDATE_ATTENDEE = "attendee.update";

export const GET_ZONES = "zones.get.all";
export const GET_ZONE = "zones.get";
export const CREATE_ZONE = "zones.create";
export const DELETE_ZONE = "zones.delete";
export const ARCHIVE_ZONE = "zones.archive";
export const UPDATE_ZONE = "zones.update";

export const GET_SHIFTS = "shifts.get.all";
export const GET_SHIFT = "shifts.get";
export const CREATE_SHIFT = "shifts.create";
export const UPDATE_SHIFT = "shifts.update";
export const ARCHIVE_SHIFT = "shifts.archive";
export const DELETE_SHIFT = "shifts.delete";