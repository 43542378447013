import { Component } from "react";
import {Organization} from "../../dto/Organization";
import User from "../../dto/User";
import { Event } from "../../dto/Event";
import Attendee from "../../dto/Attendee";
import { SearchBox } from "../../components/SearchBox";
import { AppEvent } from "../../events/AppDispatcher";
import OrganizationContext from "../../context/OrganizationContext";
import EventContext from "../../context/EventContext";
import { MaterialTable } from "../../components/MaterialTable";
import { ConfirmDialog } from "../../dialogs/ConfirmDialog"

import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import { TrackerAPI } from "../../api/TrackerAPI";
import { Header } from "../../components/Header";
import { Page } from "../../components/Page";
import { toDateString } from "../../utils/DateUtils";

export interface CheckpointAttendeesProps
{
    user: User;
    organization: Organization;
    event: Event;
}

export interface CheckpointAttendeesState
{
    searchText: string;
    isCheckoutAllOpen: boolean;
    isCheckoutOpen: boolean;
    isModalOpen: boolean;
    isEditModalOpen: boolean;
    loading: boolean;

    attendees: Attendee[];
    selectedAttendee: Attendee;
    page: number;
    rowsPerPage: number;
    count: number;
}

export class CheckpointAttendeesInternal extends Component<CheckpointAttendeesProps, CheckpointAttendeesState>
{
    private timeoutId = null;

    constructor(props: CheckpointAttendeesProps)
    {
        super(props);

        let savedSearchText: string;
        if (sessionStorage)
            savedSearchText = sessionStorage.getItem('checkpointattendees-searchtext');

        this.state = {
            searchText: savedSearchText || '',
            isCheckoutAllOpen: false,
            isCheckoutOpen: false,
            isModalOpen: false,
            isEditModalOpen: false,
            selectedAttendee: null,
            loading: false,
            attendees: [],
            page: 0,
            rowsPerPage: 50,
            count: 0
        };
    }

    public componentDidMount()
    {
        if(this.props.event)
        {
            if(this.state.searchText)
                this.refreshSearch(this.props.event.id, this.state.searchText);
            else
                this.refreshList(this.props.event.id, this.state.page, this.state.rowsPerPage);
        }
    }

    public componentWillUnmount()
    {
    }

    public componentDidUpdate(prevProps: CheckpointAttendeesProps, prevState: CheckpointAttendeesState)
    {
        if(this.state.searchText)
        {
            if(this.props.event && !prevProps.event
                || this.state.searchText !== prevState.searchText)
                this.refreshSearch(this.props.event.id, this.state.searchText);
        }
        else
        {
            if(this.props.event && !prevProps.event)
                this.refreshList(this.props.event.id, this.state.page, this.state.rowsPerPage);
    
            if(this.props.event && (this.state.page !== prevState.page 
                || this.state.rowsPerPage !== prevState.rowsPerPage
                || !this.state.searchText && prevState.searchText))
                this.refreshList(this.props.event.id, this.state.page, this.state.rowsPerPage);
        }
    }

    public render()
    {
        let attendees: Attendee[] = [];
        let pageTitle = 'Checked-in Attendees | Badgemeister';

        let attendeeColumns = [
            {
                key: 'firstName',
                label: 'Name',
                render: (data, item) => { return item.Name(); }
            },
            {
                key: 'email',
                label: 'Email'
            },
            {
                key: 'createdAt',
                label: 'Date Registered',
                render: (date: Date) => { return toDateString(date) }
            }
        ];

        if(this.state.attendees)
        {
            attendees = this.state.attendees;
        }

        let actionBarItems = [
            <Button key="0" className="mui-toolbar-button" onClick={() => { this.refreshList(this.props.event.id, this.state.page, this.state.rowsPerPage); }}><i className="fa fa-sync-alt" /></Button>,
            // <Button key="1"><i className="fa fa-ellipsis-h" /></Button>
        ];

        let toolbar = (
            <Toolbar>
                <SearchBox
                    value={this.state.searchText}
                    onChange={this.onSearch.bind(this)}
                    placeholder="Search by name" 
                    style={{ marginRight: '20px', flex: 1 }}/>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {}}>
                    Check In
                </Button>

                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ isCheckoutAllOpen: true })}>
                    Check Out All
                </Button> */}
            </Toolbar>
        );

        return (
            <Page pageTitle={pageTitle} className="page-checkpoint-attendees">
                <Header>
                    <h1>Attendees List</h1>
                </Header>
                <div>
                    {/* <InfoCard 
                        title="Active Attendee List"
                        content="The active attendee list shows all attendees that are currently checked into your event. If an attendee has forgotten or is otherwise unable to check out you can check them out manually here." /> */}

                    <MaterialTable
                        allowSelection={false}
                        columns={attendeeColumns}
                        loading={this.state.loading}
                        toolbar={toolbar}
                        showMenuButton={true}
                        menuButtonsForRow={this.onMenuButtonsForRow.bind(this)}

                        items={attendees}
                        totalItems={this.state.count}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        rowsPerPageOptions={[50,100,250]}
                        onChangePage={(e, page) => { this.setState({ page: page }); }}
                        onChangeRowsPerPage={(e, rows) => { this.setState({ rowsPerPage: rows }); }}
                    />

                    <ConfirmDialog
                        isOpen={this.state.isCheckoutOpen}
                        title={this.state.selectedAttendee ? `Check out ${this.state.selectedAttendee.getName()}` : ''}
                        message="Are you sure you want to check out this attendee?"
                        buttonText="Check Out"
                        onRequestClose={() => { this.setState({ isCheckoutOpen: false }); }}
                        onConfirm={() => { this.onCheckoutAttendee(); }} 
                    />

                    <ConfirmDialog 
                        isOpen={this.state.isCheckoutAllOpen}
                        title="Check-out all attendees"
                        message="Are you sure you want to check out all attendees? This action cannot be undone."
                        buttonText="Check out all"
                        onRequestClose={() => { this.setState({ isCheckoutAllOpen: false }); }}
                        onConfirm={() => { this.onCheckoutAll(); }}
                    />

                </div>
            </Page>
        );
    }

    private onMenuButtonsForRow(item: Attendee): React.ReactNode
    {
        return [
            // <MenuItem key="0" onClick={(e) => { this.setState({ isEditModalOpen: true, attendeeToEdit: item }); }}>Edit</MenuItem>,
            // <MenuItem key="1" onClick={(e) => {
            //     if(window.confirm("Are you sure you want to delete this attendee?"))
            //     {
            //         StoreActions.ArchiveAttendee(item);
            //     }
            // }}>Delete</MenuItem>
            <MenuItem key="0" onClick={(e) => { this.setState({ isCheckoutOpen: true, selectedAttendee: item }); }}>Check Out</MenuItem>
        ]
    }

    private onAttendeeCreated(e: AppEvent)
    {
        let attendee: Attendee = e.payload.attendee;
        //this.props.router.push('/events/' + eventId + '/overview');
        //StoreActions.GetAttendees(this.props.selectedEvent.id);
    }

    private onAttendeeUpdated(e: AppEvent)
    {
        let attendee: Attendee = e.payload.attendee;
    }

    private onSearch(e: React.ChangeEvent<HTMLInputElement>)
    {
        this.setState({ searchText: e.target.value });
        if(sessionStorage)
            sessionStorage.setItem('checkpointattendees-searchtext', e.target.value);
    }

    private onCheckoutAttendee()
    {
        TrackerAPI.GetCurrent().CheckpointCheckOut(this.props.event.id, this.state.selectedAttendee.id).then(() => 
        {
            this.refreshList(this.props.event.id, this.state.page, this.state.rowsPerPage);
        });
    }

    private onCheckoutAll()
    {
        TrackerAPI.GetCurrent().CheckpointCheckOutAll(this.props.event.id).then(() => 
        {
            this.setState({ isCheckoutAllOpen: false });

            if(this.state.searchText)
                this.refreshSearch(this.props.event.id, this.state.searchText);
            else
                this.refreshList(this.props.event.id, this.state.page, this.state.rowsPerPage);
        });
    }

    private refreshList(eventId: string, page: number, rowsPerPage: number)
    {
        this.setState({ loading: true });

        if(this.timeoutId)
            clearTimeout(this.timeoutId);

        TrackerAPI.GetCurrent().CheckpointAttendees(eventId, page * rowsPerPage, rowsPerPage, true).then((response) => 
        {
            this.setState({
                attendees: response.items,
                count: response.count,
                loading: false
            });
        });
    }

    private refreshSearch(eventId: string, queryText: string)
    {
        this.setState({ loading: true });

        if(this.timeoutId)
        {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }

        this.timeoutId = setTimeout(() => 
        { 
            // TrackerAPI.GetCurrent().SearchAttendees(eventId, queryText).then((response) => 
            // {
            //     this.setState({
            //         attendees: response,
            //         page: 0,
            //         count: response.length,
            //         loading: false
            //     });
            // });

            TrackerAPI.GetCurrent().CheckpointAttendeesSearch(eventId, queryText).then((response) =>
            {
                this.setState({
                    attendees: response.results,
                    page: 0,
                    count: response.results.length,
                    loading: false
                });
            })

        }, 250);
    }

    private onClearSearchClick() 
    {
        this.setState({ searchText: '' });
        if (sessionStorage)
            sessionStorage.setItem('attendees-searchtext', '');
    }

    private containsString(a: Attendee, value: string)
    {
        let string: string = a.getName() + a.email + a.phone;

        if(a.customFields && a.customFields.length >= 1)
        {
            a.customFields.forEach((keyValue) => {
                string += keyValue.value;
            });
        }

        return string.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    }

    private phoneFormat(str: string)
    {
        if(str && str.indexOf('-') === -1 && str.length === 10)
        {
            return `${str.slice(0, 3)}-${str.slice(3, 6)}-${str.slice(6)}`;
        }
        else
        {
            return str;
        }
    }
}

export function CheckpointAttendees(props)
{
    return (
        <OrganizationContext.Consumer>
            { orgCtx => 
                <EventContext.Consumer>
                    { ctx => <CheckpointAttendeesInternal organization={orgCtx.organization} event={ctx.event} {...props} /> }
                </EventContext.Consumer>
            }
        </OrganizationContext.Consumer>
    );
}
