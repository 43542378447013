import { Navigate, Route, Routes } from 'react-router-dom';
import { Page, PageHeader } from "../../components/Page";
import { useCurrentContext } from "../../hooks/useCurrentContext";
import { PageTabs } from "../../components/PageTabs";
import { AppRoutes } from "../../constants/AppRoutes";
import { AttendeesList } from './AttendeesList';
import { AttendeeDetails } from './AttendeeDetails';
import { ArchivedAttendeesPage } from './ArchivedAttendees';

export const AttendeesRoot = () => 
{
    const { organization, event } = useCurrentContext();

    const tabs = [
        { text: 'Attendees', href: AppRoutes.Event.Attendees.List(organization.id, event.id) },
        { text: 'Archive', href: AppRoutes.Event.Attendees.Archived(organization.id, event.id) },
        // { text: 'Automation', href: AppRoutes.Event.Attendees.Archived(organization.slug, event.id) }
    ]

    return (
        <Page>
            <PageHeader>
                <h2>Attendees</h2>
                <PageTabs tabs={tabs} />
            </PageHeader>
            <Routes>
                <Route path="list" element={<AttendeesList />} />
                <Route path="archived" element={<ArchivedAttendeesPage />} />
                {/* <Route path=":attendeeId" element={<AttendeeDetails />} /> */}
                {/* <Route path=":attendeeId/edit" element={<EditAttendee />} /> */}
                <Route path="" element={<Navigate to="list" />} />
            </Routes>
        </Page>
    );
}
