import { AppDispatcher } from "../dispatcher/AppDispatcher";
import * as StoreConstants from "./StoreConstants";
import { TrackerAPI } from "../api/TrackerAPI";
import { Event } from "../dto/Event";
import { Activity } from "../dto/Activity";
import { AppActions } from "../events/AppActions";
import Attendee from "../dto/Attendee";
import Zone from "../dto/Zone";
import Shift from "../dto/Shift";
//import { BadgemeisterAPI } from "../api/BadgemeisterAPI";
import { BM } from "../api/BM";

export class StoreActions
{
    public static async GetCurrentAccount()
    {
        try
        {
            const me = await BM.client.users.me();
            AppDispatcher.HandleServerAction({
                type: StoreConstants.GET_ACCOUNT,
                value: me
            });
        }
        catch(error)
        {
            console.error(error);
        }
    }

    public static async GetOrganizations()
    {
        try
        {
            const orgResponse = await BM.client.organizations.get();

            AppDispatcher.HandleServerAction({
                type: StoreConstants.GET_ORGANIZATIONS,
                value: orgResponse.items
            });
        }
        catch(error)
        {
            console.error(error)
        }
    }

    private static IsGettingOrganizations = false;
    public static async GetOrganization(orgId: string)
    {
        if(this.IsGettingOrganizations === false)
        {
            this.IsGettingOrganizations = true;

            try
            {
                const org = await BM.client.organizations.getById(orgId).get();
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.GET_ORGANIZATION,
                    value: org
                });
                this.IsGettingOrganizations = false;
            }
            catch(error)
            {
                console.error(error);
                this.IsGettingOrganizations = false;
            }
        }
    }

    private static IsGettingEvents: boolean = false;
    public static async GetEvents(orgId: string)
    {
        if(this.IsGettingEvents === false)
        {
            this.IsGettingEvents = true;

            try
            {
                const events = await BM.client.organizations.getById(orgId).events.get();
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.GET_EVENTS,
                    value: events.items,
                    args: { orgId }
                });
                this.IsGettingEvents = false;
            }
            catch(error)
            {
                console.error(error);
            }
            finally
            {
                this.IsFetchingEventDetails = false;
            }
        }
    }

    private static IsFetchingEventDetails: boolean = false;
    public static async GetEvent(eventId: string)
    {
        if (this.IsFetchingEventDetails === false)
        {
            this.IsFetchingEventDetails = true;

            try
            {
                const event = await BM.client.events.getById(eventId).get();
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.GET_EVENT,
                    value: event
                });
            }
            catch(error)
            {
                console.error(error);
            }
            finally
            {
                this.IsFetchingEventDetails = false;
            }
        }
    }

    private static IsCreatingEvent = false;
    public static async CreateEvent(orgId: string, name: string, startTime: string, endTime: string)
    {
        if (this.IsCreatingEvent === false)
        {
            this.IsCreatingEvent = true;

            try
            {
                // const e = new Event();
                // e.organization = orgId;
                // e.title = name;
                // e.startTime = startTime;
                // e.endTime = endTime;

                const e = {
                    organization: orgId,
                    title: name,
                    startTime,
                    endTime
                }

                const newEvent = await BM.client.events.add(e);
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.CREATE_EVENT,
                    value: newEvent
                });
            }
            catch(error: any)
            {
                console.error(error);
                AppActions.EventCreatedError(error);
            }
            finally 
            {
                this.IsFetchingEventDetails = false;
            }
        }
    }

    private static IsUpdatingEvent = false;
    public static async UpdateEvent(event: Event)
    {
        if (this.IsUpdatingEvent === false)
        {
            this.IsUpdatingEvent = true;
            
            try
            {
                const updatedEvent = await BM.client.events.getById(event.id).update(event);
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.UPDATE_EVENT,
                    value: updatedEvent
                });
                this.IsUpdatingEvent = false;
                //AppActions.EventUpdated(updatedEvent.id);
            }
            catch(error: any)
            {
                console.error(error);
                this.IsUpdatingEvent = false;
                AppActions.EventUpdatedError(error);
            }
        }
    }

    private static IsArchivingEvent = false;
    public static async ArchiveEvent(event: Event)
    {
        if (this.IsArchivingEvent === false)
        {
            this.IsArchivingEvent = true;

            try
            {
                // const archivedEvent = await BM.client.events.getById(event.id).update({ archived: true });
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.DELETE_EVENT,
                    value: event.id
                });
                this.IsArchivingEvent = false;
            }
            catch(error: any)
            {
                console.error(error);
                this.IsArchivingEvent = false;
                AppActions.EventUpdatedError(error);
            }
        }
    }

    private static IsDeletingEvent = false;
    public static DeleteEvent(eventId: string)
    {
        if (this.IsDeletingEvent === false)
        {
            this.IsDeletingEvent = true;
            TrackerAPI.GetCurrent().DeleteEvent(eventId).then((eventId) =>
            {
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.DELETE_EVENT,
                    value: eventId
                });
                this.IsDeletingEvent = false;
            }).catch((error) =>
            {
                console.error(error);
                this.IsDeletingEvent = false;
                AppActions.EventDeletedError(error);
            });
        }
    }

    // --------------------------------------------------
    // ACTIVITY ACTIONS
    // --------------------------------------------------

    private static IsGettingActivities = false;
    public static GetActivities(eventId: string)
    {
        if(this.IsGettingActivities === false)
        {
            this.IsGettingActivities = true;

            TrackerAPI.GetCurrent().GetActivities(eventId).then((activities) => 
            {
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.GET_ACTIVITIES,
                    value: { results: activities.items, eventId: eventId},
                });
                this.IsGettingActivities = false;
            }).catch((error) =>
            {
                console.error(error);
                this.IsGettingActivities = false;
            })
        }
    }

    private static IsCreatingActivity = false;
    public static CreateActivity(name: string, eventId: string, location: string, type: string, startTime: string, endTime: string)
    {
        if (this.IsCreatingActivity === false)
        {
            this.IsCreatingActivity = true;

            // let newActivity = new Activity();
            // newActivity.title = name;
            // newActivity.event = eventId;
            // newActivity.location = location;
            // newActivity.type = type;
            // newActivity.startTime = startTime;
            // newActivity.endTime = endTime;

            const newActivity = {
                title: name,
                event: eventId,
                location,
                type,
                startTime,
                endTime
            } as Activity;

            TrackerAPI.GetCurrent().CreateActivity(newActivity).then((activity) =>
            {
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.CREATE_ACTIVITY,
                    value: activity
                });
                this.IsCreatingActivity = false;
            }).catch((error) =>
            {
                console.error(error);
                this.IsCreatingActivity = false;
                AppActions.ActivityDeletedError(error);
            });
        }
    }

    private static IsUpdatingActivity = false;
    public static UpdateActivity(activity: Activity)
    {
        if (this.IsUpdatingActivity === false)
        {
            this.IsUpdatingActivity = true;
            TrackerAPI.GetCurrent().UpdateActivity(activity).then((activity) =>
            {
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.UPDATE_ACTIVITY,
                    value: activity
                });
                this.IsUpdatingActivity = false;
            }).catch((error) =>
            {
                console.error(error);
                this.IsUpdatingActivity = false;
                AppActions.ActivityUpdatedError(error);
            });
        }
    }

    private static IsArchivingActivity = false;
    public static ArchiveActivity(activity: Activity)
    {
        if (this.IsArchivingActivity === false)
        {
            this.IsArchivingActivity = true;
            //activity.archived = true;

            TrackerAPI.GetCurrent().UpdateActivity(activity).then((activity) =>
            {
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.DELETE_ACTIVITY,
                    value: activity
                });
                this.IsArchivingActivity = false;
            }).catch((error) =>
            {
                console.error(error);
                this.IsArchivingActivity = false;
                AppActions.ActivityDeletedError(error);
            });
        }
    }

    private static IsDeletingActivity = false;
    public static DeleteActivity(eventId: string, activityId: string)
    {
        if (this.IsDeletingActivity === false)
        {
            this.IsDeletingActivity = true;
            TrackerAPI.GetCurrent().DeleteActivity(eventId, activityId).then((activityId) =>
            {
                AppDispatcher.HandleServerAction({
                    type: StoreConstants.DELETE_SESSION,
                    value: { eventId: eventId, id: activityId }
                });
                this.IsDeletingActivity = false;
            }).catch((error) =>
            {
                console.error(error);
                this.IsDeletingActivity = false;
                AppActions.ActivityDeletedError(error);
            });
        }
    }

    // public static FetchActivityCheckIns(eventId: string, activityId: string)
    // {
    //     TrackerAPI.GetCurrent().GetCheckIns(eventId, sessionId).then((checkins) => 
    //     {
    //         AppDispatcher.HandleViewAction({
    //             type: StoreConstants.FETCH_CHECKINS,
    //             value: { eventId: eventId, sessionId: sessionId, checkins: checkins }
    //         });
    //     }).catch((error) => 
    //     {
    //         console.error(error);
    //     });
    // }

    public static FetchCheckIns(eventId: string, activityId: string)
    {
        TrackerAPI.GetCurrent().GetCheckIns(eventId, activityId).then((checkins) => 
        {
            AppDispatcher.HandleViewAction({
                type: StoreConstants.FETCH_CHECKINS,
                value: { eventId: eventId, activityId: activityId, checkins: checkins }
            });
        }).catch((error) => 
        {
            console.error(error);
        });
    }

    // --------------------------------------------------
    // ATTENDEE ACTIONS
    // --------------------------------------------------
    private static IsGettingAttendees = false;
    public static GetAttendees(eventId: string)
    {
        if(this.IsGettingAttendees === false)
        {
            this.IsGettingAttendees = true;
            TrackerAPI.GetCurrent().GetAttendees(eventId).then((response) => 
            {
                AppDispatcher.HandleViewAction({
                    type: StoreConstants.GET_ATTENDEES,
                    value: { attendees: response.items, eventId: eventId }
                });
                this.IsGettingAttendees = false;
            }).catch((error) =>
            {
                console.error(error);
                this.IsGettingAttendees = false;
            });
        }
    }

    public static CreateAttendee(eventId: string, personId: string, firstName: string, lastName: string, company: string, email: string, phone: string, cards: string[])
    {
        let a = new Attendee();
        a.event = eventId;
        a.firstName = firstName;
        a.lastName = lastName;
        a.company = company;
        a.email = email;
        a.phone = phone;
        a.cards = cards;

        TrackerAPI.GetCurrent().CreateAttendee(a).then((attendee) => 
        {
            AppDispatcher.HandleServerAction({
                type: StoreConstants.CREATE_ATTENDEE,
                value: attendee
            });
        }).catch((error) => 
        {
            console.error(error);
            AppActions.AttendeeCreatedError(error);
        });
    }

    public static UpdateAttendee(attendee: Attendee)
    {
        TrackerAPI.GetCurrent().UpdateAttendee(attendee).then((attendee) =>
        {
            AppDispatcher.HandleServerAction({
                type: StoreConstants.UPDATE_ATTENDEE,
                value: attendee
            });
        }).catch((error) => 
        {
            console.error(error);
            AppActions.AttendeeUpdatedError(error);
        });
    }

    public static ArchiveAttendee(attendee: Attendee)
    {
        attendee.archived = true;

        TrackerAPI.GetCurrent().UpdateAttendee(attendee).then((attendee) => 
        {
            AppDispatcher.HandleServerAction({
                type: StoreConstants.DELETE_ATTENDEE,
                value: attendee
            });
        }).catch((error) => 
        {
            console.error(error);
            AppActions.AttendeeArchivedError(error);
        });
    }

    public static DeleteAttendee(attendee: Attendee)
    {
        TrackerAPI.GetCurrent().DeleteAttendee(attendee).then((attendeeId) => 
        {
            AppDispatcher.HandleServerAction({
                type: StoreConstants.DELETE_ATTENDEE,
                value: attendee
            });
        }).catch((error) => 
        {
            console.error(error);
            AppActions.AttendeeDeletedError(error);
        });
    }
    
    // --------------------------------------------------
    // ZONE ACTIONS
    // --------------------------------------------------

    private static IsGettingZones = false;
    public static GetZones(eventId: string)
    {   
        if(this.IsGettingZones === false)
        {
            this.IsGettingZones = true;

            TrackerAPI.GetCurrent().GetZones(eventId).then((zones) => 
            {
                AppDispatcher.HandleViewAction({
                    type: StoreConstants.GET_ZONES,
                    value: { zones: zones, eventId: eventId }
                });
                this.IsGettingZones = false;
            }).catch((error) =>
            {
                console.error(error);
                this.IsGettingZones = false;
            });
        }
    }

    private static IsGettingZone = false;
    public static GetZone(eventId: string, zoneId: string, includeShifts: boolean = false)
    {
        if(this.IsGettingZone === false)
        {
            this.IsGettingZone = true;

            TrackerAPI.GetCurrent().GetZone(eventId, zoneId, includeShifts).then((zone) => 
            {
                AppDispatcher.HandleViewAction({
                    type: StoreConstants.GET_ZONE,
                    value: zone
                });
                this.IsGettingZone = false;
            }).catch((error) => 
            {
                console.error(error);
                this.IsGettingZone = false;
            });
        }
    }

    public static CreateZone(eventId: string, title: string)
    {
        let z = new Zone();
        z.event = eventId;
        z.title = title;

        TrackerAPI.GetCurrent().CreateZone(eventId, z).then((zone) => 
        {
            AppDispatcher.HandleServerAction({
                type: StoreConstants.CREATE_ZONE,
                value: zone
            });
        }).catch((error) => 
        {
            console.error(error);
            AppActions.ZoneCreatedError(error);
        });
    }

    public static UpdateZone(zone: Zone)
    {
        TrackerAPI.GetCurrent().UpdateZone(zone.event, zone).then((zone) =>
        {
            AppDispatcher.HandleServerAction({
                type: StoreConstants.UPDATE_ZONE,
                value: zone
            });
        }).catch((error) => 
        {
            console.error(error);
            AppActions.ZoneUpdatedError(error);
        });
    }

    // public static ArchiveZone(zone: Zone)
    // {
    //     attendee.archived = true;

    //     TrackerAPI.GetCurrent().UpdateAttendee(attendee).then((attendee) => 
    //     {
    //         AppDispatcher.HandleServerAction({
    //             type: StoreConstants.DELETE_ATTENDEE,
    //             value: attendee
    //         });
    //     }).catch((error) => 
    //     {
    //         console.error(error);
    //         AppActions.AttendeeArchivedError(error);
    //     });
    // }

    public static DeleteZone(zone: Zone)
    {
        TrackerAPI.GetCurrent().DeleteZone(zone.event, zone.id).then((zoneId) => 
        {
            AppDispatcher.HandleServerAction({
                type: StoreConstants.DELETE_ZONE,
                value: zoneId
            });
        }).catch((error) => 
        {
            console.error(error);
            AppActions.ZoneDeletedError(error);
        });
    }

    // --------------------------------------------------
    // SHIFT ACTIONS
    // --------------------------------------------------

    public static CreateShift(eventId: string, zoneId: string, shift: Shift)
    {
        throw new Error('Not yet implemented');
    }

    public static UpdateShift(eventId: string, shift: Shift)
    {
        TrackerAPI.GetCurrent().UpdateShift(eventId, shift.zone, shift.id, shift).then((shift) => 
        {
            AppDispatcher.HandleServerAction({
                type: StoreConstants.UPDATE_SHIFT,
                value: {
                    eventId: eventId,
                    shift: shift
                }
            });
            AppActions.ShiftUpdated(shift);
        }).catch((error) => 
        { 
            console.error(error);
            AppActions.ShiftUpdatedError(error);
        });
    }

    public static DeleteShift(eventId: string, zoneId: string, shiftId: string)
    {
        TrackerAPI.GetCurrent().DeleteShift(eventId, zoneId, shiftId).then((shift: Shift) => 
        {
            AppDispatcher.HandleServerAction({
                type: StoreConstants.DELETE_SHIFT,
                value: {
                    eventId: eventId,
                    zoneId: zoneId,
                    shiftId: shiftId
                }
            });
            AppActions.ShiftDeleted(shift);
        }).catch((error) => 
        {
            console.error(error);
            AppActions.ShiftDeletedError(error);
        });
    }
}