
export default class BMError extends Error
{
    code: string;
    status: number;

    constructor(code: string, message: string, status: number)
    {
        super(message);
        this.name = 'apiError';
        this.code = code;
        this.status = status;
    }
}