import { useRef } from "react";
import { Divider, Fade, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toHumanizedDateSpan } from "../utils/DateUtils";

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import BarChartIcon from "@mui/icons-material/BarChart";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import SettingsIcon from "@mui/icons-material/Settings";
import VolunteerActivism from "@mui/icons-material/VolunteerActivism"
import Devices from "@mui/icons-material/Devices";
import DeviceHub from "@mui/icons-material/DeviceHub";
import { useCurrentContext } from "../hooks/useCurrentContext";
import HowToReg from "@mui/icons-material/HowToReg";
import Badge from "@mui/icons-material/Badge";
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { QueryKeys } from "../data/QueryKeys";
import { useQuery } from "react-query";
import { BM } from "../api/BM";
import { EventListShimmer, SideNavigationShimmer } from "../utils/Shimmer";

import "./SideNavigation.scss";
import { useToggle } from "../hooks/useToggle";
import { AppRoutes } from "../constants/AppRoutes";

// import { el } from "date-fns/locale";
// const theme = createTheme({
//     typography: {
//         htmlFontSize: 10,
//         fontSize: 13
//     },
//     palette: {
//         mode: 'dark',
//         primary: { 
//             main: '#e84930' 
//         },
//         secondary: {
//             main: '#1c2650'
//         }
//     },
// });

export const SideNavigation = (props) => 
{
    const { organization, event, isLoaded } = useCurrentContext();

    return (
        <nav className="sidenav">
            {
                isLoaded && organization && event &&
                <>
                    <EventListControl />

                    <NavigationListItem href={AppRoutes.Event.Overview(organization.id, event.id)} icon={<BarChartIcon />}>Dashboard</NavigationListItem>
                    <NavigationListItem href={AppRoutes.Event.Activities.Root(organization.id, event.id)} icon={<FormatListBulletedIcon />}>Sessions</NavigationListItem>
                    <NavigationListItem href={AppRoutes.Event.Attendees.Root(organization.id, event.id)} icon={<PeopleIcon />}>Attendees</NavigationListItem>
                    <NavigationListItem href={AppRoutes.Event.Registration.Root(organization.id, event.id)} icon={<HowToReg />}>Registration</NavigationListItem>
                    {/* <NavigationListItem href={AppRoutes.Event.Badges.Root(organization.slug, event.id)} icon={<Badge />}>Badges</NavigationListItem> */}
                    {/* <NavigationListItem href={AppRoutes.Event.Engagement.Root(organization.slug, event.id)} icon={<AccessibilityNewIcon />}>Engagement</NavigationListItem> */}
                    <NavigationListItem href={AppRoutes.Event.Engagement.Root(organization.id, event.id)} icon={<DeviceHub />}>Devices</NavigationListItem>

                    <NavigationListItem href={AppRoutes.Event.Settings.Root(organization.id, event.id)} icon={<SettingsIcon />}>Event Settings</NavigationListItem>
                </>
            }
            { !isLoaded && <SideNavigationShimmer /> }
        </nav>
    );
};

const EventListControl = (props) =>
{
    const eventList = useToggle(false);
    const buttonEl = useRef(null);
    const nav = useNavigate();
    const { organization, isOrganizationLoaded, event, isEventLoaded } = useCurrentContext();
    const eventsQuery = useQuery(QueryKeys.Events.ForOrg(organization.id), () => {
        return BM.client.organizations.getById(organization?.id).events
            .top(5)
            .orderBy('startTime', 'desc')
            .get();
    }, { enabled: isOrganizationLoaded });

    return (
        <>
        
        <div className="event-list navigation-item" onClick={eventList.open} ref={buttonEl}>
            {
                event &&
                <>
                    <div>
                        <div className="event-list-name">{event.title}</div>
                        <div className="event-list-date">{toHumanizedDateSpan(event.startTime, event.endTime)}</div>
                    </div>
                    <UnfoldMoreIcon />
                </>
            }
            {
                !event &&
                <div>
                    <EventListShimmer />
                </div>
            }
        </div>
        {
            eventsQuery.data &&
            <Menu open={eventList.isOpen} anchorEl={buttonEl.current} onClose={eventList.close} TransitionComponent={Fade} TransitionProps={{ timeout: 100 }}>
                { eventsQuery.data?.items.map(event => {
                    return (
                        <MenuItem 
                            key={event.id}
                            component="a" 
                            href={`/${organization.id}/events/${event.id}`} 
                            onClick={e => { nav(`/${organization.id}/events/${event.id}`); eventList.close(); e.preventDefault(); }}
                            sx={{ width: '235px' }}>
                            {event.title}
                        </MenuItem>
                    );
                })}
                <Divider />
                <MenuItem component="a" href={`/${organization.id}/events`} onClick={e => { nav(`/${organization.id}/events`); eventList.close(); e.preventDefault(); }}>
                    <ListItemText>See all events</ListItemText>
                </MenuItem>
            </Menu>
        }
        </>
    );
}

interface NavigationListItemProps
{
    href: string;
    text?: string;
    icon?: React.ReactNode;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    disabled?: boolean;
}

const NavigationListItem = (props: NavigationListItemProps) => 
{
    const { href, text, icon, style, children, disabled = false } = props;
    const nav = useNavigate();

    const isSelected = (href: string) => {
        return window.location.pathname.startsWith(href);
    }

    let classes = ['navigation-item'];
    if(isSelected(href))
        classes.push('selected');
    if(disabled)
        classes.push('disabled');

    return (
        <a className={classes.join(' ')} href={href} style={style} onClick={e => { e.preventDefault(); nav(href); }}>
            {icon}{children}
        </a>
    );
}