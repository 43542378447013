import Attendee from "./Attendee";

export default class CheckIn
{
    public id: string;
    public badgeId: string;
    public timestamp: Date;

    public createdAt: Date;
    public updatedAt: Date;
    
    public event: string;
    public activity: string;
    public attendee: string;

    public metadata: {[key: string]: string};

    public static fromJson(json: any): CheckIn
    {
        const newCheckIn = new CheckIn();
        newCheckIn.id = json.id || json._id;
        newCheckIn.badgeId = json.badgeId;
        newCheckIn.timestamp = new Date(json.timestamp);

        newCheckIn.event = json.event;
        newCheckIn.activity = json.activity;
        newCheckIn.attendee = json.attendee;

        newCheckIn.createdAt = json.createdAt;
        newCheckIn.updatedAt = json.updatedAt;

        newCheckIn.metadata = json.metadata;

        return newCheckIn;
    }
}
